.ProfileModule-container-parent-class .custom_field_container__drag_drop .custom_field_group_info{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px 1px;
}
.ProfileModule-container-parent-class .custom_field_container__drag_drop .custom_field_group_info__header{
    display: flex;
    align-items: center;
    grid-gap: 12px;
}
.ProfileModule-container-parent-class .custom_field_container__drag_drop .custom_field_group_info__header span{
    display: inline-flex;
}
.ProfileModule-container-parent-class .custom_field_container__drag_drop .group_label_header{
    display: flex;
    align-items: center;
    grid-gap: 10px;
}
.ProfileModule-container-parent-class .custom_field_container__drag_drop .expand_collapse_icon,
.ProfileModule-container-parent-class .custom_field_container__drag_drop .group_edit_icon{
    cursor: pointer;
}

.ProfileModule-container-parent-class .custom_field_container__drag_drop .each_custom_field_container{

}
.ProfileModule-container-parent-class .custom_field_container__drag_drop .custom_field_group_info span{
    display: inline-flex;
    cursor: pointer;
}
/* .ProfileModule-container-parent-class .custom_field_container__drag_drop .label_class_wrapper svg,
.ProfileModule-container-parent-class .custom_field_container__drag_drop .custom_field_group_info .group_edit_icon svg
{
    color: #6a6868;
} */
.ProfileModule-container-parent-class .custom_field_container__drag_drop .custom_field_group_info .field_delete_icon svg path{
    fill:#546376;
}
.ProfileModule-container-parent-class .custom_field_container__drag_drop .custom_field_header__wr{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 9px;
    border-bottom: 1px solid #ddd;
    margin-bottom: 9px;
}
.ProfileModule-container-parent-class .custom_field_container__drag_drop .custom_field_header__space__between{
    display: flex;
    grid-gap: 10px;
    align-items: center;
}
.ProfileModule-container-parent-class .custom_field_container__drag_drop .field_action_container__wr{
    display: flex;
    grid-gap: 10px;
    align-items: center;
}
.ProfileModule-container-parent-class .custom_field_container__drag_drop .field_action_container__wr span{
    display: inline-flex;
    cursor: pointer;
}
.ProfileModule-container-parent-class .custom_field_container__drag_drop .custom_field_description_container{
    display: flex;
    grid-gap: 30px;
}
.ProfileModule-container-parent-class .custom_field_container__drag_drop .description_left_side{
    text-align: left;
    flex: 2;
    overflow: hidden;
}
.ProfileModule-container-parent-class .custom_field_container__drag_drop .description_right_side{
    flex: 1;
    text-align: left;
    overflow: hidden;
}

.ProfileModule-container-parent-class .custom_field_container__drag_drop .description_left_side{
    text-align: left;
}
.ProfileModule-container-parent-class .custom_field_container__drag_drop .group_custom_field_container{
    padding-top: 10px;
}
.ProfileModule-container-parent-class .group_custom_field_container .field_info{
    font-weight: 300;
    font-size: 14px;
    overflow: hidden;
    /* text-overflow: ellipsis; */
    white-space: nowrap;
}
.ProfileModule-container-parent-class .group_custom_field_container .field_info + .field_info{
    padding-top: 3px;
}
.ProfileModule-container-parent-class .group_custom_field_container .field_info span{
    margin-right: 4px;
}
.ProfileModule-container-parent-class .group_custom_field_container .custom_field_drag_icon{
    display: inline-flex;
    cursor: grabbing !important;
}
.ProfileModule-container-parent-class .group_custom_field_container .group_drag_icon{
    cursor: grabbing !important;
}
.ProfileModule-container-parent-class .group_custom_field_container .field_owner{
    color: var(--blue-1);
}
.ProfileModule-container-parent-class .group_custom_field_container .field_info_ellipse{
    text-overflow: ellipsis;
}
.ProfileModule-container-parent-class .group_custom_field_container .personalize__tag__custom__field{
    font-weight: 400;
}
.ProfileModule-container-parent-class .custom_field_container__drag_drop .field__info__extra{
    display: flex;
}
.ProfileModule-container-parent-class .custom_field_container__drag_drop .field__info__extra__container__flex{
    display: flex;
}
.ProfileModule-container-parent-class .custom_field_container__drag_drop .field__info__extra__hints{
    display: block;
    font-size: 10px;
    color: var(--red);
}
.ProfileModule-container-parent-class .custom_field_container__drag_drop .field__info__extra .field_edit_icon{
    color: #6a6868;
    cursor: pointer;
}
.ProfileModule-container-parent-class .custom_field_container__drag_drop .field__info__extra input{
    width: 119px !important;
    height: 18px;
    border-bottom: 0;
    font-size: 14px;
    margin: 0;
    color: #546376;
}
.ProfileModule-container-parent-class  .custom_field_container__drag_drop .no__personal__custom__field__wrapper{
    background: var(--gray_dark);
    padding: 15px;
    text-align: center;
    border-radius: 6px;
    color: var(--gray);
}

.ProfileModule-container-parent-class .custom_field_container__drag_drop .custom_field_parent__wrapper .custom_field_parent__wrapper__inner{
    background: #f0f1f3;
    border-radius: 8px;
    padding: 8px;
}
.ProfileModule-container-parent-class .custom_field_container__drag_drop .custom_field_parent__wrapper .custom_field_parent__wrapper__title{
    padding: 16px 0 10px 0;
    display: block;
}
.ProfileModule-container-parent-class .custom_field_container__drag_drop .custom_field_parent__wrapper .custom_field_parent__wrapper__list + .custom_field_parent__wrapper__list{
    margin-top: 8px;
}
.ProfileModule-container-parent-class .custom_field_container__drag_drop .custom_field_parent__wrapper .custom_field_parent__wrapper__list .field_info{
    font-weight: 300;
}
.ProfileModule-container-parent-class .custom_field_container__drag_drop .custom_field_parent__wrapper .custom_field_parent__wrapper__list .personalize__tag__title{
    font-weight: 400;
}
.ProfileModule-container-parent-class .custom_field_container__drag_drop .custom_field_parent__wrapper .custom_field_parent__wrapper__list .each_custom_field_container{
    background: #fff;
    border-radius: 8px;
    padding: 8px;
}


.ProfileModule-container-parent-class .description_left_side .field_info input{
    width: auto !important;
}
.ProfileModule-container-parent-class .each_custom_field_container .custom_menu_option_container{
    display: inline-flex;
}
.ProfileModule-container-parent-class .each_group_container .custom__field__text__edit{
    padding: 0;
    height: 18px;
    margin: 0;
    border-bottom: 0;
    color: #546376;
}
.ProfileModule-container-parent-class .custom_field_container__drag_drop .field__info__extra input:focus,
.ProfileModule-container-parent-class .each_group_container .custom__field__text__edit:focus{
    box-shadow: 0 0 0 rgba(0,0,0,0.0) !important;
    border-bottom: 0 !important;
}


.drag__and__drop__move__wrapper {
    padding: 14px;
}
.drag__and__drop__move__wrapper .drag__and__drop__move__title{
    font-size: 14px;
    padding:6px 0 10px 0;
    display: block;
    cursor: pointer;
}
.drag__and__drop__move__wrapper .drop__down__list__each__item{
    font-size: 14px;
    padding: 6px 0;
    border-bottom: 1px solid #ddd;
    cursor: pointer;
}
.drag__and__drop__move__wrapper .drop__down__list__each__item .selected__item{
    background: var(--dark_blue);
    color: #fff;
    padding: 2px 5px;
    border-radius: 2px;
    display: block;
}

.drag__and__drop__move__wrapper .drag__and__drop__move__container .drop__down__list__each__item:last-child{
    border-bottom: 0 !important;
}
.drag__and__drop__move__wrapper .drag__and__drop__move__input input{
    border: 1px solid #ddd;
    height: 34px;
    border-radius: 4px;
    padding: 0 5px;
    margin: 16px 0 16px 0;
}
.drag__and__drop__move__wrapper .drag__and__drop__move__input input:focus{
    box-shadow: 0 0 0 rgba(0,0,0,0.0) !important;
    border-bottom-color: #ddd !important;
}
.drag__and__drop__move__wrapper .drag__and__drop__move__btn{
    background: var(--dark_blue);
    color: #fff;
    border-radius: 4px;
    font-size: 14px;
    padding: 3px 8px;
    cursor: pointer;
}
