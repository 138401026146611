/* body.active__minimal_sidebar .main__content_wrapper .main__content {
    margin-left: 105px !important;
    margin-right: 15px;
    margin-top: 0;
} */
body {background: #F5F7F9;}
/* maporder section start */
.main__content {
    position: relative;
    width: 100%;
}
.ProfileModule-container-parent-class .maporder_section .pagination__button__section__area{
    display: flex;
    justify-content: flex-end;
}

.ProfileModule-container-parent-class .maporder_section .pagination__button__section__area .refresh__button__for__pagination {
    background-color: black;
    margin-left: 20px;
}

.ProfileModule-container-parent-class .maporder_section .completed__task__btn{
    background-color: red;
    color: white;
    padding: 13px 11px;
    display: flex;
    justify-content: center;
    border-radius: 5px;
}
.ProfileModule-container-parent-class .maporder_section .action__type{
    display: flex;
    grid-gap: 14px;
    align-items: center;
}
.maporder_section {
    min-height: calc(100vh - 120px);
    padding: 20px 20px 30px;
    background: #fff;
    margin: 20px 0;
    position: relative;
    border-radius: 10px;
}
.maporder_section .page_heading {
    justify-content: space-between;
    margin-bottom: 20px;
    background: #fff;
    min-height: 90px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #E5E8EF;
}
.maporder_section .page_heading h4 span {color: #3C7EF3;}
.maporder_section .page_heading h4 {
    color: #133159;
    margin: 0;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
}
.maporder_section .page_heading p {
    color: rgba(19, 49, 89, 0.65);
    font-size: 16px;
    margin-top: 12px;
    line-height: 20px;
}
.maporder_section .page_heading p span {color: #3C7EF3;}
.maporder_section .page_heading .action {display: flex;}
.maporder_section .page_heading .action a.refresh {
    border: 1px solid #E5E8EF;
    background:#F6F7FB;
    color: #133159;
    min-width: 127px;
}
.maporder_section .page_heading .action a.order {
    border: 1px solid #3C7EF3;
    background:#3C7EF3;
    color: #fff;
    min-width: 157px;
    margin-left: 24px;
}
.maporder_section .page_heading .action a svg {
    vertical-align: middle;
    margin: -3px 5px 0 0;
}
.maporder_section .page_heading .action a {
    display: inline-block;
    border-radius: 5px;
    line-height: 24px;
    font-size: 16px;
    height: 50px;
    line-height: 48px;
    text-align: center;
}

.maporder_section .table_heading .search_form {
    justify-content: space-between;
    display: flex;
    margin: 20px 0;
}
.maporder_section .table_heading .form_group {
    align-items: center;
    display: flex;
}
.maporder_section .table_heading .form_group.search {
    border: 1px solid rgba(19, 49, 89, 0.35);
    border-radius: 5px;
    width: 100%;
    height: 40px;
    max-width: 273px;
}
.maporder_section .table_heading .form_group label {
    line-height: 30px;
    color: #133159;
    font-size: 16px;
}
.maporder_section .table_heading .select-wrapper .caret {right: 5px;}
.maporder_section .table_heading .select-wrapper input {
    box-sizing: border-box;
    border-bottom: none;
    font-size: 16px;
    padding: 0 12px;
    margin: 0;
    height: 100%;
    line-height: 100%;
}
.maporder_section .table_heading .dropdown-content li {min-height: auto;}
.maporder_section .table_heading .dropdown-content li>a, 
.maporder_section .table_heading .dropdown-content li>span {
    font-size: 14px !important;
    padding: 8px 10px;
    font-weight: 500;
    color: rgba(19, 49, 89, 0.65);
}
.maporder_section .table_heading .select-dropdown {
    min-width: 100% !important;
    width: 100% !important;
}
.maporder_section .table_heading .select-wrapper,
.maporder_section .table_heading select {
    border: 1px solid rgba(19, 49, 89, 0.35);
    color: rgba(19, 49, 89, 0.65);
    font-size: 16px;
    width: 76px;
    height: 40px;
    padding: 0;
    margin: 0 10px;
    border-radius: 5px;
}
.maporder_section .table_heading .select-wrapper+label {position: inherit;}
.maporder_section .table_heading .form_group.search input:focus {
    box-shadow: none;
    outline: none;
    border: none;
}
.maporder_section .table_heading .form_group.search input::placeholder {color: #A6ACBE;}
.maporder_section .table_heading .form_group.search input {
    padding: 0 0 0 15px;
    border: none;
    margin: 0;
    height: 100%;
    color: #133159;
    font-size: 16px;
}
.maporder_section .table_heading .form_group.search button svg {
    margin-top: 2px;
    width: 18px;
    height: 18px;
}
.maporder_section .table_heading .form_group.search button {
    padding-right: 12px;
    background: none;
    cursor: pointer;
    width: 42px;
    border: none;
    outline: none;
}

.maporder_section .data_table tr:first-child td:first-child {border-radius: 10px 0 0 10px;}
.maporder_section .data_table tr:first-child td:last-child {border-radius: 0 10px 10px 0;}
.maporder_section .data_table tr {border: 10px solid #F6F7FB;}
.maporder_section .data_table {
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 20px;
}
.maporder_section .data_table th svg {
    position: absolute;
    cursor: pointer;
    right: 10px;
    top: calc(50% - 9px);
}
.maporder_section .data_table th:last-child {width: 100px;}
.maporder_section .data_table th {
    border: 3px solid #F6F7FB;
    max-width: 125px;
    font-size: 16px;
    width: auto;
    padding: 12px 10px;
    color: #133159;
    font-weight: 500;
    position: relative;
    padding-right: 20px;
}
.maporder_section .data_table tr.completed td svg.comp {display: inline-block;}
.maporder_section .data_table tr.pending td svg.pend {display: inline-block;}
.maporder_section .data_table tr.completed td a.com {display: inline-block;}
.maporder_section .data_table tr.pending td a.pen {display: inline-block;}
.maporder_section .data_table span {
    white-space: nowrap;
    display: block;
}
.maporder_section .data_table td svg.comp,
.maporder_section .data_table td svg.pend {
    vertical-align: middle;
    margin: -5px 5px 0 0;
    display: none;
}
.maporder_section .data_table td:first-child {padding-right: 30px;}
.maporder_section .data_table td {
    vertical-align: middle;
    background: #fff;
    max-width: 265px;
    font-weight: 400;
    font-size: 16px;
    color: #546376;
    padding: 12px 10px;
    position: relative;
}
.maporder_section .data_table td a.edit {
    border: 1px solid #E5E8EF;
    background:#F6F7FB;
    text-align: center;
    border-radius: 5px;
    color: #133159;
    min-width: 36px;
    height: 36px;
    right: 10px;
    line-height: 40px;
    position: absolute;
    top: calc(50% - 18px);
    display: inline-block;
}
.maporder_section .data_table td a.pen,
.maporder_section .data_table td a.com {
    line-height: 28px;
    min-width: 115px;
    padding: 0 12px;
    display: none;
    height: 30px;
    text-align: center;
    border-radius: 20px;
}
.maporder_section .data_table td a.pen {
    border: 1px solid #F2994A;
    color: #F2994A;
}
.maporder_section .data_table td a.com {
    border: 1px solid #219653;
    color: #219653;
}
.maporder_section .data_table td input:focus {
    border-color: #E5E8EF !important; 
    outline: none;
    box-shadow: none !important;
}
.maporder_section .data_table td input {
    border: 1px solid #E5E8EF;
    border-radius: 5px;
    color: #133159;
    margin: 0;
    width: 70px;
    height: 50px;
    line-height: 48px;
    text-align: center;
}
.maporder_section .data_table td .view svg {
    vertical-align: middle;
    margin-right: 5px;
}
.maporder_section .data_table td .view {
    border: 1px solid #E5E8EF;
    background: #F6F7FB;
    border-radius: 5px;
    color: #133159;
    width: 87px;
    display: flex;
    height: 50px;
    line-height: 48px;
    align-items: center;
    justify-content: center;
}
.maporder_section .table_footer {
    /* padding: 40px 10px 10px; */
    padding: 5px 18px 27px !important;
    background: #F6F7FB;
    margin-top: -27px;
    border-radius: 0 0 10px 10px;
}
.maporder_section .result {
    line-height: 24px;
    margin: 0 0 -40px;
    font-size: 16px;
    color: #546376;
}
.maporder_section .pagination {
    justify-content: center;
    margin-left: 10%;
    display: flex;
    align-items: center;
}
.maporder_section .pagination li {
    height: auto;
    width: auto;
    color: #3C7EF3;
}
.maporder_section .pagination li small {
    margin-right: 12px;
    font-size: 14px;
    font-weight: 500;
}
.maporder_section .pagination li span {
    transform: rotate(10deg);
    font-weight: 300;
    font-size: 35px;
    line-height: 0;
}
.maporder_section .pagination li small,
.maporder_section .pagination li span {
    display: inline-block;
    min-width: 30px;
    text-align: center;
    vertical-align: middle;
}
.maporder_section .pagination li input {
    border: 2px solid #3C7EF3;
    font-family: var(--poppins);
    box-sizing: border-box;
    margin: 0 10px 0 20px;
    font-weight: 500;
    color: #3C7EF3;
    padding: 0;
    width: 40px;
    height: 40px;
    font-size: 14px;
    line-height: 40px;
    text-align: center;
    border-radius: 5px;
}
.maporder_section .pagination li .prev:hover,
.maporder_section .pagination li .next:hover {background: #68EBCA;}
.maporder_section .pagination li .next, 
.maporder_section .pagination li .prev {
    display: inline-block;
    background: #3C7EF3;
    border-radius: 5px;
    padding: 0 10px;
    border: none;
    height: 40px;
    color: #fff;
    cursor: pointer;
    width: 40px;
    line-height: 40px;
    text-align: center;
    transition: all .2s;
}
.maporder_section .pagination li .next svg, 
.maporder_section .pagination li .prev svg {
    vertical-align: middle;
    margin-top: -3px;
}
.maporder_section .modal-overlay {
    position: absolute !important;
    left: -20px;
    top: -20px;
    width: calc(100% + 40px);
}
.maporder_section .modal .modal-content {padding: 0 !important;}
.maporder_section .modal {
    border-radius: 10px 0 0 10px;
    top: 80px !important;
    right: 0 !important;
    max-width: 832px;
    width: 100%;
    padding: 0;
    left: inherit;
    background: #fff;
}
.maporder_section .modal .header {
    border-bottom: 1px solid #E5E8EF;
    justify-content: space-between;
    padding: 20px 20px 12px;
    display: flex;
}
.maporder_section .modal .header h5 {
    line-height: 36px;
    color: #133159;
    font-weight: 500;
    margin: 0;
    font-size: 24px;
}
.maporder_section .modal .modal_article {padding: 21px 40px;}
.maporder_section .modal .modal_article p {
    line-height: 22px;
    color: #546376;
    font-size: 16px;
    margin: 0;
    font-weight: 500;
}
.maporder_section .modal .modal_article input:focus {
    box-shadow: none;
    outline: none;
    border: none;
}
.maporder_section .modal .modal_article input::placeholder {color: #A6ACBE;}
.maporder_section .modal .modal_article input {
    border: 1px solid #E5E8EF;
    line-height: 44px;
    max-width: 752px;
    padding: 0 10px;
    height: 44px;
    width: 100%;
    margin: 10px 0;
    font-size: 16px;
    color: #133159;
    border-radius: 5px;
    box-sizing: border-box;
}
.maporder_section .modal .modal_article .save_btn {
    font-family: var(--poppins);
    background: #3C7EF3;
    margin: 10px auto 0;
    text-align: center;
    display: block;
    color: #fff;
    border: none;
    height: 50px;
    font-size: 16px;
    min-width: 109px;
    font-weight: 500;
    border-radius: 5px;
    padding: 0 10px 2px;
}


.map__order__name__modal__wrapper .modal_body input{
    font-size: 14px!important;
    line-height: 22px;
    letter-spacing: .01em;
    color: #8896a8;
    padding-left: 12px!important;
    border: 1px solid #e5e8ef!important;
    box-sizing: border-box!important;
    border-radius: 5px!important;
    background: #fff!important;
    height: 40px !important;
    margin-bottom: 16px;
}
.map__order__name__modal__wrapper .modal--footer{
    display: flex;
    grid-gap: 16px;
}
.map__order__name__modal__wrapper .modal--footer button.btn-primary{
    border: 0;
    background: var(--direct_mail_green);
    color: #fff;
    border-radius: 6px;
    display: flex;
    align-items: center;
    grid-gap: 5px;
    padding: 8px 10px;
    cursor: pointer;
}
.map__order__name__modal__wrapper .modal--footer button.btn-danger{
    border: 0;
    background: #eb4d4b;
    color: #fff;
    border-radius: 6px;
    display: flex;
    align-items: center;
    grid-gap: 5px;
    padding: 8px 10px;
    cursor: pointer;
}
.map__order__name__modal__wrapper .map__order__name__title{
    font-family: var(--poppins);
    font-size: 24px !important;
    line-height: 36px !important;
    font-weight: 500;
    color: var(--dark_blue) !important;
}
.map__order__name__modal__wrapper .modal-close svg {
    color: #fff
}

@media screen and (max-width: 1366px) {
    .maporder_section .data_table th:last-child {width: 70px;}
    .maporder_section .data_table td .view span {display: none;}
    .maporder_section .data_table td .view svg {margin: 0;}
    .maporder_section .page_heading p {max-width: 580px;}
    .maporder_section .data_table td input {height: 40px;}
    .maporder_section .data_table td .view {
        line-height: 38px;
        height: 40px;
        width: 40px;
    }
    .maporder_section .data_table td:first-child {
        white-space: nowrap;
        max-width: 185px;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .maporder_section .data_table td a.pen, 
    .maporder_section .data_table td a.com {min-width: auto;}
    .maporder_section .data_table th,
    .maporder_section .data_table td {font-size: 12px;}
    .maporder_section .data_table td input {font-size: 12px;}
    .maporder_section .data_table td a.edit {
        line-height: 24px;
        min-width: 24px;
        height: 24px;
        top: calc(50% - 12px);
    }
    .maporder_section .data_table td a.edit svg {
        height: 12px;
        width: 12px;
    }
    .maporder_section .data_table td svg.comp,
    .maporder_section .data_table td svg.pend {
        height: 20px;
        width: 20px;
    }
    .maporder_section .data_table td .view svg {
        height: 14px;
        width: 14px;
    }
    .maporder_section .data_table th svg {
        height: 15px;
        width: 10px;
        top: calc(50% - 7px);
    }
}
.select-dropdown.dropdown-content li:hover {background-color: rgba(60, 126, 243,0.1) !important;}
.select-dropdown.dropdown-content li:hover span {color: #3C7EF3 !important;}
@media screen and (max-width: 768px) {
    .maporder_section .data_table th:last-child {width: 50px;}
    .maporder_section {padding: 10px;}
    .maporder_section .page_heading h4 {
        line-height: 24px;
        font-size: 16px;
    }
    .maporder_section .page_heading p {
        line-height: 15px;
        max-width: 380px;
        font-size: 10px;
    }
    .maporder_section .page_heading .action a.refresh {min-width: 90px;}
    .maporder_section .page_heading .action a.order {
        min-width: 115px;
        margin-left: 10px;
    }
    .maporder_section .page_heading .action a {
        line-height: 40px;
        font-size: 12px;
        height: 40px;
    }
    .maporder_section .table_heading .select-wrapper input {font-size: 12px;}
    .maporder_section .table_heading .select-wrapper, 
    .maporder_section .table_heading select {font-size: 12px;}
    .maporder_section .table_heading .form_group label {font-size: 12px;}
    .maporder_section .table_heading .form_group.search {max-width: 215px;}
    .maporder_section .table_heading .form_group.search button svg {
        margin-top: 3px;
        height: 17px;
        width: 17px;
    }
    .maporder_section .table_heading .select-wrapper,
    .maporder_section .table_heading select {
        height: 30px;
        width: 72px;
    }
    .maporder_section .data_table th svg {
        top: calc(50% - 5px);
        width: 7px;
        height: 10px;
    }
    .maporder_section .data_table td:first-child {max-width: 110px;}
    .maporder_section .data_table th,
    .maporder_section .data_table td {
        line-height: 12px;
        padding: 8px 5px;
        font-size: 8px;
    }
    .maporder_section .data_table td svg.comp,
    .maporder_section .data_table td svg.pend {
        height: 12px;
        width: 12px;
        margin: -2px 0 0 0;
    }
    .maporder_section .data_table td a.edit {
        top: calc(50% - 8px);
        line-height: 16px;
        min-width: 16px;
        height: 16px;
    }
    .maporder_section .data_table td a.edit svg {
        height: 8px;
        width: 8px;
    }
    .maporder_section .data_table td a.pen, 
    .maporder_section .data_table td a.com {
        line-height: 20px;
        padding: 0 5px;
        height: 20px;
    }
    .maporder_section .data_table td input {
        font-size: 8px;
        height: 20px;
        width: 30px;
    }
    .maporder_section .data_table td .view {
        line-height: 22px;
        width: 24px;
        height: 24px;
    }
    .maporder_section .result {
        line-height: 20px;
        font-size: 10px;
    }
    .maporder_section .modal {max-width: 448px;}
    .maporder_section .modal .header h5 {
        line-height: 24px;
        font-size: 16px;
    }
    .maporder_section .modal .modal_article p {
        line-height: 22px;
        font-size: 12px;
    }
    .maporder_section .modal .modal_article input {
        font-size: 12px;
        height: 44px;
    }
    .maporder_section .modal .modal_article .save_btn {
        font-size: 12px;
        height: 40px;
        min-width: 100px;
    }

    
}