.helperText{
    margin-top:0 !important;
}
.remindStack,.buttonStack{
    display: flex;
    align-items: center;
    gap: 10px;
}
.inputAndPersonalized{
    display:flex;
    align-items: center;
    gap:20px;
    margin-top: 12px;
}
.labelAndPersonalized{
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding-bottom: 12px;
}
.modalFooterActions{
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}
.saveButton{
    background-color: #3C7EF3 !important;
    box-shadow: none !important;
}
.remindSelect{
    min-width: 150px;
    width: 30%;
}