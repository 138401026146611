.mainWrp{
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  gap: 10px;
}
.addButtonWrp{
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: center;
}
.addButton{
  width: 100%;
  height: 40px;
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: #006df5;
  color: #fff;
  text-transform: capitalize;
  letter-spacing: 0.35px;
  border-radius: 4px;
  gap: 10px;
}
.hintsText{
  font-size: 9px;
  color: #546376;
  display: flex;
  align-items: center;
}
.hintsIcon{
  width: 35px !important;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: help;
}
.listWrp{
  width: 100%;
  height: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  overflow-y: auto;
  padding: 5px;
  box-sizing: border-box;
}
.refreshButton{
  background-color: transparent;
  color: #1c1b1f;
  border: 1px solid #e0e0e0;
}
.refreshButton.active{
  cursor: not-allowed;
  opacity: 0.5;
}

.refreshButton.activeMore{
  background-color: #1c1b1f14;
  border: none;
  font-weight: 600 !important;
}

.loadingScreenWrp{
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
}