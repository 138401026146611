.add_new_user{
    margin-left: auto;
    margin-top: 8px;
    margin-right: 20px;
}

.add_new_user a {
    background: var(--light_blue);
    padding: 13px 18px;
    display: inline-flex;
    align-items: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: var(--white);
    border-radius: 5px;
}

.add_new_user a span{
    display: flex;
    margin-right: 10px;
}

@media only screen and (min-width: 768px) and (max-width: 992px){
    .add_new_user a{
        font-size: 12px;
    }
}

.profile_sidebar,
.profile_right_items {
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 50px;
}

.profile_sidebar_top {
    background: var(--dark_blue);
    min-height: 287px;
    flex-direction: column;
}

.profile_sidebar_top_img {
    position: relative;
}
.profile_sidebar_top_img img{
    height: 150px;
    width: 150px;
}


.profile_sidebar_top h5 {
    text-align: center;
}

.add_img_camera_icon {
    position: absolute;
    right: 0;
    bottom: 0;
    height: 50px;
    width: 50px;
    border: 4px solid var(--white);
    background: var(--light_blue);
    cursor: pointer;
}

.add_img_camera_icon i {
    font-size: 30px;
}

.profile_sidebar_items .tabs {
    flex-direction: column;
    /* height: fit-content; */
    height: auto;
}

.profile_sidebar_items .tabs .indicator {
    display: none;
}

.profile_sidebar_items .tabs .tab a {
    color: rgba(19, 49, 89, 0.65);
    text-transform: capitalize;
    background: var(--white);
    text-align: left;
    font-size: 14px;
    border-bottom: 1px solid rgba(60, 127, 243, 0.1);
    /* border-radius: 5px; */
}

.profile_sidebar_items .tabs .tab a svg {
    fill: rgba(19, 49, 89, 0.65);
}

.profile_sidebar_items .tabs .tab a.delete_account {
    color: var(--red);
}

.profile_sidebar_items .tabs .tab a:hover,
.profile_sidebar_items .tabs .tab a.active {
    background: var(--light_blue);
    color: var(--white);
}

.profile_sidebar_items .tabs .tab a.delete_account svg {
    fill: var(--red);
}

.profile_sidebar_items .tabs .tab a:hover svg {
    fill: var(--white);
}

/* Profile right */
.profile_right_heading {
    background: var(--dark_blue);
}

.profile_right_item .personal_info_text_field {
    border: 1px solid var(--gray_light) !important;
    border-radius: 5px !important;
    padding-left: 10px !important;
    background: var(--white) !important;
    box-sizing: border-box !important;
    box-shadow: none !important;
}

.profile_right_item input.personal_info_text_field {
    height: 42px !important;
}



.profile_right_item textarea.personal_info_text_field {
    height: 100px;
    outline: 0;
}

span.required__field {
    color: var(--red);
    padding-left: 2px;
}

.profile_right_footer {
    border-top: 1px solid var(--gray_dark);
}

.profile_right_footer_btn {
    background: var(--light_blue);
    color: var(--white);
    border-radius: 5px;
    transition: 0.3s ease-in-out;
}

.profile_right_footer_btn i {
    color: var(--light_blue);
    font-size: 20px;
    transition: 0.3s ease-in-out;
}

.profile_right_footer_btn:hover {
    background: var(--dark_blue);
}

.profile_right_footer_btn:hover i {
    color: var(--dark_blue);
}

.profile_right_footer_btn span {
    height: 26px;
    width: 26px;
}

.country__flag__select {
    width: 80px;
    height: 50px;
    border: 1px solid var(--gray_light) !important;
    border-right: 0 !important;
    border-radius: 5px 0 0 5px;
}

.country__flag__select:focus {
    outline: 0;
}

.personal_info_text_field.flag_phone_field {
    border-left: 0 !important;
}

.img__file__condition {
    color: rgba(19, 49, 89, 0.65);
}

.upload__field {
    max-width: 450px;
    border: 1px dashed var(--light_blue);
    border-radius: 5px;
    text-align: center;
    cursor: pointer;
}

.upload__field .place__holder i {
    font-size: 40px;
    color: var(--gray_dark);
    transition: 0.3s ease-in-out;
}

.upload__field .place__holder p {
    color: rgba(19, 49, 89, 0.65);
    font-size: 16px;
}

.upload__field .file_browse_btn {
    background: var(--gray_dark);
    color: rgba(19, 49, 89, 0.65);
    cursor: pointer;
    border-radius: 5px;
    width: 148px;
    transition: 0.3s ease-in-out;
}

.upload__field:hover .file_browse_btn {
    background: var(--light_blue);
    color: var(--white);
}

.upload__field:hover .place__holder i {
    color: var(--light_blue);
}

/* CSS Code by Sazzad */
.show-appointemnet-text svg {
    vertical-align: text-top;
    margin-left: 10px;
}

.ProfileModule-container-parent-class .Switch {
    position: relative;
    display: inline-block;
    font-size: 1.6em;
    font-weight: bold;
    color: #ccc;
    height: 26px;
    width: 52px;
    padding: 4px 4px 4px 4px;
    border: 1px solid rgba(19, 49, 89, 0.35);
    border-radius: 20px;
    background: var(--white);
    cursor: pointer;
    font-size: 16px;
    margin-left: 10px;
}

.ProfileModule-container-parent-class .Switch span {
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    text-align: center;
    /*background-color: #FF264A;*/
}

.ProfileModule-container-parent-class .Switch span.On {
    font-size: 16px;
    line-height: 24px;
    color: #219653;
    /*font-weight: 400;*/
}

.ProfileModule-container-parent-class .Switch .Toggle {
    position: absolute;
    top: 1px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: transparent;
    /* background: -moz-linear-gradient(top, #ececec 0%, #ffffff 100%);
    background: -o-linear-gradient(top, #ececec 0%, #ffffff 100%);
    background: -ms-linear-gradient(top, #ececec 0%, #ffffff 100%);
    background: linear-gradient(top, #ececec 0%, #ffffff 100%);
    -webkit-transition: all 0.15s ease-in-out; */
}

.ProfileModule-container-parent-class .Switch.On .Toggle {
    left: 4%;
    top: 3px;
}

.ProfileModule-container-parent-class .Switch.Off .Toggle {
    left: 73%;
    top: 3px;
}

.ProfileModule-container-parent-class .Switch.Round {
    padding: 0px 20px;
    border-radius: 40px;
}

body.IE7 .Switch.Round {
    width: 1px;
}

.ProfileModule-container-parent-class .ProfileModule-container-parent-class .Switch.Round .Toggle {
    border-radius: 40px;
    width: 14px;
    height: 14px;
}

.ProfileModule-container-parent-class .Switch.Round.Off .Toggle {
    left: 3%;
    background: #33d2da;
}

.ProfileModule-container-parent-class .Switch.Round.On .Toggle {
    left: 58%;
}

.ProfileModule-container-parent-class .info {
    font-size: 1.2em;
    margin-bottom: 4px;
}

.ProfileModule-container-parent-class .Switch.Off .Off {
    opacity: 0;
    visibility: hidden;
}

.ProfileModule-container-parent-class .Switch.On .On {
    opacity: 1;
    visibility: visible;
}

.ProfileModule-container-parent-class .Switch.On .Off {
    visibility: visible;
    opacity: 1;
    color: #fff;
    transition: all 0.4s;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400 !important;
}

.ProfileModule-container-parent-class .Switch.On .On {
    visibility: hidden;
    opacity: 0;
    transition: all 0.4s;
}

.settings_section .back_button_to_account a,
.package_page_settings_section .back_button_to_account a {
    padding: 8px 15px;
    background: #3c7ef3;
    border-radius: 5px;
    font-family: var(--poppins);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    letter-spacing: .01em;
    color: #fff;
}

.settings_section .back_button_to_account,
.package_page_settings_section .back_button_to_account
{
    width: 100px;
}

.settings_section {
    position: relative;
}

.back_button_to_account.has_gap,
.back_button_component.has_gap{
    float: right;
    position: absolute;
    top: 0;
    right: 20px;
    z-index: 99;
}

.back_button_component.has_gap {
    border: 1px solid var(--accent-text-color);
}

.back_button_to_account.back_button_no_gap {
    float: right;
}

.settings_section .back_button_to_account span,
.package_page_settings_section .back_button_to_account span,
.back_button_to_account.back_button_no_gap span,
.back_button_to_account.has_gap span
{
    margin-left: 10px;
}

.modal-trigger.accent--bg--text--color + .modal-trigger.accent--bg--text--color {
    margin-left: 8px;
}

.profile_right_item img.responsive-img.mh250 {
    max-height: 250px;
}

.create-collaborate-modal.create-collaborate-modal-alt{
    position: unset !important;
}




.ProfileModule-container-parent-class .profile__right__side__wr .profile__right__side__single{
    margin: 0 20px;
}
.ProfileModule-container-parent-class .profile__right__side__wr .profile__right__side__single +.profile__right__side__single{
    padding: 8px 0 0 0;
}
.ProfileModule-container-parent-class .profile__right__side__wr .profile__right__side__single__p{
    margin: 0 0 4px 0;
    font-size: 14px;
}

.ProfileModule-container-parent-class .profile__right__side__wr .personal_info_text_field {
    margin: 0 !important;
    background: #F5F7F9 !important;
    font-size: 12px;
    color: rgba(19, 49, 89, 0.65);
}
.ProfileModule-container-parent-class .profile__right__side__wr textarea.personal_info_text_field{
    resize: none;
}
.ProfileModule-container-parent-class .profile__right__side__wr .profile__right__side__single__phone .personal_info_text_field{
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important
}
.ProfileModule-container-parent-class .profile__right__side__wr .country__flag__select{
    height: 42px;
    background: #F5F7F9 !important;
    border-right: 1px solid var(--gray_dark) !important
}
.ProfileModule-container-parent-class .profile__right__side__wr .profile__right__side__single__phone .l8.m8{
    width: 100%;
    padding-right: 0 !important;
}
.ProfileModule-container-parent-class .profile__right__side__wr .profile__right__side__single__container{
    padding-top: 20px;
}
.ProfileModule-container-parent-class .profile_right_footer .profile_right_footer__btn__wr{
    border: 1px solid var(--accent-text-color);
    cursor: pointer;
    border-radius: 5px;
    font-size: 14px;
    margin-left: 20px !important;
}

.ProfileModule-container-parent-class .profile_right_footer .profile_right_footer__btn__wr:hover{
    background: #0c1038 !important;
}

.ProfileModule-container-parent-class .profile_right_footer .profile_right_footer__btn__wr i{
    font-size: 18px;
}


.ProfileModule-container-parent-class .profile_sidebar__v2 .profile_sidebar_items{
    padding: 20px;
}
.ProfileModule-container-parent-class .profile_sidebar__v2 .profile_sidebar_items .tabs{
    border: 1px solid rgba(60, 127, 243, 0.1);
    border-radius: 5px;
}
.ProfileModule-container-parent-class .profile_sidebar__v2 .profile_sidebar_items .tabs li:last-child a{
    border-bottom: 0 !important;
}
.ProfileModule-container-parent-class .profile_sidebar__v2 .profile_sidebar_items .tabs .tab i{
    font-size: 18px;
}
.ProfileModule-container-parent-class .profile__right__side__wr .profile__right__side__single .show-appointemnet-text{
    font-size: 14px;
}
.ProfileModule-container-parent-class .api-key-text{
    margin-left: auto;
    margin-top: 0px;
    margin-right: 25px;
    margin-bottom: 0px;
    font-style: oblique;
}

.create-new-quick-clone {
    text-align: end;
}

.create-new-quick-clone a {
    background: var(--light_blue);
    padding: 13px 18px;
    display: inline-flex;
    align-items: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: var(--white);
    border-radius: 5px;
}

.create-new-quick-clone a span{
    display: flex;
    margin-right: 10px;
}