/* settings section start */
.main__content {width: 100%;}
.settings_section {
    min-height: calc(100vh - 120px);
    background: #fff;
    /* margin: 20px 0; */
    overflow: hidden;
    border-radius: 10px;
}
nav {
    background-color: unset;
}
.settings_section .tabs .tab {
    line-height: 80px;
    height: 80px;
}
.settings_section .tabs {
    border-bottom: 1px solid #E5E8EF;
    height: 80px;
}
.settings_section .tabs .indicator {background: #3C7EF3;}
.settings_section .tabs a.active path,
.settings_section .tabs a:hover path {
    fill-opacity: 1;
    fill: #3C7EF3;
}
.settings_section .tabs a:hover,
.settings_section .tabs a.active {
    background: none !important;
    color: #3C7EF3;
    border-bottom: 2px solid #3C7EF3;
}
.settings_section .tabs a span {color: #FF264A;}
.settings_section .tabs a svg {
    vertical-align: middle;
    margin: -3px 5px 0 0;
    display: inline-block;
}
.settings_section .tabs a {
    font-weight: 500;
    font-size: 20px;
    text-transform: capitalize;
    color: rgba(19, 49, 89, 0.65);
}
.settings_section .settings_content {padding: 15px 25px;}

/* general setting */
.settings_section .color_plate {padding: 0 25px 0 0;}
.settings_section .color_plate input:checked + .slider:before {left: -4px;}
.settings_section .color_plate .switch {
    display: flex;
    width: 56px;
}
.settings_section .color_plate li h5 {
    font-size: 16px;
    margin: 0;
}
.updated.settings_section .dialer__setting_wrapper label {
    display: flex;
    align-items: center;
}
.updated.settings_section .dialer__setting_wrapper label h6 {
        margin: 0 10px 0 0;
        line-height: 22px;
}

.settings_section .color_plate li {
    justify-content: space-between;
    align-items: center;
    display: flex;
    font-size: 16px;
    color: #133159;
    margin-bottom: 25px;
}
.settings_section .color_plate li .color_option {
    justify-content: space-between;
    align-items: center;
    display: flex;
    min-width: 110px;
    margin-left: 15px;
}
.settings_section .color_plate input {
    border: 2px solid #3C7EF3;
    padding: 0;
    height: 32px;
    width: 74px;
    position: relative;
    border-radius: 4px;
 }
 .settings_section .color_plate input[type="color"]::after {
    display: inline-block;
    content: '';
    background-color: inherit;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
 .settings_section .color_plate li .color_option svg {margin-left: 15px;}
 .settings_section .theme_plate {padding-left: 5%;}
.settings_section .theme_plate h5 {
    margin-top: 10px;
    color: #133159;
    font-size: 20px;
}
.settings_section .theme_option {
    flex-wrap: wrap;
    display: flex;
}
.settings_section .theme_color {
    margin: 0 20px 35px 0;
    cursor: pointer;
}
.settings_section .theme_color svg {
    margin-bottom: 10px;
    display: block;
}
.settings_section .theme_plate label span {
    color: rgba(19, 49, 89, 0.65);
    padding-left: 25px;
    font-size: 16px;
}
.settings_section .theme_plate label {
    display: inline-block;
    margin-top: 8px;
}
.settings_section [type="radio"]:not(:checked)+span:before, 
.settings_section [type="radio"]:not(:checked)+span:after {
    border: 1px solid #133159;
}
.settings_section [type="radio"]:checked+span:after {background: #3C7EF3;}
.settings_section .font_option {
    flex-wrap: wrap;
    display: flex;
}
.settings_section [type="radio"]:checked+span:before,
.settings_section [type="radio"]:checked+span:after {border: 2px solid #3C7EF3;}
.settings_section .font_select {margin: 10px 20px 15px 0;}
.settings_section .font_choice {
    background: #F6F7FB;
    border-radius: 5px;
    width: 114px;
    padding: 14px 16px;
}
.settings_section .font_choice h6 {
    line-height: 30px;
    color: #133159;
    font-size: 16px;
    margin: 0;
}
.settings_section .font_choice p {
    color: rgba(19, 49, 89, 0.65);
    line-height: 24px;
    font-size: 14px;
    margin: 0;
}
/* cut time */
.settings_section #cut_time p {
    margin-bottom: 25px;
    color: #133159;
    font-size: 16px;
    line-height: 30px;
}
.settings_section #cut_time p.msg {
    color: rgba(19, 49, 89, 0.65);
    font-size: 14px;
    margin: 15px 0 45px;
    line-height: 24px;
}
.settings_section #cut_time p.msg span {color: #FF264A;}
.settings_section #cut_time p svg {
    vertical-align: middle;
    margin-right: 5px;
    margin-top: -3px;
}
.settings_section .time_input {
    justify-content: space-between;
    align-items: center;
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    margin-top: 15px;
    max-width: 800px;
    position: relative;
}
.settings_section .time_input > label,
.settings_section #cut_time h5 {
    margin-bottom: 15px;
    line-height: 24px;
    font-size: 16px;
    overflow: hidden;
    min-width: 400px;
    color: #133159;
    margin-right: 15px;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.settings_section #cut_time .form_group {
    max-width: 375px;
    width: 100%;
    padding: 0;
    position: relative;
}
.settings_section #cut_time .time_clock {
    background: #667993;
    margin: 0 !important;
    position: absolute;
    right: 0;
    top: 1px;
    width: 51.73px;
    height: 50px;
    line-height: 67px;
    text-align: center;
}
.settings_section #cut_time input {
    color: rgba(19, 49, 89, 0.65);
    width: calc(100% - 25px);
    font-size: 16px;
    margin: 0;
    height: 50px;
    line-height: 50px;
    padding: 0 12px;
    border-radius: 4px;
    border: 1px solid #667993;
}
.settings_section #cut_time hr {
    border-bottom: none;
    margin: 25px 0;
    border-top: 1px solid #E5E8EF;
}
.settings_section #cut_time .form_group label {
    margin: 11px 0;
    display: block;
}
.settings_section #cut_time .form_group label span {
    line-height: 24px;
    font-size: 16px;
    color: #133159;
}
#cut_time [type="checkbox"].filled-in:not(:checked)+span:not(.lever):after {border: 1px solid #3C7EF3;}
#cut_time [type="checkbox"].filled-in:checked+span:not(.lever):after {
    border: 2px solid #3C7EF3;
    background: #3C7EF3;
}
#cut_time [type="checkbox"].filled-in:not(:checked)+span:not(.lever):after,
#cut_time [type="checkbox"].filled-in:checked+span:not(.lever):after {top: 2px;}
#cut_time [type="checkbox"].filled-in:checked+span:not(.lever):before {top: 1px;}
.settings_section #cut_time .btn_submit:hover {background: #68EBCA;}
.settings_section #cut_time .btn_submit {
    display: inline-block;
    background: #3C7EF3;
    border-radius: 4px;
    padding: 0 20px;
    font-size: 16px;
    border: none;
    height: 50px;
    color: #fff;
    cursor: pointer;
    min-width: 217px;
    font-weight: 500;
    line-height: 50px;
    text-align: center;
    transition: all .2s;
    font-family: var(--poppins);
}
/* timezone */
.settings_section #timezone p {
    color: rgba(19, 49, 89, 0.65);
    line-height: 30px;
    font-size: 16px;
}
.settings_section #timezone p.alart {
    font-size: 14px;
    color: #FF264A;
    line-height: 24px;
}
.settings_section #timezone p img {
    vertical-align: middle;
    margin-right: 5px;
    margin-top: -3px;
}
.settings_section .selet_input {
    align-items: center;
    display: flex;
    width: 100%;
    margin-top: 25px;
    max-width: 420px;
}
.settings_section .selet_input label {
    line-height: 24px;
    font-size: 16px;
    color: #133159;
    margin-right: 15px;
}
.settings_section .selet_input .time_select {
    position: relative;
    width: 316px;
}
.settings_section .selet_input .time_select svg {
    position: absolute;
    right: 8px;
    z-index: 999;
    height: 49px;
    top: 1px;
    width: 12px;
    background: #fff;
}
.settings_section .selet_input .select-wrapper input {
    border-bottom: none;
    padding: 0 12px;
    height: 50px;
    margin: 0;
    border-bottom: none;
}
.settings_section .selet_input .select-dropdown {width: 100% !important;}
.settings_section .selet_input .select-wrapper,
.settings_section .selet_input select {
    border: 1px solid rgba(19, 49, 89, 0.35);
    color: rgba(19, 49, 89, 0.65);
    font-size: 16px;
    width: 100%;
    max-width: 325px;
    border-radius: 5px;
}
/* notification  table */
.settings_section table thead {background: #E5E8EF;}
.settings_section table {
    border-radius: 10px;
    overflow: hidden;
}
.settings_section table tbody tr:last-child {border: none;}
.settings_section .notification_table a {
    line-height: 24px;
    font-size: 14px;
    display: block;
    color: #3C7EF3;
}
.settings_section .notification_table th svg,
.settings_section .notification_table td img {
    vertical-align: middle;
    margin: 0 5px;
    width: 20px;
}
.settings_section .switch {
    display: inline-block;
    position: relative;
    width: 66px;
    height: 30px;
    cursor: pointer;
}
.settings_section [type="checkbox"]:checked + span + small {display: none;}
.settings_section .switch input { 
  	opacity: 0;
  	width: 0;
  	height: 0;
}
.settings_section .slider {
    position: absolute;
    transition: .4s;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
}
.settings_section .slider:before {
    position: absolute;
    content: "";
    height: 24px;
    width: 24px;
    left: 4px;
    bottom: 3px;
    transition: .4s;
    background-color: #fff;
}
.settings_section input:checked + .slider {background-color: #2196F3;}
.settings_section input:checked + .slider:before {
    transform: translateX(34px);
    border: none;
    top: 0px;
    left: 5px;
    width: 24px;
    height: 24px;
    background: #fff;
}
.settings_section .slider.round {
    border-radius: 34px;
    height: 30px;
    background: #E5E8EF;
}
.settings_section .slider.round:before {
    border-radius: 50%;
    border: none;
    top: 1px;
    left: 5px;
    width: 22px;
    z-index: 99;
    height: 22px;
    background: #9CA8BA;
}
.settings_section .switch .on {
    color: #fff;
    left: 4px;
}
.settings_section .switch .on,
.settings_section .switch .off {
    line-height: 32px;
    position: absolute;
    height: 30px;
    top: 0;
    z-index: 9;
    font-size: 16px;
    text-transform: uppercase;
}
.settings_section .switch .off {
    color: #9CA8BA;
    right: 5px;
}
.settings_section table th.text-center,
.settings_section table td.text-center {text-align: center;}
.settings_section table th {
    border: 1px solid #fff;
    white-space: nowrap;
    position: relative;
    font-size: 16px !important;
    line-height: 30px !important;
}
.settings_section table th,
.settings_section table td {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
}
/* calloutcome */
.settings_section #call_outcome .heading {
    border-bottom: 1px solid #E5E8EF;
    align-items: center;
    padding: 5px 0 20px;
    display: flex;
    justify-content: space-between;
}
.settings_section #call_outcome .heading h5 {
    line-height: 30px;
    font-size: 16px;
    margin: 0 15px 0 0;
 }
.settings_section #call_outcome .heading h5 span {
    color:rgba(19, 49, 89, 0.65);
 }
 .settings_section #call_outcome .btn_submit:hover {background: #68EBCA;}
 .settings_section #call_outcome .btn_submit {
    display: inline-block;
    background: #3C7EF3;
    border-radius: 5px;
    padding: 0 10px;
    font-size: 16px;
    border: none;
    height: 50px;
    color: #fff;
    cursor: pointer;
    min-width: 243px;
    font-weight: 500;
    line-height: 50px;
    text-align: center;
    transition: all .2s;
    font-family: var(--poppins);
}
.settings_section #call_outcome .btn_submit svg {
    margin: -5px 6px 0 0;
    vertical-align: middle;
 }
.settings_section #call_outcome .search_form {
    justify-content: space-between;
    display: flex;
    margin: 20px 0;
}
.settings_section #call_outcome .form_group {
    align-items: center;
    display: flex;
}
.settings_section #call_outcome .form_group.search {
    border: 1px solid rgba(19, 49, 89, 0.35);
    border-radius: 5px;
    border-radius: 5px;
    max-width: 245px;
    width: 100%;
}
.settings_section #call_outcome .form_group label {
    line-height: 30px;
    color: #133159;
    font-size: 16px;
}
.settings_section #call_outcome .select-wrapper input {
    border-bottom: none;
    padding: 0 12px;
    margin: 0;
    border-bottom: none;
}
.settings_section #call_outcome .select-dropdown {width: 100% !important;}
.settings_section #call_outcome .select-wrapper,
.settings_section #call_outcome select {
    border: 1px solid rgba(19, 49, 89, 0.35);
    color: rgba(19, 49, 89, 0.65);
    font-size: 16px;
    width: 65px;
    margin: 0 10px;
    border-radius: 5px;
}
.settings_section #call_outcome .select-wrapper+label {position: inherit;}
.settings_section #call_outcome .form_group.search input:focus {
    outline: none;
    outline: none;
    border: none;
}
.settings_section #call_outcome .form_group.search input {
    padding: 0 0 0 15px;
    border: none;
    margin: 0;
}
.settings_section #call_outcome .form_group.search button {
    padding-right: 12px;
    background: none;
    cursor: pointer;
    width: 42px;
    border: none;
    outline: none;
}
.settings_section .data_table th svg {
    position: absolute;
    right: 15px;
    top: 18px;
}
.settings_section .data_table th,
.settings_section .data_table td {padding-left: 10%;}
.settings_section .data_table th:last-child,
.settings_section .data_table td:last-child {padding-left: 24%;}
.settings_section #call_outcome td {color: rgba(19, 49, 89, 0.65)}
.settings_section #call_outcome .delete {color: #FF264A;}
.settings_section #call_outcome .edit {color: #133159;}
.settings_section #call_outcome .delete,
.settings_section #call_outcome .edit {
    border: 1px solid #E5E8EF;
    display: inline-block;
    border-radius: 5px;
    font-weight: 500;
    font-size: 16px;
    min-width: 75px;
    padding: 0 8px;
    height: 40px;
    line-height: 40px;
    transition: all .2s;
}
.settings_section #call_outcome .delete svg,
.settings_section #call_outcome .edit svg {
    vertical-align: middle;
    margin: -5px 5px 0 0;
    color: #fff;
}
.settings_section #call_outcome .delete:hover path {fill: #fff;}
.settings_section #call_outcome .delete:hover {
    border-color: #FF264A;
    background: #FF264A;
    color: #fff;
}
.settings_section #call_outcome .edit:hover path {fill: #fff;}
.settings_section #call_outcome .edit:hover {
    border-color: #133159;
    background: #133159;
    color: #fff;
}
.settings_section .responsive-table {min-height: 75vh;}
.settings_section #call_outcome .result {
    line-height: 24px;
    font-size: 14px;
    color: #133159;
}
.settings_section .pagination {
    justify-content: center;
    display: flex;
    margin-bottom: 25px;
    align-items: center;
}
.settings_section .pagination li {
    font-size: 32px;
    min-width: 50px;
    height: auto;
    color: #3C7EF3;
    margin-right: 0;
}
.settings_section .pagination li small {
    margin-right: 12px;
    font-size: 18px;
}
.settings_section .pagination li span {font-weight: 300;}
.settings_section .pagination li small,
.settings_section .pagination li span {
    display: inline-block;
    padding-top: 3px;
    height: 50px;
    min-width: 30px;
    line-height: 50px;
    text-align: center;
}
.settings_section .pagination li input {
    border: 2px solid #3C7EF3;
    margin: 0 0 0 10px;
    padding: 0;
    width: 65px;
    height: 46px;
    font-size: 18px;
    text-align: center;
    border-radius: 5px;
}
.settings_section .pagination li .prev:hover,
.settings_section .pagination li .next:hover {background: #68EBCA;}
.settings_section .pagination li .next, 
.settings_section .pagination li .prev {
    display: inline-block;
    background: #3C7EF3;
    border-radius: 5px;
    padding: 0 20px;
    font-size: 16px;
    border: none;
    height: 50px;
    color: #fff;
    cursor: pointer;
    width: 50px;
    font-weight: 500;
    line-height: 56px;
    text-align: center;
    transition: all .2s;
}
/* responsive */
@media screen and (max-width: 1370px) {
    .settings_section .font_select {margin: 10px 17px 15px 0;}
    .settings_section .theme_plate {padding-left: 2%;}
    .settings_section .tabs a {
        font-size: 16px;
        padding: 0 15px;
    }
}
@media screen and (max-width: 1168px) {
    .settings_section .tabs {
        height: 90px;
        display: flex;
        align-items: flex-start;
    }
    .updated.settings_section .tabs a {  
        padding: 31px 15px;
        max-width: initial;
    }
    .settings_section .tabs a {
        white-space: initial;
        line-height: 20px;
        max-width: 130px;
        font-size: 16px;
        padding: 0 8px;
    }
    .settings_section .tabs a svg {
        margin: 8px auto;
        display: block;
    }
    .settings_section .tabs a span {display: block;}
}
@media screen and (max-width: 1135px) {
    .settings_section .time_input > label {display: none;}
}
@media screen and (max-width: 768px) {
    .settings_section .theme_plate {padding-left: 0;}
    .settings_section .tabs a {padding: 0 4px;}
    .settings_section p {
        line-height: 24px !important;
        font-size: 14px !important;
    }
    .settings_section table th {
        white-space: nowrap;
        font-size: 16px !important;
    }
    .settings_section table th svg {
        height: 16px;
        width: 16px;
    }
    .settings_section .notification_table th svg,
    .settings_section .notification_table td img {
        height: 16px;
        width: 16px;
    }
    .settings_section .data_table th:last-child,
    .settings_section .data_table td:last-child {
        padding-left: 15px;
        width: 45%;
    }
    .settings_section #cut_time p svg {
        height: 16px;
        width: 16px;
    }
    .settings_section .tabs a.cut_time {max-width: 105px;}
}
/* settings section end */


/* time picker */
@media(max-width: 1366px) {
    .picker_container {
        margin: auto;
    } 

    .outside_container.active .modal_container {
        top: unset !important;
        bottom: 100% !important;
        margin-bottom: -30px;
    }
}

.time_select.timezone-time-select input{
    height:auto;
}

.settings_section .selet_input .time_select.timezone-time-select svg {
    height: 36px;
}


/* notify css */
.notify__wrapper__v2 .notify__container__v2 .modal_body input{
    border: 1px solid var(--gray_dark)!important;
    color: var(--gray);
    box-shadow: none!important;
    padding-left: 15px!important;
    box-sizing: border-box!important;
    background: #fff !important;
}
.notify__wrapper__v2 .notify__container__v2 .modal_body  .notify__account__input__error{
    color: var(--red);
    margin: 0 0 8px 0 !important;
    display: inline-block;
}