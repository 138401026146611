.record_search .search-text{
    background: #FFFFFF;
    border: 1px solid rgba(19, 49, 89, 0.65) !important;
    border-radius: 5px !important;
    width: 446px !important;
    height: 40px !important;
    box-sizing: border-box !important;
    padding: 0 30px 0 10px !important;
    font-size: 16px !important;
    line-height: 22px !important;
    letter-spacing: 0.01em  !important;
    color: rgba(84, 99, 118, 0.65) !important;
}
.webhook-report-filter .MuiInputBase-root{
    background: #FFFFFF;
    border: 1px solid rgba(19, 49, 89, 0.65) !important;
    border-radius: 5px !important;
    min-width: 200px !important;
    height: 40px !important;
    box-sizing: border-box !important;
    padding: 0 30px 0 10px !important;
    font-size: 16px !important;
    line-height: 22px !important;
    letter-spacing: 0.01em !important;
    color: rgba(84, 99, 118, 0.65) !important;
}

.webhook-report-filter .MuiSelect-select{
    background: #FFFFFF !important;
}
.webhook-report-filter .MuiSelect-select:focus{
    background: #FFFFFF !important;
}

.webhook-datepicker .MuiFormControl-marginNormal {
    margin: 0px;

}
.webhook-datepicker .MuiInput-input{
    width: 170px !important;
    margin: 0px;
    background: #FFFFFF;
    border: 1px solid rgba(19, 49, 89, 0.65) !important;
    border-radius: 5px !important;
    height: 40px !important;
    box-sizing: border-box !important;
    padding: 0 30px 0 10px !important;
    font-size: 16px !important;
    letter-spacing: 0.01em !important;
    color: rgba(84, 99, 118, 0.65) !important;
}

.webhook-datepicker .MuiInputBase-input.MuiInput-input.MuiInputBase-inputAdornedEnd{
    border-bottom: 1px solid rgba(19, 49, 89, 0.65) !important;
}

.m__report_tooltip{
    cursor: pointer;
}
.m__report_tooltip div{
    display: none;
}

.m__report_tooltip:hover div{
    display: block;
    position: absolute;
    width: 400px;
    padding: 16px;
    border-radius: 6px;
    z-index: 5;
    background: whitesmoke;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

}