.rightMain{
  width: 100%;
  height: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.listWrp{
  width: 100%;
  height: calc(100% - 61px);
  display: flex;
  /* grid-template-columns: repeat(1, 1fr); */
  /* flex-grow: 1; */
  overflow: hidden;
  overflow-y: auto;
  gap: 10px;
  flex-direction: column;
  padding: 5px;
  box-sizing: border-box;
}
.paginationWrp{
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #ddd;
}
.emptyScreen{
  width: 100%;
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
}
@media only screen and (max-width: 1440px) {
  .listWrp {
    /* grid-template-columns: repeat(1, 1fr) !important; */
  }
}

/* pagination size */
.labelWrp {
  display: flex;
  float: left;
  padding: 3px 30px;
  cursor: pointer;
  border: 1px solid #ddd;
  border-radius: 4px;
  height: 40px;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}
.popDlvnWrapper{
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
  justify-content: center;
  padding: 5px 0px;
  border-radius: 7px;
}
.popDlvnItem{
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
  padding: 3px;
}
.popDlvnItem:hover{
  background-color: #ddd;
}