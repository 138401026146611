button, input, optgroup, select, textarea{
  font-family: var(--poppins) !important;
}
/* ==============Packages page css============= */
.price_packages{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  margin-top: 30px;
}
.packages_page_content h1{
  font-weight: 600;
  font-size: 32px;
  line-height: 48px;
  letter-spacing: 0.02em;
  color: #3C7EF3;
  margin: 0;
}

.packages_page_content p{
  font-size: 16px;
  line-height: 24px;
  color: rgba(19, 49, 89, 0.65);
  margin: 0;
}
.packages_page_content p span{
  font-size: 12px;
  line-height: 18px;
  color: #68EBCA;
}
.packages_page_content{
  clear: both;
  background: #F4F5F7;
  /* padding-top: 20px; */
  /* margin-left: -20px; */
  /* margin-right: -20px; */
}
.mnth_year_content{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  margin-left: 60px;
}
.switch label{
  position: relative;
}
.switch label input[type=checkbox] ~.no{
  color: rgba(19, 49, 89, 0.5);
}
.switch label input[type=checkbox] ~.yes{
  color: #133159;
}
.switch label input[type=checkbox]:checked ~.no{
  color: #133159;
}
.switch label input[type=checkbox]:checked ~.yes {
  color: rgba(19, 49, 89, 0.5);
}
.switch label .yes{
  position: absolute;
  left: -100px;
}
label .no{
  text-transform: capitalize;
}






.first_price_list{
  max-width: 350px;
  width: 100%;
  box-shadow: 0px 10px 10px -5px rgba(60, 126, 243, 0.25);
  border-radius: 10px;
  position: relative;
  overflow: hidden;
}
.first_price_list .ribon{
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  position: absolute;
  color: var(--white);
  background: var(--light_blue);
  text-transform: capitalize;
  transform: rotate(-42.48deg);
  padding: 7px 36px;
  top: 26px;
  left: -36px;
}
.first_price_list .card-content{
  padding: unset;
  padding-top: 20px;
}
.center_price_list h5{
  margin-top: 48px !important;
}
.first_price_list h5{
  margin: 0;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: #133159;
}
.first_price_list h1{
  font-weight: 500;
  font-size: 72px;
  line-height: 108px;
  letter-spacing: 0.01em;
  color: #3C7EF3;
  text-transform: uppercase;
}
.price_content{
  padding-top: 0px !important;
}
.price_content small{
  font-weight: 600;
  font-size: 32px;
  line-height: 48px;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: #3C7EF3;
  position: absolute;
  left: 60px;
  top: 112px;
}
.secong_pric_list small{
  top: 64px !important;
  left: 55px !important;
}

.price_content h2{
  font-weight: 500;
  font-size: 72px;
  line-height: 108px;
  letter-spacing: 0.01em;
  color: var(--light_blue) !important;
  margin: 0;
  position: relative;
  display: inline-block;
}
.price_content h2 span{
  font-weight: normal;
  font-size: 20px;
  line-height: 36px;
  text-transform: capitalize;
  color: rgba(19, 49, 89, 0.65);
}
.price_content sup{
  position: absolute;
  top: 12%;
  left: -21px;
  font-weight: 600;
  font-size: 32px;
  line-height: 48px;
}
.price_content p{
  margin: 0 !important;
  margin-top: -20px !important;
}

.show_more_btn{
  transform: rotate(90deg);
  margin-left: -54px;
  background: #3C7EF3;
  border-radius: 10px 10px 0px 0px;
  padding: 10px 20px;
  color: white;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  cursor: pointer;
}

/* .switch label .lever{
  background: rgba(60, 126, 243, 0.2);
  border-radius: 10px;
  margin: unset;
}
.switch label .lever:after{
  background-color: #3C7EF3;
}
.switch label .lever:before{
  background: rgba(60, 126, 243, 0.2) !important;
}
.switch label input[type=checkbox]:checked+.lever{
  background: rgba(60, 126, 243, 0.2);
}
.switch label input[type=checkbox]:checked+.lever:after{
  background-color: #3C7EF3;
} */
.mnth_year_content label{
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: var(--dark_blue);
}
.mnth_year_content p{
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: rgba(19, 49, 89, 0.35);
  margin-left: 30px;
}
.pric_list_content{
  border: unset !important;
}
.pric_list_content .collection-item{
  text-align: left;
  font-size: 16px;
  line-height: 24px;
  color: rgba(19, 49, 89, 0.65);
}
.pric_list_content .collection-item span{
  margin-right: 8px;
}
.pric_list_content .collection-item:last-child{
  border-bottom: 1px solid #e0e0e0 !important;
}
.select_plan_btn{
  padding-bottom: 20px;
}
.select_plan_btn button{
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: var(--light_blue);
  box-shadow: unset;
  padding: 15px 65px 40px 65px;
  text-transform: capitalize;
  background: rgba(60, 126, 243, 0.05);
  border-radius: 5px;
}
.select_plan_btn button:focus{
  background-color: var(--light_blue)!important;
  color: white !important;
}
.select_plan_btn button:hover{
  box-shadow: unset;
  color: var(--white);
  background: var(--light_blue);
  border-radius: 5px;
}


/* ===============Payment css============== */
/* .payment_page{
  margin: 30px 20px 0px 30px;
} */
.payment_topbar{
  background: #FFFFFF;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 10px;
}
.payment_title h3{
  font-weight: 500;
  font-size: 24px;
  line-height: 40px;
  color: #133159;
  margin: 0;
}

.back_button a{
  background: rgba(60, 126, 243, 0.05);
  border-radius: 5px;
  padding: 12px 18px;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #3C7EF3;
}
.back_button a span{
  margin-right: 10px;
  display: flex;
  align-items: center;
}

.package_payment{
  background: var(--white);
  border-radius: 10px;
  text-align: left;
  max-width: 1028px;
  margin-right: 60px;
}
.package_payment_title{
  border-bottom: 1px solid #E5E8EF;
  padding: 10px 30px;
}
.package_payment_title h2{
  font-weight: normal;
  font-size: 24px;
  line-height: 40px;
  color: var(--dark_blue);
  margin: 0;
  text-align: left;
}
.package_payment_title p{
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #546376;
  text-align: left;
}
.people_info{
  border-bottom: 1px solid #E5E8EF;
  padding: 10px 30px 10px;
}
/* [type="checkbox"].filled-in:not(:checked)+span:not(.lever):after{
  border: 1px solid #6236FF !important;
  box-sizing: border-box;
  border-radius: 2px;
} */
/* [type="checkbox"].filled-in:checked+span:not(.lever):after{
  border: 2px solid #3C7EF3;
  background-color: #3C7EF3;
}
[type="checkbox"].filled-in:disabled:checked+span:not(.lever):after{
  background: #E5E8EF;
  border: 2px solid #E5E8EF;
} */
.package_next_btn{
  padding: 20px 30px;
}
.package_next_btn a,
.package_next_btn button{
  background: #3C7EF3;
  border-radius: 5px;
  border: unset;
  padding: 13.5px 78px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: white;
  text-transform: capitalize;
  cursor: pointer;
  height: auto;
}
.person h6{
  font-size: 16px;
  line-height: 30px;
  color: #133159;
  margin: 0;
}
.person p{
  margin-bottom: 10px;
}
.person p span{
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: rgba(19, 49, 89, 0.65) !important;
}





.order_summery{
  background: #FFFFFF;
  box-shadow: 0px 3px 0px #3c7ef3;
  border-radius: 10px;
  max-width: 474px;
  width: 350px;
}
.order_title h2{
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: var(--dark_blue);
  margin: 0;
  border-bottom: 1px solid #E5E8EF;
  padding: 25px;
}
.packages{
  border-bottom: 1px solid rgba(19, 49, 89, 0.05);
  padding: 10px 30px 0px;
}
.tax_subtotal ul,
.packages ul{
  display: flex;
  justify-content: space-between;
}
.tax_subtotal ul li:first-child,
.packages ul li:first-child{
  font-weight: normal;
  font-size: 16px;
  line-height: 30px;
  color: rgba(19, 49, 89, 0.65);
}
.tax_subtotal ul li.subtotal{
  color: var(--dark_blue);
}
.tax_subtotal ul li:nth-child(2),
.packages ul li:nth-child(2){
  font-weight: normal;
  font-size: 16px;
  line-height: 30px;
  color: #3C7EF3;
}
.tax_subtotal ul li.total_price{
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #3C7EF3;
}
.tax_subtotal{
  border-bottom: 1px solid rgba(19, 49, 89, 0.05);
  padding: 0px 30px;
}

.order_summery_bottom_content h1{
  font-weight: 500;
  font-size: 20px;
  line-height: 36px;
  text-align: center;
  color: var(--dark_blue);
  margin: 0;
  padding: 40px 0;
}
.order_summery_bottom_content h1 span{
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  color: #3C7EF3;
  margin: 0 13px;
}
.order_summery_bottom_content p{
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: rgba(19, 49, 89, 0.65);
  padding: 0 30px;
  text-align: left;
  padding-bottom: 20px;
}
.order_summery_bottom_content p span{
  color: var(--dark_blue);
}
.trams_condition{
  padding-bottom: 2px;
}
.trams_condition a{
  font-size: 14px;
  line-height: 30px;
  letter-spacing: 0.01em;
  color: #3C7EF3;
  padding: 20px 0px 10px;
}












.select_payment{
  padding: 10px 21px; 
}
.card_field{
  margin-bottom: 201px;
}
.select_payment h6{
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #133159;
  margin-bottom: 7px;
}
.card_content{
  margin-bottom: 325px;
}
.card_content_field{
  border: 1px solid rgba(60, 126, 243, 0.5);
  box-sizing: border-box;
  border-radius: 5px;
  padding: 9px 0px;
  margin-right: 25px;
}
.defult_btn{
  background: #68EBCA;
  border-radius: 5px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #FFFFFF;
  padding: 3px 7.5px;
  margin: 0 20px 0 10px;
  cursor: pointer;
}
.add_card button{
  border: 1px solid #3C7EF3;
  box-sizing: border-box;
  border-radius: 5px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #3C7EF3;
  background: white;
  padding: 12px 16px;
  cursor: pointer;
}
.card_no{
  font-size: 16px;
  line-height: 24px;
  color: rgba(19, 49, 89, 0.65);
  margin: 0 20px 0 10px;
}
.ex_date{
  font-size: 16px;
  line-height: 24px;
  color: rgba(19, 49, 89, 0.65);
  margin-right: 10px;
}
.payment_input input{
  background: #F6F7FB !important;
  border-radius: 5px !important;
  border-bottom: unset !important;
  margin-top: 10px !important;
  font-weight: normal;
  font-size: 16px !important;
  line-height: 22px !important;
  letter-spacing: 0.01em !important;
  color: rgba(19, 49, 89, 0.35) !important;
  padding: 25px 20px !important;
  box-sizing: border-box !important;
}
.payment_input input:focus{
  border-bottom: unset !important;
  box-shadow: unset !important;
}
.payment_input label{
  font-weight: normal;
  font-size: 16px;
  line-height: 30px;
  color: #133159;
}
.payment_input label span{
  color: var(--red);
}
.datePicker_input{
  position: relative;
}
.datePicker_icon{
  position: absolute;
  top: 25px;
  right: 25px;
  cursor: pointer;
}

.payment_successfull-modal{
  background: #FFFFFF !important;
  border-radius: 10px !important;
  overflow-y: unset;
  padding-bottom: 70px;
  max-width: 822px;
  width: 100%;
  top: 19% !important;
}
.payment_successfull_content h1{
  font-weight: 500;
  font-size: 48px;
  line-height: 72px;
  letter-spacing: 0.01em;
  color: #3C7EF3;
}
.payment_successfull_content p{
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.01em;
  color: rgba(19, 49, 89, 0.65);
  width: 409px;
  text-align: center;
  margin: auto;
  margin-bottom: 50px;
}
.successfull_img svg{
  margin-top: 90px;
}
.payment_successfull_content p span{
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.01em;
  color: var(--dark_blue);
}
.download_invoice{
  background: #3C7EF3;
  border-radius: 5px;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  text-transform: capitalize;
  color: #FFFFFF;
  padding: 13.5px 20.5px;
}
.close_icon{
  margin-right: 20px;
  margin-top: 24px;
}
.Worning_modal h1{
  color: var(--dark_blue);
}
.Worning_modal p{
  width: 418px;
  color: var(--red);
  margin-bottom: 95px;
}
.try_again{
  border: 1px solid #3C7EF3;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 13.5px 55.5px;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  text-transform: capitalize;
  color: #3C7EF3;
}











/* Media Query */
@media(max-width: 1500px) {
  .custom__container{
    max-width: 1330px;
  }
}
@media(max-width: 1400px) {
  .custom__container{
    max-width: 1230px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1366px){
  .custom__container{
    max-width: 1116px;
  }
  .price_package_content > .row {
    max-width: calc(100% - 128px);
    margin: 0 64px;
    column-gap: 68px;
  }
  .price_package_content .col.xl7.payment_content {
    width: 771px;
  }
  .first_price_list h5{
    font-size: 20px;
  }
  .first_price_list h1,
  .price_content h2{
    font-size: 72px;
  }
  .pric_list_content .collection-item{
    font-size: 16px;
  }
  .package_payment{
    width: 544px;
    margin-right: 30px;
  }
  .order_summery{
    max-width: 474px;
  }
  .add_card button{
    padding: 12px 8px;
  }
  .select_payment{
    padding: 10px;
  }
  .show_more_btn{
    display: none;
  }
  .payment_successfull-modal{
    right: -19% !important;
    max-height: 75%;
  }
}

/* @media(max-width: 1271px){
  .packages_page_content{
    padding: 40px 5px;
  }
} */
@media only screen and (min-width: 900px) and (max-width: 1200px){
  .card_content{
    display: block !important;
  }
  .card_content_field{
    margin-right: unset;
    margin-bottom: 20px;
  }
  .card_no{
    font-size: 12px;
    margin: 0 8px 0 8px;
  }
  .select_payment{
    padding: 10px;
  }
  .show_more_btn{
    display: none;
  }
}
@media(max-width: 1024px) and (min-width: 800px){
  .price_package_content > .row{
    max-width: calc(100% - 53px);
    margin: 0 31px;
    column-gap: 30px;
  } 
  .singup_right{
    display: none;
  }
  .from_content{
    margin-left: 180px;
    margin-bottom: 100px;
  }
  .first_price_list{
    max-width: 280px;
    width: 100%;
  }
  .show_more_btn{
    display: none;
  }
}
@media only screen and (min-width: 768px) and (max-width: 900px){
  .price_package_content > .row{
    max-width: calc(100% - 39px);
    margin: 0 20px;
    column-gap: 20px;
  } 
  .top_title h1{
    font-size: 20px;
  }
  .price_package_content .col.xl7.payment_content {
    max-width:400px;
    padding: 10px;
  }
  .card_no_input label,
  .payment_input label{
    font-size: 10px !important;
  }
  .bank_logo ul li img{
    height: 50px;
    width: 75px;
  }
  .top_title p{
    font-size: 12px;
  }
  .packages .package_list,
  .packages .price,
  .price_sub_total,
  .packages .sub_total,
  .privecy_policy p,
  .secqure_payment p{
    font-size: 10px;
  }
  .price_list p span,
  .price_list h2{
    font-size: 16px;
  }
  .price_list p{
    font-size: 20px;
  }
  .packages_page_content p span,
  .warrning p{
    font-size: 8px;
  }
  .warrning{
    padding-top: 15px;
  }
  .payment_btn a{
    padding: 10px 40px;
  }
  .singup_right{
    display: none;
  }
  .from_content{
    width: 450px;
    margin-left: 180px;
    margin-bottom: 100px;
  }
  .first_price_list h5{
    font-size: 16px;
  }
  .first_price_list h1{
    font-size: 32px;
  }
  .first_price_list .ribon{
    font-size: 8px;
    line-height: 12px;
    top: 16px;
    left: -30px;
  }
  .mnth_year_content p{
    font-size: 16px !important;
  }
  .packages_page_content p,
  .price_content h2 span,
  .pric_list_content .collection-item{
    font-size: 12px;
  }
  .collection .collection-item{
    padding: 10px 14px;
  }
  .price_content h2{
    font-size: 32px;
  }
  .price_content sup{
    font-size: 16px;
    top: 19%;
    left: -13px;
  }
  .payment_title h3{
    font-size: 16px;
  }
  .select_payment h6{
    font-size: 12px;
    line-height: 18px;
  }
  .add_card button{
    font-size: 12px;
  }
  .select_plan_btn button{
    font-size: 12px;
    padding: 7px 41px 33px;
  }
  .package_payment{
    max-width: 335px;
    margin-right: 30px;
  }
  .order_summery{
    max-width: 285px;
  }
  .card_content{
    display: block !important;
  }
  .card_content_field{
    margin-right: unset;
    margin-bottom: 20px;
    width: 244px;
  }
  .select_payment{
    padding: 3px;
  }
  .payment_input input{
    font-size: 10px !important;
  }
  .order_title h2,
  .package_payment_title h2{
    font-size: 16px;
  }
  .package_payment_title p{
    font-size: 10px;
  }
  .package_payment_title{
    padding: 10px;
  }
  .person p span{
    font-size: 10px !important;
  }
  .person h6{
    font-size: 12px;
  }
  .tax_subtotal ul li:nth-child(2), .packages ul li:nth-child(2),
  .tax_subtotal ul li:first-child, .packages ul li:first-child{
    font-size: 12px !important;
    line-height: 20px;
  }
  .order_summery_bottom_content h1{
    font-size: 12px;
    line-height: 18px;
  }
  .order_summery_bottom_content h1 span{
    font-size: 16px;
    line-height: 20px;
  }
  .order_summery_bottom_content p{
    font-size: 10px;
    line-height: 15px;
    padding: 20px 10px 20px 20px;
  }
  
  .trams_condition a{
    font-size: 12px;
  }
  .package_next_btn a,
  .package_next_btn button{
    font-size: 12px;
    line-height: 20px;
    padding: 12px 32px;
  }
  .show_more_btn{
    display: none;
  }
  .first_price_list{
    max-width: 206px;
  }
  .payment_successfull_content h1{
    font-size: 24px;
  }
  .payment_successfull_content p{
    font-size: 12px;
    line-height: 18px;
    width: 250px;
  }
  .payment_successfull-modal{
    max-width: 457px;
  }
  .ex_date{
    font-size: 10px;
  }
  .card_no{
    font-size: 10px;
    margin: 0 10px 0 10px;
  }
  .defult_btn{
    font-size: 12px;
    margin: 0 5px 0 5px;
  }
}

.user_package_module_wrapper{
  width: 100%;
  display: grid;
  grid-template-columns: repeat(
    3,1fr 
  );
  gap: 20px;
  /* flex-wrap: wrap;
  box-sizing: border-box; */
}

.ProfileModule-container-parent-class .package_page_settings_section .packages_content {
  padding: 30px;
}

.ProfileModule-container-parent-class .update_package.package_payment_content {flex: 1;}

.ProfileModule-container-parent-class .update_package .package_payment {
    width: calc(100% - 20px);
    max-width: 100%;
    margin-right: 20px;
    box-sizing: border-box;
}

ul.package_info_list{
  min-height: 145px;
}