.pp_sub_user {
}

.globalModalWrapper.pp_sub_user .modalBody::-webkit-scrollbar,
.pp_sub_user .awesome__scroll_bar::-webkit-scrollbar {
  width: 12px;
}
body.active__minimal_sidebar
  .globalModalWrapper.pp_sub_user
  .modalBody::-webkit-scrollbar,
body.active__minimal_sidebar
  .pp_sub_user
  .awesome__scroll_bar::-webkit-scrollbar {
  width: 12px;
}

.margin-left-zero-mui-btn-label .MuiButton-label{
  margin-left: 0px;
}

.important_alert {
  background: #fff;
  border-radius: 5px;
  padding: 15px 62px 15px 15px;
  margin-bottom: 30px;
}

.important_alert h5{
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #df1a1a;
  margin: 0;
}
.important_alert h5 .light_red_text{
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  color: #d53232;
}
.important_alert p{
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.01em;
  color: #df1a1a;
}
.important_alert p .light_red_text{
  color: #d53232;
}

.important_alert h6 .light_red_text{
  color: #d53232;
}

.important_alert h6{
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #df1a1a;
}
.important_alert h6 .light_red_text{
  color: #d53232;
}

.important_alert .title {
  display: flex;
  font-weight: bold;
  letter-spacing: .1em;
  text-align: center;
  text-transform: uppercase;
  font-size: 15px;
  align-items: center;
  gap: 8px;
  color: #df1a1a;
}

.important_alert .message_line_1 {
  padding: 0 !important;
  margin: 5px 0 !important;
}

.important_alert .message_line_2{
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #df1a1a;
}
