.first_price_list.profile__new_pack{
    max-width: 475px;
}

.profile_package__new_agency_package .profile_package__periods_wrapper {
    display: flex;
    justify-content: center;
    gap: 5px;
    margin-bottom: 20px;
}

.profile_package__new_agency_package .profile_package__select_period {
    display: flex;
    flex-direction: column;
    width: 140px;
    height: 85px;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 0 3px #ccc;
    align-items: flex-start;
    cursor: pointer;
    position: relative;
}

.profile_package__new_agency_package .profile_package__select_period.active {
    color: #0039aac9;
    background: #00c0ff1c;
    box-shadow: 0 0 0px 2px #0074a336;
}

.profile_package__new_agency_package .profile_package__select_period svg {
    position: absolute;
    right: 0;
    top: 0;
    color: #31b900;
}

.profile_package__new_agency_package .profile_package__select_period:not(.active) svg {
    display: none;  
}

.profile_package__new_agency_package .profile_package__select_period span.price-amount {
    font-size: 22px;
}




/* new styles */
.MuiGrid-grid-xs-4.new_user_buy_package {
    flex-grow: unset !important;
    max-width: 490px !important;
    width: 100% !important;
    flex-basis: unset !important;
}

.new_user_buy_package .first_price_list {
    max-width: unset !important;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

.new_user_buy_package .sign_up__periods_wrapper {
    display: flex;
    justify-content: center;
    grid-gap:10px !important;
    margin-bottom: 20px;
}

.new_packages_content__wrapper__alt.MuiGrid-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    grid-gap: 15px;
    justify-content: center !important;
    padding-bottom: 30px;
}
.new_user_buy_package .sign_up__select_period {
    display: flex;
    flex-direction: column;
    width: 140px;
    height: 85px;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 0 3px #ccc;
    align-items: flex-start;
    cursor: pointer;
    position: relative;
}

.new_user_buy_package .sign_up__select_period.active {
    color: #0039aac9;
    background: #00c0ff1c;
    box-shadow: 0 0 0px 2px #0074a336;
}

.new_user_buy_package .sign_up__select_period svg {
    position: absolute;
    right: 0;
    top: 0;
    color: #31b900;
}

.new_user_buy_package .sign_up__select_period:not(.active) svg {
    display: none;  
}

.new_user_buy_package .sign_up__select_period span.price-amount {
    font-size: 25px;
}
@media screen and (max-width:460px) {
    .new_user_buy_package .sign_up__periods_wrapper {
        flex-wrap: wrap;
        
        
    }
    .new_user_buy_package .sign_up__periods_wrapper .price-amount{
        font-size: 20px;
    }

}

.new_packages_content__wrapper__alt .trial_package_addtional_charge_title h2 {
    font-size: 14px;
    background: #81ecec;
    padding: 6px 10px;
    display: inline-block;
    font-weight: 500;
    margin: 0;
    width: 250px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.new_packages_content__wrapper__alt .first_price_list .pric_list_content {
    display: inline-block;
    text-align: center !important;
}

.new_packages_content__wrapper__alt .pric_list_content .collection-item {
    text-align: center;
    font-size: 16px;
    line-height: 30px;
    color: rgba(19, 49, 89, 0.65);
    padding: 2px 20px;
}


.new_packages_content__wrapper__alt .sign_up__select_period.active {
    color: #0039aac9;
    background: #00c0ff1c;
    box-shadow: 0 0 0px 2px #0074a336;
}
.new_packages_content__wrapper__alt .sign_up__select_period {
    display: flex;
    flex-direction: column;
    width: 140px;
    height: 85px;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 0 3px #ccc;
    align-items: flex-start;
    cursor: pointer;
    position: relative;
    white-space: nowrap;
}

.new_packages_content__wrapper__alt .sign_up__periods_wrapper {
    display: flex;
    justify-content: center;
    grid-gap: 10px !important;
    margin-bottom: 20px;
  }