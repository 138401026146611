.cutoff_time_chart_wrapper.flex-container {
    display: flex;
    flex-wrap: nowrap;
    background-color: #f1f1f1;
    margin-bottom: 30px;
    margin-left: 22px;
    margin-right: 20px;
}

.cutoff_time_chart_wrapper.flex-container > div {
    margin: 0.5px;
    text-align: center;
    line-height: 35px;
    font-size: 10px;
    font-weight:bold;
    position: relative;
    height: 35px;
    flex: 1;
  }

  .cutoff_time_chart_wrapper .active {
      background-color: green;
  }

  .cutoff_time_chart_wrapper .danger {
      background-color: red;
  }

  .cutoff_time_chart_wrapper span.cutoff_hour {
    position: absolute;
    bottom: -25px;
    display: block;
    left: -13px;
}

.cutoff_time_chart_wrapper .cutoff__start_time {
    background: red;
    height: 100%;
}

.cutoff_time_chart_wrapper .cutoff__end_time {
    background: green;
    height: 100%;
}

.cutoff_time_chart_wrapper .cuttoff__virtical_line {
    height: calc(100% + 10px);
    width: 1px;
    background: #000;
    float: right;
    margin-top: -10px;
    position: relative;
}

.cutoff_time_chart_wrapper .cuttoff__virtical_line.left{
    float: left;
}

.cutoff_time_chart_wrapper .cuttoff__virtical_line_text {
    position: absolute;
    top: -14px;
    right: 2px;
    width: 45px;
}
.cutoff_time_chart_wrapper .cuttoff__virtical_line_text_right {
    position: absolute;
    top: -14px;
    left: 3px;
    width: 45px;
}

.chart_block__color_indicator {
    font-size: 12px;
    margin-bottom: 12px;
}

.chart_block__color_indicator .cutoff__green_indicator {
    background: green;
    width: 10px;
    height: 10px;
    display: inline-block;
    margin-right: 5px;
}

.chart_block__color_indicator .cutoff__red_indicator {
    background: red;
    width: 10px;
    height: 10px;
    display: inline-block;
    margin-right: 5px;
}

