.custom_menu_form_content  .custom_input input {
    border: 1px solid #e2e3e9 !important;
    box-sizing: border-box !important;
    border-radius: 5px !important;
    padding: 10px 16px !important;
    height: 40px !important;
    font-size: 16px !important;
    line-height: 22px !important;
    letter-spacing: 0.01em !important;
    color: rgba(19, 49, 89, 0.5) !important;
    background: #fff !important;
}

.custom_menu_form_content  .custom_input .MuiInput-root {
    width: 100% !important;
    border: 1px solid #e2e3e9 !important;
    box-sizing: border-box !important;
    border-radius: 5px !important;
    padding: 10px 16px !important;
    height: 40px !important;
    font-size: 16px !important;
    line-height: 22px !important;
    letter-spacing: 0.01em !important;
    color: rgba(19, 49, 89, 0.5) !important;
    background: #fff !important;
}

table .color-border{
    border: 1px solid rgb(0, 0, 70);
    max-width: 120px;
    border-radius: 3px;
}
.custom_menu_form_content .findIcon{
    cursor: pointer;
    transition: transform .2s;

}
.custom_menu_form_content .findIcon:hover{
    background: rgb(224, 234, 255);
    border-radius: 4px;
    color: rgb(0, 0, 0);
    transform: scale(1.14);
}
.custom_menu_form_content .icon-container{
    display: flex;
    flex-wrap: wrap;
    color: rgb(57, 57, 57);
}

.custom-menu-table .header th{
    font-size: 15px !important;
    white-space: 'initial';
}

.loader-container{
    position: absolute !important;
    height: 100%;
    width: 100%;
    text-align: center;
    z-index: 10000 !important;
    margin: 0;
    position: absolute;
    background: rgba(0, 0, 0, 0.25);

    display: flex;
    align-items: center;
    justify-content: center;
}

.loader-container{
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
