@media (min-width: 1600px) and (max-width: 1920px) {
}
@media (min-width: 1366px) and (max-width: 1599px) {
}
@media (min-width: 1201px) and (max-width: 1366px) {
    .modal__search__preview{
        height: 160px;
    }
    .checkbox__text_2, .modal__select__text{
        font-size: 14px;
    }
    .main__bottom{
        height: 530px;
    }
}
@media (min-width: 992px) and (max-width: 1201px) {
    .main__bottom{
        height: 530px;
    }
    .checkbox__text_2, .modal__select__text{
        font-size: 14px;
    }
}
@media (min-width: 992px) and (max-width: 1024px) {
    .modal{
        width: 64% !important;
    }
}
@media (min-width: 768px) and (max-width: 992px) {
   
    /* body.active__minimal_sidebar .main__content_wrapper .main__content{
        width: calc(100% - 110px);
    } */
    .custom__tbody tr td{
        padding: 6px 10px !important;
    }
    .main__bottom{
        height: 530px;
        padding: 0 10px;
    }
    .custom__thead tr th{
        font-size: 16px;
        padding: 7px 10px;
    }
    .checkbox__text_2{
        font-size: 14px;
        margin-right: 13px;
    }
    .distribution__icon{
        margin-right: 15px;
    }
    .modal__content__bottom{
        padding: 10px !important;
    }
    .modal{
        width: 72.2% !important;
    }
    .modal__checkbox{
        padding: 10px;
    }
    .modal__select__text{
        font-size: 16px !important;
        margin-right: 5px;
    }
    @media (min-width: 768px){
        .main__bottom{
            height: 644px;
        }
    }
}
