


.strong {
    font-weight: 700;
    font-size: 15px;
    color: #3a414a;
}


.detailsbody {
    font-size: 15px;
    color: #3a414a;
    /* margin-top: 5px; */
}

.detailsHeader{
    font-size: 16px;
}
.detailsInfo{
    display: flex;
    gap:5px;
}
 .detailsOthers{
    display: flex;
    justify-content: space-between;
 }


.neutralButton {
    color: #fff !important;
    background-color: #7581d5 !important;
}

.neutralButton:focus {
    background-color: #7581d5 !important;
}

.loaderPage {
  margin: 10px;
  min-height:100px;
}

