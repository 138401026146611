.public-api-container-body .main-body{
    background-color: #fff;
    padding: 10px !important;
    border-radius: 6px;
}
.public-api-container-body .main___content{
    width: 100%;
    padding-right: 20px;
}
.public-api-container-body .api_info_content{
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    position: relative;
}
.public-api-container-body .api_desc_content p{
    padding: 0px;
    margin: 0px;
}
.public-api-container-body .api_desc_content p.api_desc_content_title{
    margin-bottom: 0px;
    font-size: 16px;
    font-weight: bolder;
    color: var(--accent-color);
}
.public-api-container-body .api_desc_content p.no_token_text{
    padding-top: 15px;
    color: #7c7c7c;
    font-style: italic;
}
.public-api-container-body .api_desc_content p.token_text{
    padding-top: 15px;
    font-size: 14px;
    background: #f5f7f9;
    padding: 10px;
    margin-top: 20px;
    border-radius: 2px;
    position: relative;
    padding-right: 20px;
}
.public-api-container-body .api_token_button{
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
}
.public-api-container-body .api_token_short_description{
    padding-bottom: 10px;
    border-top: 1px solid #ddd;
    padding-top: 10px;
    align-items: center;
    justify-content: space-between;
    display: flex;
}
.public-api-container-body .api_token_short_description .api_token_short_description_icon{
    width: 100px;
    align-items: center;
    justify-content: center;
    display: flex;
}
.public-api-container-body .api_token_short_description .api_token_short_description_text{
    font-size: 15px;
    flex: 1;
    text-align: justify;
}
.public-api-container-body .first_loading{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100px;
}
.public-api-container-body .loading_container{
    position: absolute;
    background-color: #fff;
    z-index: 9;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0.8;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* for public api list table */
.table__empty__container{
    height: 100px;
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
}

.public__api__list__table__container{
    padding: 10px;
    background-color: #f5f7f9;
    margin-top: 80px;
}
.public-base-url-info-content p{
    font-size: 16px;
    font-weight: bold;
}

.token_text .copy_to_clipboard{
    background: #fff;
    padding: 5px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 2px;
    position: absolute;
    top: 2px;
    right: 2px;
    display: none;
}

.token_text:hover .copy_to_clipboard{
    display: flex;
}