.ProfileModule-container-parent-class .company__info__container{
    padding: 20px;
}
.ProfileModule-container-parent-class .company__info__container .company__info__row  + .company__info__row{
    margin-top: 10px;
}
.ProfileModule-container-parent-class .company__info__container .company__info__row__title{
    margin: 0 0 4px 0;
    font-size: 14px;
}
.ProfileModule-container-parent-class .company__info__container .company__info__row__right .personal_info_text_field {
    background: #F5F7F9 !important;
    font-size: 12px;
    margin: 0;
    color: rgba(19, 49, 89, 0.65);
}
.ProfileModule-container-parent-class .company__info__container .company__info__row__right .personal_info_text_field::placeholder{
    color: rgba(19, 49, 89, 0.65);  
}
.ProfileModule-container-parent-class .company__info__container .company__info__row__right__img img{
    max-width: 200px;
}
.ProfileModule-container-parent-class .company__info__container .company__info__row__right .img__file__condition {
    color: rgba(19, 49, 89, 0.65);
    font-size: 12px;
    margin: 0 0 8px 0;
}
.ProfileModule-container-parent-class .company__info__container .company__info__row__right .MuiDropzoneArea-root{
    max-width: 300px;
    padding: 16px 58px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: auto;
    border: 2px dashed #ddd;
    background: #F5F7F9;
}
.ProfileModule-container-parent-class .company__info__container .company__info__row__right .MuiTypography-root{
    font-size: 12px;
    margin: 0 0 8px 0;
}
.ProfileModule-container-parent-class .company__info__container .company__info__row__right .MuiDropzoneArea-icon {
    color: rgba(0, 0, 0, 0.87);
    width: 28px;
    height: auto;
}
.ProfileModule-container-parent-class .company__info__container .company__info__row__right.minimized__img__for__company img{
    width: 100%;
    max-width: 20%;
}