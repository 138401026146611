.ProfileModule-container-parent-class .company__favicon__container{
    padding: 20px;
}
.ProfileModule-container-parent-class .company__favicon__container .company__favicon__row  + .company__favicon__row{
    margin-top: 10px;
}
.ProfileModule-container-parent-class .company__favicon__container .company__favicon__row__title{
    margin: 0 0 4px 0;
    font-size: 16px;
}
.ProfileModule-container-parent-class .company__favicon__container .company__favicon__row__right .personal_info_text_field {
    background: #F5F7F9 !important;
    font-size: 14px;
    margin: 0;
}
.ProfileModule-container-parent-class .company__favicon__container .company__favicon__row__right img{
    max-width: 100px;
}
.ProfileModule-container-parent-class .company__favicon__container .company__favicon__row__right .img__file__condition {
    color: rgba(19, 49, 89, 0.65);
    font-size: 12px;
    margin: 0 0 8px 0;
}
.ProfileModule-container-parent-class .company__favicon__container .company__favicon__row__right .MuiDropzoneArea-root{
    max-width: 300px;
    padding: 16px 58px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: auto;
    border: 2px dashed #ddd;
    background: #F5F7F9;
}
.ProfileModule-container-parent-class .company__favicon__container .company__favicon__row__right .MuiTypography-root{
    font-size: 12px;
    margin: 0 0 8px 0;
}
.ProfileModule-container-parent-class .company__favicon__container .company__favicon__row__right .MuiDropzoneArea-icon {
    color: rgba(0, 0, 0, 0.87);
    width: 28px;
    height: auto;
}