.ProfileModule-container-parent-class .virtual_number_wrapper .container {
    padding: 16px;
    background-color: #f1f1f1;
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12);
    display: flex;
    align-items: center;
    height: 120px;
  }
  
  .ProfileModule-container-parent-class .virtual_number_wrapper .phn-container {
    margin-top: 20px;
    font-weight: bold;
    font-size: 18px;
  }
  
  .ProfileModule-container-parent-class .virtual_number_wrapper .custom-container {
    padding: 20px;
    background-color: #ffffff;
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12);
  }
  
  .ProfileModule-container-parent-class .virtual_number_wrapper .flex-end {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  
  .ProfileModule-container-parent-class .virtual_number_wrapper .announcement {
    margin-right: 10px;
    padding: 10px;
    background-color: #7b24ff;
    border-radius: 4px;
    display: flex;
    align-items: center;
  }
  
  .ProfileModule-container-parent-class .virtual_number_wrapper .switch-box {
    color: #ffffff;
    padding-right: 10px;
  }

.ProfileModule-container-parent-class .virtual_number_wrapper .buy-additional {
  padding: 8px;
  height: 44px;
  background-color: #7b24ff;
  border-radius: 4px;
  display: flex;
  align-items: center;
  text-decoration: none;
  gap: 5px;
  color: #ffffff;
  border: none;
  cursor: pointer;
}
.ProfileModule-container-parent-class .virtual_number_wrapper .buy-additional:hover {
  background-color: #6a1bff;
}

.ProfileModule-container-parent-class .virtual_number_wrapper .buy-additional:focus {
  background-color: #7b24ff;
}
  
  .ProfileModule-container-parent-class .virtual_number_wrapper .stir-box {
    margin-top: 10px;
    padding-left: 20px;
    padding-right: 20px;
    background-color: #ffffff;
    border-radius: 4px;
  }
  
  .ProfileModule-container-parent-class .virtual_number_wrapper .flex-space-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .ProfileModule-container-parent-class .virtual_number_wrapper .absolute-center-right {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
  
  .ProfileModule-container-parent-class .virtual_number_wrapper .custom-input {
    padding: 8px 16px;
    border-radius: 12px;
    border: 1px solid;
    font-size: 14px;
    width: 240px;
  }
  
  