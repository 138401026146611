

.invoice-list .custom_input input {
    border: 1px solid #e2e3e9 !important;
    box-sizing: border-box !important;
    border-radius: 5px !important;
    padding: 10px 16px !important;
    height: 40px !important;
    font-size: 16px !important;
    line-height: 22px !important;
    letter-spacing: 0.01em !important;
    color: rgba(19, 49, 89, 0.5) !important;
    background: #fff !important;
}

.invoice-list .select_gateway {
    border: 1px solid #e2e3e9 !important;
    box-sizing: border-box !important;
    border-radius: 5px !important;
    padding: 10px 16px !important;
    height: 40px !important;
    font-size: 16px !important;
    line-height: 22px !important;
    letter-spacing: 0.01em !important;
    color: rgba(19, 49, 89, 0.5) !important;
    background: #fff !important;
}

.invoice-list .select_gateway .MuiSelect-select:focus {
    border-radius: 0;
    background-color: transparent !important;
}

.invoice_form_wrapper {
    width: 100%;
}

.loader-container{
    position: absolute !important;
    height: 100%;
    width: 100%;
    text-align: center;
    z-index: 10000 !important;
    margin: 0;
    position: absolute;
    background: rgba(0, 0, 0, 0.25);

    display: flex;
    align-items: center;
    justify-content: center;
}

.loader-container{
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
