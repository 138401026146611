.pageWrapper {
    padding: 10px 20px 10px 10px;
    background: #fff;
    border-radius: 10px;
}

.pageHeader {
    padding: 6px;
}

.pageTitle {
    font-size: 18px;
    font-weight: 600;
}

.pageSubtitle {
    margin-top: 5px !important;
    font-size: 12px;
}

.backButton {
    position: absolute;
    right: 0;
    top: 20px;
}

.pageHints {
    line-height: 35px;
    margin-left: 10px;
}

.pageHint {
    font-size: 16px;
    color: #3a414a;
    display: flex;
    align-items: center;
}

.pageHint svg {
    margin-right: 10px;
}

.pageBody {
    width: 100%;
}

.serviceContainer {
    width: 100%;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-column-gap: 30px;
    grid-row-gap: 30px;
    padding-right: 30px;
    padding-bottom: 30px;
}

.serviceContainerItem {
    background-color: #fff;
    padding: 20px;
    border-radius: 4px;
    font-size: 40px;
    font-family: sans-serif;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 1px solid #875dd9;
}

.pageHintIcon {
    width: 20px !important;
    height: 20px !important;
    color: #d97070 !important;
    background-color: #e7d8c3 !important;
    margin-right: 5px !important;
}

.serviceContainerItemHeader p {
    padding: 0;
    margin: 0;
    font-size: 11px;
    font-weight: 600;
    color: #3a414a;
}

.serviceContainerItemHeader h1 {
    font-size: 18px;
    font-weight: 600;
    color: #3a414a;
    margin-bottom: 10px;
}

.serviceContainerBody {
    padding: 10px 0;
}

.serviceContainerFooter {
    display: flex;
    justify-content: flex-end;
}

.serviceContainerItem .strong {
    font-weight: 700;
    font-size: 15px;
    color: #3a414a;
}

.serviceContainerItem .serviceStatus {
    font-size: 15px;
    color: #3a414a;
    margin-top: 8px;
}

.serviceContainerItem .serviceStatusV2 {
    font-size: 15px;
    color: #3a414a;
    margin-top: 8px;
    display: flex;
    justify-content: space-between;
}
.serviceContainerItem .viewDetails{
    background-color: forestgreen;
    color: #FFFFFD;
    cursor: pointer;
    border: blue 1px solid;
    padding: 6px 6px;
    border-radius: 5px;
}

.serviceContainerItem .type {
    font-size: 16px;
    font-weight: 500;
    color: #3a414a;
}

.statusChipApproved {
    color: #ffffff;
    font-size: 12px;
    background-color: #66cd81;
    padding: 5px 10px;
    font-weight: 700;
    border-radius: 4px;
    width: fit-content;
}

.statusChipFailed {
    color: #ffffff;
    font-size: 12px;
    background-color: #e12a2a;
    padding: 5px 10px;
    font-weight: 700;
    border-radius: 4px;
    width: fit-content;
}

.statusChipRequested {
    color: #ffffff;
    font-size: 12px;
    background-color: #e1de83;
    padding: 5px 10px;
    font-weight: 700;
    border-radius: 4px;
    width: fit-content;
}

.statusChipInactive {
    color: #ffffff;
    font-size: 12px;
    background-color: #f37e7e;
    padding: 5px 10px;
    font-weight: 700;
    border-radius: 4px;
    width: fit-content;
}

.statusChipDefault {
    color: #5a2b54;
    font-size: 12px;
    background-color: #f8fbdf;
    padding: 5px 10px;
    font-weight: 700;
    border-radius: 4px;
    width: fit-content;
    border: 1px solid #5a2b54;
}

.neutralButton {
    color: #fff !important;
    background-color: #7581d5 !important;
}

.neutralButton:focus {
    background-color: #7581d5 !important;
}

.loaderPage {
    margin-top: 100px;
    min-height: calc(100vh - 120px);
}

.boxHeaderMessage {
    font-size: 14px;
    color: #b90c0c;
    font-weight: 800;

    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}