
.ProfileModule-container-parent-class .main__content {
    width: 100%;
    position: relative;
    /* background: #E5E5E5; */
    /* overflow-y: hidden; */
}
.ProfileModule-container-parent-class .main__content .container-fluid {
    width: 100%;
    margin-top: 10px;
}
.notifications_bx{
    background: #fff;
    border-radius: 10px;
    padding: 10px 20px;
    height: 90px;
}
.notifications_bx h5{
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.01em;
    color: var(--dark_blue);
}
.notification_tab {
    position: relative;
    width: 100%;
    padding:10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.notification_tab .mark_list {
    /* width: 20%; */
    text-align: right;
    flex-shrink: 0;
}
.mark_list p{
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.01em;
    cursor: pointer;
    margin-right: 18px;
    background: #3a7bdb;
    color: #fff;
    padding: 8px 13px;
    border-radius: 5px;
}

.mark_list p:hover{
    background: #577298;
}

.notification_tab .tabs_list {
    position: relative;
    /* width: 80%; */
    background: unset;
}
.notification_tab .tabs_list li {
    margin-right: 30px;
    cursor: pointer;
    height: unset;
    margin: 0 5px 0 0;
}
.tabs_list .tab a:hover,
.tabs_list .tab a.active{
    color: #fff !important;
    background: #3e82e7;
}
.tabs_list .tab a:focus, .tabs_list .tab a:focus.active{
    background-color: unset !important;
}
.notification_tab .tabs_list a {
    color: rgba(19, 49, 89, 0.65);
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 0.01em;
    text-transform: capitalize;
    margin-top: 14px;
    border-radius: 20px;
    border: #133159 1px solid;
    margin-right: 30px;
    cursor: pointer;
    padding: 16px 23px;
    height: unset;
    display: block;
    line-height: 0;
}
.tabs_list_items .load a{
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 0.01em;
    color: #546376;
    text-transform: capitalize;
    padding: 20px;
}
.tabs_list_notification {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    background: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    box-shadow: 0px 5px 5px rgba(19, 49, 89, 0.05);
    /* height: 90px; */
    align-items: center;
    margin-right: 15px;
}
.tabs_list_notification.checked_notifiation {
    background: #dbd8d8;
    overflow: hidden;
    animation: slide-right .5s forwards;
}

@keyframes slide-right {
    0% {
      transform:translateX(0);
      opacity: 1;
    }
    100% {
        transform:translateX(100%);
        opacity: 0;
        display: none;
    }
}

.tabs_list .indicator{
    display: none;
}
.items_box{
    position: relative;
    display: flex;
    align-items: center;
}
.items_box .checkbox input {
    opacity: 1;
    pointer-events: all;
    position: absolute;
    top: 25px;
    left: 0;
    height: 20px;
    width: 20px;
}
.userBox {
    width:50px;
    height: 50px;
    background: #F6F7FB;
    position: relative;
    border-radius: 50%;
    text-align: center;
    line-height: 25px;
    margin: 10px 20px;
    font-size: 12px;
    font-weight: 600;
    margin-left: 40px;
    flex-shrink: 0;
}
.info_msg p{
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.01em;
    color: #133159;
    margin: 0;
}
.seen_info p{
    color: #546376;
}
.tabs_list_items .items_box .userBox p {
    color:#133159
}

.tabs_list_items .items_box .userBox p svg {
    position: relative;
    background: no-repeat;
    border-radius: 0;
    width: 60%;
    height: 60%;
}

.tabs_list_items .items_box .userBox svg {
    position: absolute;
    bottom:0;
    right:0;
    background: #68EBCA;
    padding:5px;
    border-radius: 50%;
}
ul {
    margin-top: 24px;
}
.userName h6{
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.01em;
    color: #133159;
}
.notification_info .userName .new_msg {
    background: #FF264A;
    padding:1px 10px;
    color:#fff;
    border-radius: 50px;
    font-size: 12px;
    font-weight: 400;
    margin: 0 10px;
}
.notification_info .userName .date {
    color:#3C7EF3
}
.notification_info .userName p {
    margin: 0;
    font-weight: 500;
    font-size: 16px;
}
.tabs_list_items .tabs_list_notification .mark_icons {
    width: 30%;
    text-align: right;
}
.tabs_list_items .tabs_list_notification .mark_icons {
    position: relative;
}
.tabs_list_items .tabs_list_notification .mark_icons ul li {
    display: inline;
    margin-right: 10px;
}
.tabs_list_items .tabs_list_notification .mark_icons ul li a {
    width: 30px;
    height: 30px;
    background: transparent;
    position: relative;
    border-radius: 50%;
    display: inline-block;
    border:1px solid #546376;
}
.tabs_list_items .tabs_list_notification .mark_icons ul li a svg {
    position: absolute;
    top:8px;
    left:8px
}
.tabs_list_items .tabs_list_notification .mark_icons ul li a.notification__icon svg {
    position: absolute;
    top: 5px;
    left: 6px;
    width: 17px;
    height: 17px;
}
.tabs_list_items .tabs_list_notification .mark_icons ul li a .user {
    position: absolute;
    top:8px;
    left:10px
}
.tabs_list_items .tabs_list_notification .mark_icons ul li a .user_t {
    position: absolute;
    top:15px;
    left:8px
}
.tabs_list_items .tabs_list_notification .mark_icons ul li a .list_i {
    position: absolute;
    top:8px;
    left:8px
}
 .tabs_list_items .tabs_list_notification .mark_icons ul li:nth-child(3) a .list_l {
    position: absolute;
    top:8px;
    left:12px
}
.tabs_list_items .tabs_list_notification .mark_icons ul li:nth-child(4) a svg:nth-of-type(2) {
    position: absolute;
    top:8px;
    left:12px
}
.tabs_list_items .tabs_list_notification .mark_icons ul li:nth-child(5) a svg:nth-of-type(2) {
    position: absolute;
    top:12px;
    left:8px;
}

/*=================== Scrollbar css=============== */
.tab-notifications-items.awesome__scroll_bar{
    padding-bottom: unset;
    height: 600px;
}
.tab-notifications-items.awesome__scroll_bar::-webkit-scrollbar-thumb{
    background-color: var(--dark_blue);
}
.tab-notifications-items.awesome__scroll_bar::-webkit-scrollbar-track{
    background-color: rgba(60, 126, 243, 0.1);
}

/* =====================Responsive css=========================== */
@media only screen and (min-width: 1366px) and (max-width: 1920px){
    .tab-notifications-items.awesome__scroll_bar{
        height: 590px;
    }
}
@media only screen and (min-width: 900px) and (max-width: 1366px){
    .tab-notifications-items.awesome__scroll_bar{
        height: 420px;
    }
}


@media only screen and (min-width: 768px) and (max-width: 900px){
    /* main#main__content{
        margin-left: 105px;
    } */
    .tabs_list_notification{
        padding: 0px 10px;
    }
    .notification_tab .tabs_list{
        width: 30%;
        margin: unset;
    }
    .notification_tab .mark_list{
        width: unset;
    }
    .userBox{
        margin: 10px 10px;
        margin-left: 26px;
    }
    .items_box .checkbox input{
        top: 25px;
    }
    .userName h6{
        font-size: 14px;
    }
    .notification_info .userName .date{
        font-size: 14px;
    }
    .info_msg p{
        font-size: 12px;
    }
    .tabs_list_items .tabs_list_notification .mark_icons ul li{
        margin-right: 2px;
    }
    .tabs_list_notification{
        margin-right: 2px;
    }
    .tab-notifications-items.awesome__scroll_bar{
        height: 650px;
    }
}