.mip_company_logo {
    /* background: #F7D9EF; */
    border-radius: 10px;
    overflow: hidden;
    /* max-width: 292px; */
    width: 100%;
}

.mip_company_logo_info {
    padding: 10px;
    border: 1px solid #E5E8EF;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.mip_company_logo img {
    /* max-width: 292px; */
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.mip_company_logo_info p {
    font-weight: 300;
    font-size: 14px;
    line-height: 24px;
    color: rgba(19, 49, 89, 0.65);
    max-width: 166px;
    margin: 0;
}

.mip_company_logo_info button {
    background: #fff;
}

.mip_company_data .mip_cardPayment_btn_save {
    background: #3C7EF3;
    color: #fff;
    margin-left: 10px;
}

.mip_company_data .mip_cardPayment_btn_save:hover {
    opacity: 1;
    background: #6392e9;
}

.mip_company_data {
    max-width: 185px;
    margin-left: 24px;
}

.mip_company_data > div {
    margin-top: auto;
}

.mip_company_info {
    padding: 10px;
    margin: 0 0 15px;
    display: flex;
    border: 1px solid #E5E8EF;
    border-radius: 10px;
    height: 218px;
}

.mip_company_info h3 {
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
    color: #133159;
}

.mip_company_info p {
    font-weight: 300;
    font-size: 16px;
    line-height: 30px;
    color: rgba(19, 49, 89, 0.65);
}

.mip_company_logo_info p {
    font-size: 14px;
    line-height: 24px;
}

.mip_company_logo_info button {
    background: #fff !important;
}


.mip_customPaper {
    background: #FFFFFF;
    border: 1px solid #E5E8EF;
    border-radius: 10px;
}

.mip_cardHeaderBox {
    padding: 20px;
}

.mip_cardHeaderBox + .mip_cardHeaderBox {
    border-top: 1px solid #E5E8EF;
}

.mip_customPaper h4 {
    font-weight: 500;
    font-size: 20px;
    line-height: 36px;
    color: rgba(19, 49, 89, 0.65);
    margin: 0;
}

.mip_cardHeaderBox p {
    font-weight: 300;
    font-size: 16px;
    line-height: 30px;
    color: rgba(19, 49, 89, 0.65);
    margin: 10px 0 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.mip_customPaper p > span {
    font-weight: 500;
}

.mip_customPaper.alt p > span {
    text-align: right;
}

.mip_customPaper p > span > span {
    display: block;
}

.mip_cardPayment_page button {
    padding: 12px 15px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #133159;
    letter-spacing: 0.01em;
    text-transform: capitalize;
    background: #E5E8EF;
    border-radius: 10px;
    box-shadow: unset;
}

.mip_cardPayment_page button:hover {
    opacity: 0.85;
}

.mip_cardPayment_page button:hover {
    box-shadow: unset;
}


.mip_cardPayment_page button.mip_cardPayment_btn_buy {
    background: #08BE7C;
    border-radius: 5px;
    color: #fff;
    margin: 0 auto;
}

.mip_customPaper .mip_payment_order_summery {
    text-align: center;
}

.mip_customPaper .mip_payment_order_summery p {
    margin: 20px;
}

.mip_payment_type {
    padding: 0px 20px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.pip_billing_cycle .mip_payment_type input {
    position: static;
    opacity: 1;
    pointer-events: initial;
    margin: 10px 10px 0 0;
}

.mip_payment_type label {
    font-size: 16px;
    line-height: 30px;
    color: #133159;
    font-weight: 300;
}

.mip_payment_type label + span {
    font-weight: 600;
    font-size: 20px;
    line-height: 36px;
    color: #133159;
}

.pip_billing_cycle {
    background: #F6F7FB;
}

.pip_billing_cycle h4 {
    margin: 20px 20px 0;
}

.pip_billing_cycle img {
    margin-right: 25px;
}

.mt-20 {
    margin-top: 20px;
}

.mtl {
    margin-top: 20px !important;
    margin-left: 20px !important;
    margin-bottom: 10px !important;
}

.mip_form .formItem {
    position: relative;
}

.formItem label {
    font-size: 16px;
    line-height: 30px;
    color: #133159;
}

.mip_form_wrapper + hr {
    background: #E5E8EF;
    height: 0.5px;
    border: 0;
}

.mip_form_wrapper {
    padding: 0 20px 20px;
}

.mip_form .formItem input {
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.01em;
    color: #424770;
    padding: 14px 20px;
    height: unset;
    background: #F6F7FB;
    border-radius: 5px;
    border: 0;
    margin: 0;
}

.mip_form .formItem input:focus {
    border: inherit !important;
    box-shadow: inherit !important;
}

.mip_form .formItem img.inputIcon {
    position: absolute;
    right: 25px;
    bottom: 25px;
    z-index: 9;
}

.mip_form .formItem label {
    display: flex;
    align-items: center;
}

.mip_form .formItem img.inputIcon.alt {
    position: static;
    margin-left: 8px;
}

.mip_form_wrapper.alt {
    padding-bottom: 10px;
}

.mx-20 {
    margin: 0 10px !important;
    width: calc(100% - 20px) !important;
}

.mip_form_wrapper .mip_select_wrap {
    width: 100%;
    background: #f6f7fb;
    padding: 10px;
}


.mip_form_wrapper .mip_select_wrap .MuiSelect-select:focus {
    background-color: inherit !important;
}

@media (max-width: 1440px) {
    .mip_form_wrapper.alt2 .formItem {
        max-width: 50%;
        flex-basis: 50%;
    }
}

@media (max-width: 1200px) {
    .mip_two_columns .MuiGrid-item {
        max-width: 100%;
        flex-basis: 100%;
    }
}

.mip_form .stripe_input {
    display: block;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.01em;
    color: #424770;
    padding: 14px 0 14px 14px;
    height: unset;
    background: #F6F7FB;
    border-radius: 5px;
    border: 0;
    margin: 0;
}

.mip_form .stripe_input_label {
    font-size: 16px;
    line-height: 30px;
    color: #133159;
}

.mip_form_checkbox_default {
    padding: 5px 0;
}