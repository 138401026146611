/***********************
Group Lists CSS
************************/

.top_content {
  border-bottom: 1px solid #E7EFF0;
}
.sub_title h2 {
  font-size: 24px;
  line-height: 30px;
  font-weight: 700;
  text-transform: capitalize;
  letter-spacing: 0.03em;
  color: var(--dark_blue);
  margin: 0;
}
.cross_button {
  background: var(--red);
  width: 26px;
  height: 26px;
  border-radius: 50%;
  cursor: pointer;
}
.active__minimal_sidebar .awesome__scroll_bar::-webkit-scrollbar{
  width: 12px !important;
}
.main__top{
  border-bottom: 1px solid rgba(60, 126, 243, 0.1);
  margin-bottom: 19px;
  padding: 0 20px;
}
.main__wrapper {
  background-color: var(--white);
  /* margin-top: 20px; */
  margin-bottom: 20px;
  border-radius: 10px;
  position: relative;
}
.main__top__text h1 {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: var(--dark_blue);
  letter-spacing: 0.01em;
}
.main__top__text p {
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  margin-bottom: 0;
  color: #13315959;
}
.main__top__text p span {
  color: var(--blue-1);
}
.add__group__btn,
.modal__close__btn {
  font-family: var(--poppins);
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 30px;
  height: 30px;
  font-weight: 500;
  letter-spacing: 0.01em;
  color: var(--white);
  display: flex;
  align-items: center;
  padding: 25px 14px;
  border-radius: 5px;
  background-color: var(--blue-1);
  border: none;
  cursor: pointer;
}

/***********************
Group Lists Table CSS
************************/
.main__bottom {
  color: var(--dark_blue);
  margin-right: -18px;
  height: 690px;
  padding: 0 20px;
}
.main__bottom.awesome__scroll_bar{
  padding-bottom: unset;
}
.main__bottom.awesome__scroll_bar::-webkit-scrollbar-thumb{
  background-color: var(--dark_blue);
}
.main__bottom.awesome__scroll_bar::-webkit-scrollbar-track{
  background-color: rgba(60, 126, 243, 0.1);
}
.custom__thead {
  background-color: var(--gray_dark);
}
.custom__thead tr {
  border-bottom: none;
}
.custom__thead tr th {
  border-left: 2px solid var(--white);
  padding: 10px 25px;
  font-family: var(--poppins);
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: var(--dark_blue);
}
.custom__tbody {
  border: none;
}
.custom__tbody tr td {
  padding-left: 25px;
  font-family: var(--poppins);
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  color: var(--dark_blue);
  padding: 6px 20px !important;
}

.custom__tbody tr {
  border-bottom: 3px solid rgba(60, 126, 243, 0.05) !important;
}
.custom__tbody tr:first-child {
  border-top: 0px;
}
.distribution__icon {
  background-color: var(--blue-1);
  color: var(--white);
  border-radius: 100%;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 5px 0px;
}
.distribution__icon + .distribution__icon {
  margin-left: 2px;
}
.distribution__btn {
  padding-left: 0px;
}
.distribution__btn img {
  width: 36px;
  height: 36px;
}
.group__edit__btn,
.group__delete__btn,
.distribution__btn {
  border: none;
  background-color: transparent;
  cursor: pointer;
}
.group__edit__btn:focus-within,
.group__delete__btn:focus-within,
.distribution__btn:focus-within {
  border: none;
  background-color: transparent;
}

/***********************
Group Lists Modal CSS
************************/
#addToGroup {
  background-color: var(--white);
  top: 80px !important;
  left: unset;
  max-width: 1170px;
  border-radius: 10px 0 0 10px;
  max-height: 88%;
}
.add__group__btn:focus-within {
  background-color: var(--blue-1);
}
.modal-content {
  padding: unset !important;
}
.modal__content__top h1 {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: var(--dark_blue);
  border-bottom: 1px solid #efebff;
  padding-bottom: 17px;
}
.modal__input__box label {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  display: block;
  color: var(--dark_blue);
  margin-bottom: 8px;
}
.modal__input {
  background-color: #fff !important;
  border-bottom: none !important;
  border-radius: 5px !important;
  box-shadow: none !important;
  padding: 7px 17px !important;
  margin-bottom: 20px !important;
  box-sizing: border-box !important;
  border: 1px solid #E5E8EF !important;
  font-size: 16px !important;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
  color: #BABABA;    
  height: 40px !important;
  font-family: var(--poppins);
}
.modal__input__search {
  background-color: var(--white) !important;
  border-bottom: none !important;
  border-radius: 5px !important;
  box-shadow: none !important;
  padding: 11px 17px !important;
  box-sizing: border-box !important;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.01em;
  color: #546376;
  font-family: var(--poppins);
}
.modal__input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--gray);
  opacity: 1; /* Firefox */
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
}

.modal__input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: var(--gray);
}

.modal__input ::-ms-input-placeholder {
  /* Microsoft Edge */
  color: var(--gray);
}
.modal__input__search::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--gray);
  opacity: 1; /* Firefox */
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
}

.modal__input__search:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: var(--gray);
}

.modal__input__search ::-ms-input-placeholder {
  /* Microsoft Edge */
  color: var(--gray);
}
.modal__search__box {
  background-color: #F6F7FB;
  padding: 20px;
  border-radius: 10px;
  border: 1px solid #E5E8EF;
  position: relative;
}
.modal__search__box  span.serach_icon{
  position: absolute;
  right: 33px;
  top: 31px;
  cursor: pointer;
}
.modal__search__preview {
  height: 227px;
  padding-right: 5px;
}
.modal__search__preview.awesome__scroll_bar{
  padding-bottom: unset;
}
.modal__search__preview.awesome__scroll_bar::-webkit-scrollbar-track{
  background-color: rgba(60, 126, 243, 0.1);
  scrollbar-color: rgba(60, 126, 243, 0.1);
}
.modal__search__preview.awesome__scroll_bar::-webkit-scrollbar-thumb{
  background-color: var(--dark_blue);
}
/* [type="checkbox"].filled-in:checked+span:not(.lever):after{
  background-color: var(--light_blue) !important;
} */
.modal__search__preview .checkbox__text_1::after {
  border: 1px solid var(--light_blue) !important;
}
.modal__search__preview label {
  background-color: var(--white);
  padding: 10px 10px;
  border-radius: 5px;
  font-size: 16px;
  margin-top: 10px;
}
.modal__search__preview label:hover{
  background-color: rgba(60, 126, 243, 0.1);
}
/* .modal__search__input:focus + .modal__search__preview {
  display: block !important;
} */
.modal__checkbox {
  background-color: #fff;
  padding: 20px;
  margin-right: 10px;
  height: 93px;
  margin-top: 20px;
  border-radius: 5px;
}
.modal__checkbox label {
  display: flex;
}
.checkbox__text__holder {
  display: flex;
  flex-direction: column;
}

.checkbox__text__holder::after {
  border: 1px solid var(--light_blue) !important;
}
.checkbox__text__holder .checkbox__text_1{
  padding-left: 0px !important;
}
.checkbox__text_1 {
  font-weight: 500;
  font-size: 16px !important;
  color: #546376;
  line-height: 22px !important;
  letter-spacing: 0.01em;
  padding-left: 40px !important;
}
.checkbox__text_2,
.modal__select__text {
  font-style: normal;
  font-size: 16px;
  font-weight: 400;
  color: #99a6b9;
  line-height: 22px;
  margin-right: 20px;
  letter-spacing: 0.01em;
}
.checkbox__text__holder span {
  display: block;
}
.selector__box {
  margin-left: 20px;
  background-color: var(--gray);
  border-radius: 10px;
}
.selector__box .select-wrapper {
  padding: 5px 10px !important;
  border-bottom: none !important;
}
.select-wrapper .caret {
  fill: var(--white) !important;
  font-size: 16px;
}
.select-wrapper .dropdown-content {
  background-color: var(--gray);
}
.select-wrapper .dropdown-content li span {
  color: var(--white);
  font-size: 16px;
}
.selector__box .select-wrapper input {
  background-color: transparent !important;
  border-bottom: none !important;
  color: var(--white);
  font-size: 16px;
  height: 2rem;
  max-width: 140px;
}
.modal-footer {
  height: 70px !important;
  padding: 0 20px !important;
  margin-bottom: 20px;
  background: #fff !important;
}
.modal__buttons {
  padding-top: 20px;
  border-top: 1px solid #efebff;
}
.modal__close__btn {
  background-color: var(--red);
  margin-left: 35px;
}
.modal__close__btn:focus-within {
  background-color: var(--red);
}


/*.add_grp_select input{*/
/*  border-bottom: unset !important;*/
/*  background: #546376 !important;*/
/*  color: white;*/
/*  border-radius: 5px !important;*/
/*  height: 30px !important;*/
/*  width: 120px !important;*/
/*  padding: 0px 10px !important;*/
/*  font-weight: 500;*/
/*  font-size: 16px !important;*/
/*  line-height: 24px !important;*/
/*  letter-spacing: 0.01em;*/
/*  font-family: var(--poppins);*/
/*}*/
.lead_group_select_fields .add_grp_select input,
.lead_group_select_fields .add_grp_select .MuiInput-underline{
  background-color: var(--gray_light);
  border: 1px solid #E5E8EF;
  color: #143562;
  border-radius: 5px ;
  height: 40px ;
  width: 100%;
  padding: 0px 10px ;
  font-weight: 500;
  font-size: 16px ;
  line-height: 24px ;
  letter-spacing: 0.01em;
  font-family: var(--poppins);
  box-sizing: unset;
}
.lead_group_select_fields .add_grp_select .MuiInput-underline .MuiSelect-select:focus{
  background: transparent;
}
/*.add_grp_select .caret{*/
/*  display: none;*/
/*}*/
/*.add_grp_select .select_icon{*/
/*  position: absolute;*/
/*  right: 12px;*/
/*  top: 3px;*/
/*  z-index: 1;*/
/*}*/
.group__delete__btn span,
.group__edit__btn span{
  height: 30px;
  width: 30px;
  border-radius: 50%;
  border: 1px solid rgba(19, 49, 89, 0.35);
  display: inline-grid;
  line-height: 27px;
  place-items: center;

}
.group__delete__btn span{
  border: 1px solid rgba(255, 88, 88, 0.6);
}

.massaging_service{
  max-width: 1170px;
  background: var(--gray_light);
  border-radius: 10px 0px 0px 10px;
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.1);
  left: unset;
  top: 80px !important;
  right: 0 !important;
  position: fixed;
  max-height: 80%;
  overflow-y: auto;
}
.massaging_service.modal{
  max-height: 88% !important;
}
.massaging_service_title{
  border-bottom: 2px solid var(--white);
}
.add_grp_select .MuiInput-underline:after,
.add_grp_select .MuiInput-underline:before{
  display: none;
}
.add_grp_select input{

}

#lead_group .rdt_Pagination select{
  display: block;
  margin-right: 10px;
  /*background: var(--gray_light);*/
}
#lead_group .rdt_Pagination select:focus{
  outline: none;
  border: none;
}
#lead_group .rdt_Pagination svg{
  top: 5px;
}
.lead-group-main-table #lead_group .rdt_Pagination {
  box-shadow: unset !important;
  border-bottom: 0;
}

.lead-group-main-table .rdt_TableCell {
  font-size: 14px;
}

.lead-group-main-table .rdt_TableCell{
  padding: 0px 30px;
  line-height: 24px;
  color: rgba(19, 49, 89, 0.65);
  word-break: break-word;
}
.lead-group-main-table .rdt_TableCell {
  font-weight: 600;
}

.lead-group-main-table .rdt_TableHead{
  background: #E5E8EF;
}

/**/
.lead-group-main-table .rdt_TableHeadRow .rdt_TableCol {
  padding-left: 30px;
  padding-right: 30px;
  background: #F6F7FB;
}

.lead-group-main-table .rdt_TableHeadRow .rdt_TableCol + .rdt_TableCol {
  border-left: 2px solid #fff;
}

.lead-group-main-table .rdt_TableHeadRow .rdt_TableCol:first-child {
  border-radius: 5px 0 0 5px;
}


.lead-group-main-table .rdt_TableHeadRow .rdt_TableCol:last-child {
  border-radius: 0 5px 5px 0;
}

.lead-group-main-table .rdt_TableCol {
  position: relative;
  padding: 0px 30px;
  font-size: 20px;
  line-height: 30px;
  background: #F6F7FB;
  border-right: 2px solid #FFFFFF;
  color: var(--dark_blue);
  font-weight: normal;
}
.lead-group-main-table .rdt_TableCol span {
  all: unset;
}
.lead-group-main-table .rdt_TableRow {
  background: #fff;
}

.lead-group-main-table .rdt_TableRow + .rdt_TableRow {
  border-top: 3px solid gba(60, 126, 243, 0.05);
}
.lead-group-main-table button#pagination-first-page,
.lead-group-main-table  button#pagination-last-page {
  display: none;
}
.lead-group-distribution{
  flex-wrap: wrap !important;
}