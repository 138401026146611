.pageWrapper {

}

.pageHeader {

}

.pageHeaderLeft {

}

.pageTab {
    display: flex;
    gap: 10px;
}

.pageTabItem {
    color: #3a414a;
    font-size: 13px;
    font-weight: 600;
    cursor: pointer;
    padding: 5px;
    transition: border-bottom 0.3s;
    border-bottom: 2px solid transparent;
}

.pageTabItem:hover {
    color: #007aff;
    border-bottom: 2px solid #007aff;
}

.pageTabItem.activeTab {
    color: #007aff;
    border-bottom: 2px solid #007aff;
}

.pageBody {
    padding: 10px 0;
}