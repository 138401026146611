/* global scroll bar for pdad */
.cf_scroll_bar{
  padding-bottom: 4px;
  overflow-y: auto;
  box-sizing: border-box;
}
/* .cf_scroll_bar::-webkit-scrollbar:hover{
  width: 10px;
} */
.cf_scroll_bar::-webkit-scrollbar {
  width: 6px;
  height: 3px;
}
.cf_scroll_bar::-webkit-scrollbar-track {
  background: rgba(60, 126, 243, 0.1);
  border-radius: 4px;
}
.cf_scroll_bar::-webkit-scrollbar {
  scrollbar-width: thin;
}
.cf_scroll_bar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: #949DB2;
  box-sizing: border-box;
}
.cf_scroll_bar::-webkit-scrollbar-track {
  background: #F4F5F7;
  box-shadow: inset 1px 0px 0px rgba(148, 157, 178, 0.12);
  -webkit-box-shadow: inset 1px 0px 0px rgba(148, 157, 178, 0.12);
}