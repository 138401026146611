.geo__farming__wrapper__update{
    background-color: #fff;
    padding: 16px;
}

.geo__farming__wrapper__update .geo__get__start__btn{
    color: #FFFFFF;
    background-color: #4285F4;
    box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 50%);
    width: 100%;
    display: inline-block;
    max-width: 854px;
    padding: 14px 30px 12px 30px;
    cursor: pointer;
    border-radius: 4px;
}
.geo__farming__wrapper__update .geo__get__start__top{
    font-family: "Poppins", Sans-serif;
    font-size: 30px;
    font-weight: 800;
    display: block;
    line-height: 30px;
    margin-bottom: 7px;
}
.geo__farming__wrapper__update .geo__get__start__top svg path{
    fill:#fff;
}
.geo__farming__wrapper__update .geo__get__start__bottom{
    font-size: 20px;
    line-height: 20px;
    display: inline-flex;
}

.geo__farming__wrapper__update .geo__common__h2{
    color: #1B294C;
    font-family: "Poppins", Sans-serif;
    font-size: 31px;
    font-weight: 600;
    text-transform: capitalize;
    line-height: 1.2em;
}
.geo__farming__wrapper__update .geo__common__p{
    color: #3d4459;
    font-size: 18px;
    font-weight: 300;
    text-align: left;
}
.geo__farming__wrapper__update .geo__top__section__wr{
    text-align: center;
}

.geo__farming__wrapper__update .geo__top__section__wr .geo__heading__h1{
    color: #4285F4;
    font-family: "Poppins", Sans-serif;
    font-size: 60px;
    margin: 20px 0px 0px 0px;
    font-weight: 700;
    text-transform: capitalize;
    line-height: 84px;
}
.geo__farming__wrapper__update .geo__top__section__wr .geo__sub__heading{
    color: #1B294C;
    font-family: "Poppins", Sans-serif;
    font-size: 31px;
    font-weight: 600;
    margin: 0;
    line-height: 46px;
}
.geo__farming__wrapper__update .geo__top__section__wr .geo__top__vidoe__wr{
    padding:27px 0px 23px 0;
}
.geo__farming__wrapper__update .geo__top__section__wr .geo__top__vidoe__wr iframe{
    width: 855px;
    height: 480px;
    border: 0;
    box-shadow: -2px 5px 22px 3px rgba(0,0,0,0.5)
}

.geo__farming__wrapper__update .what__geo__farming__wr{
    display: flex;
    padding-top: 150px;
}
.geo__farming__wrapper__update .what__geo__farming__left{
    flex: 1;
}
.geo__farming__wrapper__update .what__geo__farming__left .what__geo__farming__left__title{
    color: #1B294C;
    font-family: "Poppins", Sans-serif;
    font-size: 35px;
    font-weight: 600;
    text-transform: capitalize;
    line-height: 1.2em;
    text-align: left;
} 
.geo__farming__wrapper__update .what__geo__farming__left p{
    color: #3d4459;
    font-size: 20px;
    font-weight: 300;
    text-align: left;
}
.geo__farming__wrapper__update .what__geo__farming__left .what__borer__dashed{
    border: 1px dashed #000;
    width: 50%;
}
.geo__farming__wrapper__update .what__geo__farming__right{
    flex: 1;
}
.geo__farming__wrapper__update .what__geo__farming__right img{
    width: 86%;
    margin-left: 35px;
}

/* how it work */
.geo__farming__wrapper__update .geo__how__work__wr{
    display: flex;
    grid-gap: 16px;
    padding-top: 150px;
}
.geo__farming__wrapper__update .geo__framing__how__does__it__work h2 {
    color: #FFFFFF;
    font-family: "Poppins", Sans-serif;
    font-size: 45px;
    font-weight: 600;
    text-transform: capitalize;
    line-height: 1.2em;
    margin: 0;
    padding: 0 0 20px 0;
}

.geo__farming__wrapper__update .geo__framing__how__does__it__work .geo__farming__how__border{
    border: 1px dashed #000;
    width: 100%;
    margin: 0 auto 5px auto;
    max-width: 590px;
}
.geo__farming__wrapper__update .geo__framing__how__does__it__work{
    background-color: #1B294C;
    text-align: center;
    color: #fff;
    padding: 10px 0;
    margin: 100px 0 0 0;
}
.geo__farming__wrapper__update .geo__how__work__wr .geo__how__work__wr__left{
    /* flex: 1; */
    width: 40%;
    z-index: 1;
}
.geo__farming__wrapper__update .geo__how__work__wr .geo__how__work__wr__left p{
    color: #3d4459;
    font-size: 20px;
    font-weight: 300;
    text-align: left;
}
.geo__farming__wrapper__update .geo__how__work__wr .geo__how__work__wr_right{
    /* flex: 1; */
    width: 60%;
    /* box-shadow: -2px 5px 22px 3px rgba(0,0,0,0.5) */
}
.geo__farming__wrapper__update .geo__how__work__wr .geo__how__work__wr_right img{
    filter: brightness( 100% ) contrast( 100% ) saturate( 100% ) blur( 0px ) hue-rotate( 0deg );
    border-style: solid;
    border-color: #FFFFFF;
    border-radius: 10px 10px 10px 10px;
    box-shadow: 0px 0px 30px 0px #54595f;
    width: 100%;
}

.geo__farming__wrapper__update  .geo__how__work__wr__left__step{
    color: #4285F4;
    font-family: "Montserrat", Sans-serif;
    font-size: 35px;
    font-weight: 800;
    text-transform: uppercase;
    margin: 20px 0;
}
.geo__farming__wrapper__update  .geo__how__work__wr__left__title{
    color: #1B294C;
    font-family: "Poppins", Sans-serif;
    font-size: 35px;
    font-weight: 600;
    text-transform: capitalize;
    line-height: 1.2em;
    padding: 0 25px 0 0;
}
.geo__farming__wrapper__update  .geo__how__work__wr__left__content{
    color: #3d4459;
    font-size: 20px;
    font-weight: 300;
    text-align: left;
    margin: 20px 0;
}


/* step 2 */
.geo__farming__wrapper__update .geo__step__right__wr{
    display: flex;
    grid-gap: 32px;
    padding-top: 150px;
}
.geo__farming__wrapper__update .geo__step__right__left{
    width: 60%;
    /* box-shadow: -2px 5px 22px 3px rgba(0,0,0,0.5); */
    height: 100%;
}
.geo__farming__wrapper__update .geo__step__right__left img{
    filter: brightness( 100% ) contrast( 100% ) saturate( 100% ) blur( 0px ) hue-rotate( 0deg );
    border-style: solid;
    border-color: #FFFFFF;
    border-radius: 10px 10px 10px 10px;
    box-shadow: 0px 0px 30px 0px #54595f;
    width: 100%;
    height: 490px;
}
.geo__farming__wrapper__update .geo__step__right__right{
    width: 40%;
}
.geo__farming__wrapper__update .geo__step__right__right__step{
    margin: 0 0 20px 0;
    color: #4285F4;
    font-family: "Montserrat", Sans-serif;
    font-size: 35px;
    font-weight: 800;
    text-transform: uppercase;
}
.geo__farming__wrapper__update .geo__step__right__right__title{
    color: #1B294C;
    font-family: "Poppins", Sans-serif;
    font-size: 35px;
    font-weight: 600;
    text-transform: capitalize;
    line-height: 1.2em;
}
.geo__farming__wrapper__update .geo__step__right__right__content{
    color: #3d4459;
    font-size: 20px;
    font-weight: 300;
    text-align: left;
    margin: 20px 0;
}

.geo__farming__wrapper__update .geo__marketing__social{

}
.geo__farming__wrapper__update .geo__marketing__social__single{
    display: flex;
    align-items: center;
    grid-gap: 20px;
}
.geo__farming__wrapper__update .geo__marketing__social__single span{
    font-size: 20px;
    color: #2e3243;
    line-height: 37px;
}


/* Getting Start */
.geo__farming__wrapper__update .geo__getting__started{
    padding: 150px 0 0 0;
    max-width: 663px;
    width: 100%;
    margin: 0 auto;
}
.geo__farming__wrapper__update{
    /* color: #1B294C;
    font-family: "Poppins", Sans-serif;
    font-size: 50px;
    font-weight: 700;
    text-transform: capitalize;
    line-height: 60px;
    text-align: center;
    margin:0;
    line-height: 70px; */
}
.geo__farming__wrapper__update .geo__getting__started__sub__title{
    text-align: center;
    color: #FB0000;
    font-family: "Poppins", Sans-serif;
    font-size: 25px;
    font-weight: 800;
    line-height: 42px;
    margin:0;
}

.geo__farming__wrapper__update .geo__getting__border{
    border: 1px dashed #000;
    width: 68%;
    margin: 14px auto 100px auto;
}

.geo__farming__wrapper__update .geo__getting__started .geo__common__h2{
    margin: 0 0 20px 0;
}

.geo__farming__wrapper__update .geo__global__btn{
    font-size: 20px;
    font-weight: 800;
    color: #FFFFFF;
    background-color: transparent;
    background-image: linear-gradient(180deg, #F49642 0%, #f2295b 100%);
    border-radius: 50px 50px 50px 50px;
    padding:12px 24px;
    display: block;
}
.geo__farming__wrapper__update  .geo__step__btn__wr{
    text-align: center;
    margin-top: 40px;
}
.geo__farming__wrapper__update .padding__top__bottom__50{
    padding: 50px 0 ;
}

.geo__farming__wrapper__update .geo__question__inner{
    background: #F4F7F9;
    text-align: center;
    padding: 50px 0;
}

.geo__farming__wrapper__update .geo__question__inner h2{
    color: #1B294C;
    font-family: "Poppins", Sans-serif;
    font-size: 50px;
    font-weight: 600;
    text-transform: capitalize;
    line-height: 1.2em;
    margin: 0;
}
.geo__farming__wrapper__update .geo__question__inner p{
    text-align: center;
    color: #1B294C;
    font-size: 18px;
    font-family: "Poppins", Sans-serif;
    font-weight: 700;
    line-height: 0.1em;
}



.geo__farming__wrapper__update .question__step__btn__wr{
    text-align: center;
    padding: 36px 0;
}
.geo__farming__wrapper__update .geo__question__btn{
    font-size: 20px;
    font-weight: 800;
    color: #FFFFFF;
    background-color: transparent;
    background-image: linear-gradient(180deg, #F49642 0%, #f2295b 100%);
    border-radius: 50px 50px 50px 50px;
    padding:12px 24px;
  
}

/* faq start */
.geo__farming__wrapper__update .geo__farming__faq__inner{
    background: #fff;
    text-align: center;
    padding: 50px 0;
}
.geo__farming__wrapper__update .geo__farming__faq__title{
    color: #1B294C;
    font-family: "Poppins", Sans-serif;
    font-size: 45px;
    font-weight: 600;
    text-transform: capitalize;
    line-height: 1em;
    margin: 0 0 8px 0;
}
.geo__farming__wrapper__update .geo__farming__faq__sub__title{
    color: #1B294C;
    font-family: "Poppins", Sans-serif;
    font-size: 25px;
    font-weight: 300;
    margin: 0;
}
.geo__farming__wrapper__update .geo__farming__faq__inner__row {
    display: grid;
    grid-template-columns: repeat(2,1fr);
    padding-top: 62px;
    grid-gap: 30px;
}
.geo__farming__wrapper__update .geo__farming__faq__inner__single{
    padding: 30px;
    text-align: left;
    margin-bottom: 45px;
    transition: flex 1s;
    /* transition: all linear 0.5s; */
}
.geo__farming__wrapper__update .geo__farming__faq__inner__single:hover{
    box-shadow: 0px 0px 25px 0px rgb(0 0 0 / 24%);
}
.geo__farming__wrapper__update .geo__farming__faq__inner__header{
    display: flex;
    justify-content: space-between;
    padding: 0 0 15px 0;
    cursor: pointer;
}
.geo__farming__wrapper__update .geo__farming__faq__inner__header h4{
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
    color: #00ce1b;
    margin: 0;
}
.geo__farming__wrapper__update .geo__farming__faq__inner__header span{
    border: 2px solid #00ce1b;
    height: 18px;
    width: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}
.geo__farming__wrapper__update .geo__farming__faq__inner__header span svg {
    width: 16px;
    color: #00ce1b;
}
.geo__farming__wrapper__update .geo_farming__toggle__active h4,
.geo__farming__wrapper__update .geo_farming__toggle__active span svg{
    color: #000
}
.geo__farming__wrapper__update .geo__farming__faq__inner__single__p{
    color: #bfbfbf;
    font-size: 16px;
    font-weight: 300;
    margin: 0;
    padding: 15px 0 26px 0;
    line-height: 28px;
    transition: all linear 0.5s;
}
.geo__farming__wrapper__update .geo__farming__faq__inner__single.geo_farming__toggle__active .geo__farming__faq__inner__single__p{
    padding: 0 !important;
    height: 0 !important;
    overflow: hidden !important;
  
}
.geo__farming__wrapper__update .geo_farming__toggle__active .geo__farming__faq__inner__header span{
    border-color: #000;
}
.geo__farming__wrapper__update .geo__farming__faq__inner__single.geo_farming__toggle__active{
    display: flex;
    flex-direction: column;
    justify-content: center;
    transition: all linear 0.5s;
}
/* faq end */



/* overly section */
.geo__farming__wrapper__update .geo__farming__overly__section{
    position: relative;
}
.geo__farming__wrapper__update .geo__farming__overly__section .what__geo__framing__overly{
    /* background-image: url(https://salespype.com/wp-content/uploads/2021/05/Bg-more-about-the-services.png); */
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 1;
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
    filter: brightness( 99% ) contrast( 100% ) saturate( 200% ) blur( 0px ) hue-rotate( 360deg );
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;

}


.geo__farming__wrapper__update .geo__farming__setp__2{
    position: relative;
}
.geo__farming__wrapper__update .geo__farming__setp__2__overly{
    /* background-image: url(https://salespype.com/wp-content/uploads/2020/05/BG-Service-one.png); */
    background-position: bottom left;
    filter: brightness( 95% ) contrast( 100% ) saturate( 200% ) blur( 0px ) hue-rotate( 360deg );
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 5;
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
}
.geo__farming__wrapper__update .geo__farming__setp__2__overly:before{
    box-sizing: border-box;
}

.geo__farming__wrapper__update .geo__farming__setp__3{
    position: relative;
}
.geo__farming__wrapper__update .geo__farming__setp__3__overly{
    /* background-image: url(https://salespype.com/wp-content/uploads/2021/05/Bg-Services.png); */
    background-position: bottom right;
    filter: brightness( 99% ) contrast( 100% ) saturate( 200% ) blur( 0px ) hue-rotate( 360deg );
    background-repeat: no-repeat;
    opacity: 5;
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
}
.geo__farming__wrapper__update .geo__farming__setp__3__overly:before{
    box-sizing: border-box;
}

.geo__farming__wrapper__update .geo__question__wr {
    margin-top: 20px;
}




@media screen and (max-width:1366px) {
    .geo__farming__wrapper__update .geo__top__section__wr .geo__heading__h1{
        font-size: 45px;
        line-height: 64px;
    }
    .geo__farming__wrapper__update .geo__question__inner h2{
        font-size: 45px;
    }
}

@media screen and (max-width:991px) {
    .geo__farming__wrapper__update .geo__top__section__wr .geo__heading__h1{
        font-size: 36px;
        line-height: 64px;
    }
    .geo__farming__wrapper__update .geo__top__section__wr .geo__sub__heading{
        font-size: 24px;
    }
    .geo__farming__wrapper__update .geo__get__start__top{
        font-size: 24px;
    }
    .geo__farming__wrapper__update .what__geo__farming__left .what__geo__farming__left__title{
        font-size: 24px;
    }
    .geo__farming__wrapper__update .what__geo__farming__wr{
        padding-top: 50px;
        flex-direction: column-reverse;
    }
    .geo__farming__wrapper__update .what__geo__farming__left p{
        font-size: 16px;
    }
    .geo__farming__wrapper__update .geo__how__work__wr{
        flex-direction: column;
        padding-top: 50px;
    }
    .geo__farming__wrapper__update .geo__how__work__wr .geo__how__work__wr__left{
        width: 100%;
    }
    .geo__farming__wrapper__update .geo__how__work__wr .geo__how__work__wr_right{
        width: 100%;
    }
    .geo__farming__wrapper__update .geo__how__work__wr__left__step{
        font-size: 24px;
        margin: 14px 0;
    }
    .geo__farming__wrapper__update .geo__how__work__wr__left__title{
        font-size: 24px;
       
    }
    .geo__farming__wrapper__update .geo__step__right__right__content{
        font-size: 16px;
    }
    .geo__farming__wrapper__update .geo__marketing__social__single span{
        font-size: 16px;
        line-height: 30px;
    }
    .geo__farming__wrapper__update .geo__step__right__right__step{
        font-size: 24px;
        margin: 0 0 14px 0;
    }
    .geo__farming__wrapper__update .geo__step__right__right__title{
        font-size: 24px;
    }
    .geo__farming__wrapper__update .geo__step__right__wr{
        flex-direction: column-reverse;
        padding-top: 50px;
    }
    .geo__farming__wrapper__update .geo__step__right__left{
        width: 100%;
    }
    .geo__farming__wrapper__update .geo__step__right__right{
        width: 100%;
    }
    .geo__farming__wrapper__update .geo__farming__faq__inner__single{
        margin-bottom: 0;
        padding: 15px;
    }
    .geo__farming__wrapper__update .geo__farming__faq__title{
        font-size: 32px;
    }
    .geo__farming__wrapper__update .geo__question__inner h2{
        font-size: 32px;
    }
}


