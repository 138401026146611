/*==============Sitting page css==================*/
/*======Email Sitting css=======*/
.email-sitting, .phone-sitting{
    background: #FFFFFF;
    box-shadow: 0px 3px 15px rgb(0 0 0 / 3%);
    border-radius: 10px;
    padding: 20px 50px;
    margin: 0px 0px 20px;
}
.email-sitting h3, .phone-sitting h3{
    font-size: 24px;
    line-height: 40px;
    margin: 0;
    color: var(--dark_blue);
    padding-bottom: 13px;
    text-transform: capitalize;
}
.email-sitting ul, .phone-sitting ul{
    margin-top: 40px;
    margin-left: -27px;
}
.email-sitting ul li, .phone-sitting ul li{
    max-width: 195px;
    width: 100%;
    padding: 5px;
    }
.email-sitting ul li a, .phone-sitting ul li a{
    display: block;
    text-align: center;
}
.email-sitting ul li a h6, .phone-sitting ul li a h6{
    font-weight: 500;
    font-size: 16px;
    line-height: 34px;
    letter-spacing: 0.01em;
    color: var(--dark_blue);
    text-transform: capitalize;
}
.email-sitting ul li a h6 span{
    color: red;
}
.email-sitting ul li a:hover, .phone-sitting ul li a:hover{
    background: unset !important;
}
.sms-box{
    padding: 22px 0;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    background: var(--light_green);
    margin: 0 auto;
}
/* ==============Sitting icon box=============== */
.sitting_icon_box{
    padding: 22px 0;
    height: 50px;
    width: 50px;
    border-radius: 50%;
}
/*============My Profile icon box css==============*/
.my-profile-icon-box{
    background: var(--blue-2);
    margin: 0 auto;
}
/*============Phone Sitting icon box css==============*/
.phn-icon-box{
    background: var(--purple);
    margin: 0 auto;
}
/*============Billing Info icon box css==============*/
.billing-info-icon-box{
    background: var(--blue-1);
    margin: 0 auto;
}
.billing-info-cancel-icon-box{
    background: var(--red);
    margin: 0 auto;
}
/*============Display setting icon box css==============*/
.display-icon-box{
    background: var(--blue-3);
    margin: 0 auto;
}
/*============Custom field icon box css==============*/
.custom-field-icon-box{
    background: var(--red);
    margin: 0 auto;
}
/*============Pipeline Settings icon box css==============*/
.pipeline-icon-box{
    background: var(--orange);
    margin: 0 auto;
}
/*============Lead icon box css==============*/
.lead-icon-box{
    background: var(--green2);
    margin: 0 auto;
}
/*============Tags & List==============*/
.tags-icon-box{
    background: var( --purple-2);
    margin: 0 auto;
}
/*============General Settings==============*/
.g-setting-icon-box{
    background: var(--blue-3);
    margin: 0 auto;
}
/*============Facebook Settings icon box css==============*/
.facebook-setting-icon-box{
    background: var(--blue-3);
    margin: 0 auto;
}

.options-icon-list .tabs li.indicator {
    display: none !important;
}
/*==========Scrollbar Css=============*/
.sitting-page.awesome__scroll_bar{
    height: 100%;
    overflow-y: auto;
    margin-top: 20px;
    padding-bottom: unset;
}
.sitting-page.awesome__scroll_bar::-webkit-scrollbar{
    width: 10px;
}
.sitting-page.awesome__scroll_bar::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
    background: rgba(19, 49, 89, 0.5);
}
.sitting-page.awesome__scroll_bar::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
    border-radius: 10px;
    background-color: var(--white);
   border: 10px solid white;
}
.back_button_to_account a{
    padding: 8px 15px;
    background: #3c7ef3;
    border-radius: 5px;
    font-family: var(--poppins);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    display: inline-flex;
    align-items: center;
    letter-spacing: .01em;
    color: #fff;
    margin-bottom: 15px;
}
.back_button_to_account a svg{
    /*margin-right: 10px;*/
}

.sitting-page .settings_page_search_wrapper {
    width: 320px;
    position: fixed;
    right: 49px;
    padding: 10px;
    top: 90px;
    border-radius: 10px;
}

.sitting-page .settings_page_search_wrapper input{
    border-radius: 12px;
    box-shadow: 0 0 18px 0 #ddd;
}
.sitting-page .settings_page_search_wrapper input:focus{
    border-bottom: 1px solid #546376 !important;
}



@media (max-width: 992px) {
    /* .has-fixed-sidenav main#main__content { */
        /* margin-left: 268px; */
        /* width: calc(100% - 268px); */
    /* } */
    .main__content .sitting_content{
        width: 100%;
    }
    /* .has-fixed-sidenav.active__minimal_sidebar main#main__content {
        margin-left: 100px;
        width: calc(100% - 100px);
    } */
    .email-sitting ul li, .phone-sitting ul li{
        width: 130px;
    }
    .email-sitting ul li a h6, .phone-sitting ul li a h6{
        font-size: 12px;
    }
    .email-sitting, .phone-sitting{
        padding: 10px 15px;
    }
    .email-sitting ul, .phone-sitting ul{
        margin-top: 10px;
        margin-left: 7px;
    }
    .sitting_icon_box{
        padding: 15px 0;
        height: 30px;
        width: 30px;
    }
    .sitting_icon_box svg{
        height: 13px;
    }
    .email-sitting h3, .phone-sitting h3{
        font-size: 18px;
    }
}