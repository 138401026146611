.SendTemplateBox{
    width:50%;
    border-color: rgba(123, 176, 255, 0.17) !important;
}
.topBar{
    margin: 0;
    background-color: #006df514 ;
    padding: 20px;
}
.sendForm{
    padding: 24px;
    margin-bottom: 24px;
}
.subjectInput,.smsBody{
    margin-bottom: 16px;
}
.inputAndPersonalized{
    display:flex;
    align-items: center;
    gap:20px;
    margin-top: 12px;
}
.helperText{
    margin-top:0 !important;
}
.emailBodyInput{
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding-bottom: 12px;
}
.saveButtonBox{
    display: flex;
    justify-content: flex-end;
    margin-top: 24px;
}
.saveButton{
    background-color: #3C7EF3 !important;
    box-shadow: none !important;
}