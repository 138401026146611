.componentMainWrp{
  width: 100%;
  height: calc(100vh - 215px);
  display: flex;
}
.leftSideWrp{
  width: 350px;
  height: 100%;
  display: flex;
  padding: 8px;
  border-right: 1px solid #949db23d;
  box-sizing: border-box;
}
.rightSideWrp{
  width: 100%;
  flex-grow: 1;
  height: 100%;
  overflow: hidden;
  padding-left: 10px;
}