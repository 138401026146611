  button:focus {
    background-color: unset;
  }

  .payment_gateway_container input:-webkit-autofill,
  .payment_gateway_container input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
  }

  .payment_gateway_container input[data-autocompleted] {
    background-color: transparent !important;
  }

  .payment_gateway_container input:not([type]),
  .payment_gateway_container input[type="text"]:not(.browser-default),
  .payment_gateway_container input[type="password"]:not(.browser-default),
  .payment_gateway_container input[type="email"]:not(.browser-default),
  .payment_gateway_container input[type="url"]:not(.browser-default),
  .payment_gateway_container input[type="time"]:not(.browser-default),
  .payment_gateway_container input[type="date"]:not(.browser-default),
  .payment_gateway_container input[type="datetime"]:not(.browser-default),
  .payment_gateway_container input[type="datetime-local"]:not(.browser-default),
  .payment_gateway_container input[type="tel"]:not(.browser-default),
  .payment_gateway_container input[type="number"]:not(.browser-default),
  .payment_gateway_container input[type="search"]:not(.browser-default),
  .payment_gateway_container textarea.materialize-textarea {
    margin: 0;

    &:focus,
    &:active {
      box-shadow: unset !important;
      --webkit-box-shadow: unset;
      border-bottom: unset;
    }
  }

  .payment_gateway_container input[type="search"]::-webkit-search-decoration,
  .payment_gateway_container input[type="search"]::-webkit-search-cancel-button,
  .payment_gateway_container input[type="search"]::-webkit-search-results-button,
  .payment_gateway_container input[type="search"]::-webkit-search-results-decoration {
    -webkit-appearance: button;
  }

  .payment_gateway_container [type="checkbox"]+span:not(.lever):before,
  .payment_gateway_container [type="checkbox"]:not(.filled-in)+span:not(.lever):after {
    content: none;
  }

  .payment_gateway_container input[type="text"]:not(.browser-default) {
    height: unset;
  }

  .payment_info {
    display: flex;
    justify-content: space-between;
  }

  .publishable_key_info {
    display: flex;
    justify-content: space-between;
  }

  .find_info {
    display: inline-flex;
    align-items: center;
    color: #3C7EF3;
  }

  .select_gateway {
    border: 1px solid #e2e3e9;
    padding: 5px;
    font-size: 16px;
  }

  .input_tag {
    border: 1px solid #e2e3e9 !important;
    box-sizing: border-box !important;
    border-radius: 5px !important;
    padding: 10px 16px !important;
    height: 40px !important;
    font-size: 16px !important;
    line-height: 22px !important;
    letter-spacing: .01em !important;
    color: rgba(19, 49, 89, .5) !important;
    background: #fff !important;
    /* width: 30% !important; */
  }

  .label_text {
    font-size: 16px;
    line-height: 30px;
    color: #133159;
    margin: 0;
  }

  .saveButton button:disabled {
    opacity: .7;
  }