.modalWrapper {
    display: grid;
    place-content: center;
}
.modalInner {
    width: 1200px;
}
.modalInner:focus-visible {
    border: none !important;
    box-shadow: none !important;
    outline: none !important;
}

.modalBody::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}
.modalBody::-webkit-scrollbar-track {
    background: rgba(60, 126, 243, 0.1);
    border-radius: 4px;
}
.modalBody::-webkit-scrollbar {
    scrollbar-width: thin;
}
.modalBody::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #949db2;
    box-sizing: border-box;
}
.modalBody::-webkit-scrollbar-track {
    background: #f4f5f7;
    box-shadow: inset 1px 0px 0px rgba(148, 157, 178, 0.12);
    -webkit-box-shadow: inset 1px 0px 0px rgba(148, 157, 178, 0.12);
}

.paddingWrp {
    padding: 14px;
}

.headerWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 24px 10px 24px;
    border-bottom: 1px solid rgba(148, 157, 178, 0.24);
}

.headerTitle {
    color: #101046;
    font-weight: 500;
}

.headerClose {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    display: grid;
    place-content: center;
    cursor: pointer;
}

.headerClose:hover {
    background-color: rgba(237, 0, 57, 0.03);
}

.headerClose svg path {
    color: #101046;
}

.modalBody {
    height: fit-content;
    max-height: calc(100vh - 260px);
    overflow-y: auto;
    padding: 10px;
    border: 1px solid #e6e6ff;
    border-radius: 4px;
}

.MuiList-root li {
    color: red !important;
}
.textArea {
    border-radius: 4px;
    padding: 8.5px 14px;
    border-color: #ced4da;
}


.list {
    display: flex;
    flex-direction: column;
}

.codePreview {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    background-color: #362929;
    overflow-x: auto;
    position: relative;
}

.codePreview code {
    font-family: 'Courier New', Courier, monospace;
    font-size: 14px;
    color: #4d9538;
}

.codePreview:not(:last-child){
    margin-bottom: 10px;
}