.mainWrp{
  width: 100%;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
  padding: 10px 5px !important;
  cursor: grab;
  margin-bottom: 3px;
  border-radius: 8px;
  box-sizing: border-box;
  margin-right: 5px;
}
.mainWrp:hover{
  background-color: #1c1b1f14;
  transition: background-color 0.3s ease-in-out;
}
.mainWrp:hover .icon{
  display: flex;
}
.mainWrp.active{
  background-color: #1c1b1f14;
  cursor: auto !important;
}
.title{
  font-size: 12px;
  text-transform: capitalize;
  font-weight: 500;
  letter-spacing: 0.35px;
  color: #1c1b1f;
  cursor: pointer;
}
.mainWrp.active .title{
  font-weight: 600;
}
.icon{
  height: 100%;
  width: 40px;
  align-items: center;
  justify-content: center;
  display: none;
}
.icon svg{
  display: inline-flex;
  cursor: pointer;
}
.icon svg:hover{
  fill: #006df5;
}
.sidebarIcon {
  display: flex;
  gap: 5px;
  margin-right: 10px;
}
.sidebarIcon .icon{
  width: unset;
}