.pageWrapper {

}

.pageHeader {
    width: 100%;
    background-color: #edf5ff;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    border: 1px solid #e5eff3;
    border-radius: 4px;
    margin:  16px 0;
}

.pageHeaderTitle {
    line-height: 1.9;
    display: flex;
    align-items: center;
    gap: 15px;
}

.pageMainTitle {
    color: #3a414a !important;
    padding: 0 !important;
    margin: 0 !important;
    font-size: 24px !important;
    text-transform: uppercase !important;
    font-weight: 600 !important;
}

.pageMainSubtitle {
    color: #3a414a !important;
    font-size: 15px !important;
    padding: 0 !important;
    margin: 0 !important;
}

.pageHeaderAction {
    display: flex;
    align-items: center;
    gap: 20px;
}

.formControl {
    width: 100%;
}

.formControl .MuiInputBase-root {
    color: black !important;
}

.pageBody {
    color: #3a414a;
    background: #ffffff;
    padding: 8px 16px;
    border: 1px solid #635dff;
    border-radius: 4px;
}

.tableHead {
    color: #3a414a;
    font-size: 17px;
}

.fileTitle {
    font-size: 16px;
    font-weight: 700;
}

.validationType {
    font-size: 14px;
}

.validationTypeLabel {
    font-size: 16px;
    font-weight: 700;
}

.fileId {
    font-size: 14px;
}

.fontBold {
    font-weight: 700;
}

.statusChip {
    background-color: #00c2a8;
    color: #ffffff;
    padding: 4px 8px;
    border-radius: 14px;
    font-size: 14px;
}

.viewDetails {
    background-color: #d9e3eb;
    padding: 6px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600;
    cursor: default;
}

.fileActions {
    display: flex;
    justify-content: space-between;
}

.textAlignCenter {
    text-align: center;
}

.detailsPane {

}

.detailsPaneHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
}

.detailsPaneHeaderLeft {
    /* width: 22%; */
    display: flex;
    gap: 20px;
}

.detailsPaneHeaderRight {
    display: flex;
    /* width: 60%; */
    gap: 5px;
    align-items: center;
    flex-wrap: wrap;
    justify-content: flex-end;
}

.filterWrapper {
    padding: 30px 0 20px 40px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: #fff;
    margin-bottom: 30px;
}

.pageBody table tbody tr:last-child {
    border: none;
}

.detailsPaneBody {

}

.detailsPaneBody table thead th {
    padding: 4px 16px;
    background-color: #e7e7e7;
    font-size: 14px;
    font-weight: 600;
    color: #464646de;
}

.detailsPaneBody table tbody tr {
    border: none;
}

.detailsPaneBody table tbody td {

}

.dFlexAlignCenter {
    display: flex;
    align-items: center;
}

.backButton {
    width: 120px;
}

.loaderWrapper {
    padding: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.marginTop {
    margin-top: 100px;
}

.truncate {
    width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.failedReason {
    font-size: 12px;
}

.cursorPointer {
    cursor: pointer;
}

.active {
    background-color: #4CAF50;
    color: #ffffff;
}

.queued {
    background-color: #FFC107;
    color: #333333;
}


.processing {
    background-color: #2196F3;
    color: #ffffff;
}

.complete {
    background-color: #8BC34A;
    color: #333333;
}


.failed {
    background-color: #F44336;
    color: #ffffff;
}

.default {
    background-color: #f2f2f2;
    color: #333333;
}

.moreAddress {
    margin: 0 !important;
}

.moreAddress li {
    color: #000 !important;
    font-size: 12px !important;
}

.moreAddress li span {
    font-weight: bold;
}

a.filterBtn {
    border: 1px solid #E5E8EF;
    background: #F6F7FB;
    color: #133159;
    min-width: 60px;
    margin-right: 10px;
    display: flex;
    border-radius: 5px;
    line-height: 24px;
    font-size: 16px;
    height: 50px;
    text-align: center;
    align-items: center;
    justify-content: center;
}

a.filterBtn svg{
    outline: 0;
}

a.refreshBtn {
    border: 1px solid #E5E8EF;
    background: #F6F7FB;
    color: #133159;
    min-width: 127px;
    display: flex;
    border-radius: 5px;
    line-height: 24px;
    font-size: 16px;
    height: 50px;
    text-align: center;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

a.backBtn {
    border: 1px solid #3C7EF3;
    background: #3C7EF3;
    color: #fff;
    min-width: 157px;
    margin-left: 24px;
    display: flex;
    border-radius: 5px;
    line-height: 24px;
    font-size: 16px;
    height: 50px;
    text-align: center;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.searchFieldWrapper input {
    border: 1px solid #0059cb;
    box-sizing: border-box !important;
    border-radius: 5px;
    padding: 0 18px;
    height: 40px;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: .01em;
    color: #a6acbe;
    margin: 0;
    padding-right: 30px !important;
    background-color: #fff;
    font-weight: 400;
}

.searchFieldWrapper input:focus {
    box-shadow: none !important;
    border: 1px solid #546376 !important;
    color: #444;
}

.searchFieldWrapper input::placeholder {
    color: #8b8b8b;
    opacity: 1;
}

.searchFieldWrapper input::-ms-input-placeholder {
    color: #8b8b8b;
}

.highlight {
    color: #1b5689;
}

.tablePagination {
    background: var(--white);
    padding: 12px;
    border-radius: 5px;
    font-size: 15px;
    color: var(--dark_blue);
    margin-bottom: 8px;
}

.tablePaginationInner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    grid-gap: 8px;
    gap: 8px;
    padding: 0 13px;
    position: relative;
    width: 60%;
}

.perPageSelect {
    display: flex;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: var(--text-secondary-color);
    align-items: center;
    grid-gap: 8px;
    gap: 8px;
}

.pageSelect {
    display: flex;
    align-items: center;
    gap: 10px;
}

.custom_data_enhance_blue_btn
{
    background-color:#316aff !important;
    color:white !important;
    border-radius:5px !important;

}

.custom_btn_min_width_115_px
{
    min-width: 115px !important;
}

.dark_blue_color
{
    color: #181f48 !important;
}

.enhance_data_dnc_result_header
{
    background-color: #181f48 !important;
    color: white !important;
}

.textAlignCenter {
    text-align: center !important;
}