input, button, select{
    font-family: var(--poppins);
}

.snapshot-user-mark {
    border-radius: 100px;
    padding: 3px 6px;
    background-color: #fd0b0b;
    color: white;
    cursor: default;
}

.mail__inbox {
    background: #fff;
    border-radius: 10px;
    margin: 0 5px;
    margin-top: 30px;
}
.tagBox{
    display: flex;
    border-bottom:1px solid rgba(60, 126, 243, 0.1);
    padding-bottom:10px;
    align-items: center;
}
.tag_list h5{
    font-weight: normal;
    font-size: 24px;
    line-height: 40px;
    font-size: 24px;
    letter-spacing: 0.01em;
    color: var(--dark_blue);
    padding: 0px 20px;
}
.tag_list h5 span{
    color: #546376;
}
.tagBox .tag_btn .creat_tag {
    border-radius: 5px;
    padding: 13px 20px;
    background: #3C7EF3;
    color: #fff;
    font-size: 16px;
    border:none;
    display: flex;
}
.tagBox .tag_btn .creat_tag svg {
    background: #fff;
    padding:5px;
    color:#fff;
    border-radius: 50%;
    margin-right: 10px;
}
.ProfileModule-container-parent-class .details {
    padding: 13px 13px;
}
.details .items{
    display: flex;
    justify-content: space-between;
    padding-bottom: 3px;
    align-items: center;
}
.records {
    display: flex;
    align-items: center;
}
.records span{
    font-size: 16px;
    line-height: 30px;
    color: #133159;
}
.details .items .input-field{
    margin-bottom: unset;
    margin-top: unset;
}
.records input{
    width: 66px !important;
    font-weight: 600;
    margin-left: 10px !important;
    margin-right: 10px !important;
    margin-top: 5px !important;
    background: #FFFFFF;
    border: 1px solid #E5E8EF !important;
    box-sizing: border-box !important;
    border-radius: 5px !important;
    position: relative !important;
    font-size: 16px !important;
    line-height: 24px !important;
    letter-spacing: 0.01em;
    color: #546376;
    padding: 0 10px !important;
    height: 40px !important;
}
.records .select_icon{
    position: absolute;
    top: 15px;
    right: 22px;
    cursor: pointer;
}
.records .caret{
    fill: var(--gray) !important;
    right: 16px !important;
}
.records select:focus{
    outline: unset !important;
}
.details .items .records p {
    font-size: 16px;
    line-height: 30px;
    color: #133159;
}
.record_search{
    position: relative;
}
.record_search .search{
    background: #FFFFFF;
    border: 1px solid rgba(19, 49, 89, 0.65) !important;
    border-radius: 5px !important;
    width: 204px !important;
    height: 50px !important;
    box-sizing: border-box !important;
    padding: 0 30px 0 10px !important;
    font-size: 16px !important;
    line-height: 22px !important;
    letter-spacing: 0.01em  !important;
    color: rgba(84, 99, 118, 0.65) !important;
}
.record_search .search_icon {
    position: absolute;
    right: 30px;
    top: 10px;
    cursor: pointer;
}
.details .items .record_search .search_icon svg {
    position: absolute;
    top:8px;
}
.details .items .record_search input:focus {
    border:1px solid #333;
    outline: none;
    box-shadow: none;
}
.details table {
    width: 100%;
    height: auto;
    border-collapse: collapse;
    position: relative;
    margin-top: 10px;
    border-radius: 10px;
    table-layout: fixed;
}
.details table thead{
    width: 100%;
    position: relative;
}
/*  */
.details table thead tr th:first-child{
    border-radius: 5px 0 0 5px;
    width: 22.2%;
    box-sizing: border-box;
    white-space: nowrap;
}
.details table thead tr th:nth-child(2){
    width: 24%;
} 

.details table thead tr th:nth-child(3){
    width: 21.5%;
}
.details table thead tr th:nth-child(4){
    width: 22.5%;
}
.details table thead tr th:last-child{
    border-right: unset;
    border-radius: 0 5px 5px 0;
    width: 15%;
}

.details table tr {
    height: 60px;
    border-bottom: 3px solid rgba(60, 126, 243, 0.05) !important;
    background: white;
}
.details table .header{
    height: 60px;
}
.details table .header th{
    position: relative;
    padding: 0px 30px;
    font-size: 20px;
    line-height: 36px;
    background: #F6F7FB;
    border-right: 2px solid #FFFFFF;
    color: var(--dark_blue);
}
/* .details table .header th::after{
    content: '';
    background: url(./img/filter_icon.svg) no-repeat;
    position: absolute;
    height: 20px;
    width: 20px;
    right: 0;
    top: 18px;
    cursor: pointer;
} */
.details table .header th span{
    
}
.details table tbody tr:last-child {
    border-bottom:none;
}
.details table tr td {
    color: #133159;
    font-size: 13px;
    font-weight: 600;
}
.details table tr td:nth-child(2){
    width: 12%;
}
.details table tr td:last-child{
    width: 20%;
}
.details table tr td:last-child a{
    margin-right: 15px;
}
.details table tr td:last-child .edit-list-button{
    margin-right: 15px;
}

.details table tr td:last-child a:last-child{
    margin-right: 0px;
}
/* #main__content .details table tbody tr:hover {
    background: #03a9f4;
    color:#fff;
} */
.details table tr td {
    padding: 0px 30px;
    font-size: 16px;
    line-height: 24px;
    color: rgba(19, 49, 89, 0.65);
}
.details table tr td span{
    color: var(--dark_blue);
}
.details table tr td button.parsed,.details table tr td button.notParsed  {
    width: 120px;
    padding: 8px 30px;
    background: transparent;
    border:1px solid #219653;
    outline:none;
    border-radius: 50px;
    display: flex;
    position: relative;
}
.details table tr td button.notParsed {
    border:1px solid #F2994A;
}
.details table tr td button.parsed .dot, .details table tr td button.notParsed .dot{
    width: 8px;
    height: 8px;
    border-radius: 50%;
    position: absolute;
    left:15px;
    top:14px;
}
.details table tr td button.parsed .dot_c_blue {
    background: #219653;
   
}
.details table tr td button.notParsed .dot_c_orng {
    background: #F2994A;
}
.details table tr td button.notParsed .txt_c_orng {
    color:#F2994A;
}
.details table tr td button.parsed span {
    color:#219653;
}
.details table tr td button.details_btn, 
.details table tr td button.delete_btn {
    padding: 7px 12px;
    outline:none;
    border-radius: 5px;
    position: relative;
    border:none;
    color:#fff;
    cursor: pointer;
    margin-right: 13px;
}
.details table tr td button.details_btn {
    background: #3C7EF3;
   
}
.details table tr td button.delete_btn {
    background: #FF264A;
   
}
.details table tr td button.details_btn .icons , #main__content .details table tr td button.delete_btn .icons {
    display: flex;
    justify-content: center;
}

.details table tr td button.details_btn .icons .svg_box , #main__content .details table tr td button.delete_btn .icons .svg_box  {
    background: #fff;
    padding: 8px ;
    border-radius: 50%;
    width: 17px;
    height: 17px;
    display: flex;
    margin-right: 6px;
    position: relative;
    
}
.details table tr td button.details_btn .icons .svg_box .svg_icon {
    position: absolute;
    top:-2px;
    left:3px;
}
.details table tr td button.details_btn .icons .icon_txt span,
.details table tr td button.delete_btn .icons .icon_txt span  {
    font-weight: 600;
}
.details table tr td button.delete_btn .icons .svg_box .svg_icon {
    position: absolute;
    top:0;
    left:0;
}
.details table tr td button.delete_btn .icons .svg_box .svg_icon svg:nth-of-type(1) {
    position: absolute;
    top:5px;
    left:5px;
}
.details table tr td button.delete_btn .icons .svg_box .svg_icon svg:nth-of-type(2) {
    position: absolute;
    top:7px;
    left:5px;
}
/* #main__content .details table tr td button.details_btn .svg_icon svg {
    position: absolute;
    left:3px;
    top:3px;
} */
.pagination_bar {
    padding: 62px 0 20px;
}
.pagination_bar ul  {
   display: flex;
   justify-content: center;
}
.pagination_bar ul li a {
    color:#fff;
    background: #3C7EF3;
    border-radius: 5px;
    padding: 6px 11px;
    margin-left:10px;
    display: block;
    border:2px solid #3C7EF3;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
}
.pagination_bar ul li:nth-child(2) a {
    background: transparent;
    border:2px solid #3C7EF3;
    color:#3C7EF3;
}
.pagination_bar ul li:nth-child(3) a {
    background: transparent;
    color:#3C7EF3;
    border: unset;
    padding: 0px 6px;
}
.pagination_bar ul li:nth-child(3) a span {
    margin-right: 5px;
}


/* ==========Modal css========== */
.modal_details{
    background: var(--white);
    border-radius: 10px 0px 0px 10px;
    max-width: 660px;
    left: unset;
    top: 80px !important;
    max-height: 88% !important;
}
.top_content {
    border-bottom: 1px solid #E7EFF0;
}
.cross_button {
    background: var(--red);
    width: 26px;
    height: 26px;
    border-radius: 50%;
    cursor: pointer;
}
.sub_title h2 {
    font-size: 24px;
    line-height: 30px;
    font-weight: 700;
    text-transform: capitalize;
    letter-spacing: 0.03em;
    color: var(--dark_blue);
    margin: 0;
}

.table_btn{
    background: var(--light_blue);
    padding: 4px 20px 6px 20px;
    border-radius: 3px;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #FFFFFF;
    text-transform: capitalize;
    cursor: pointer;
}
.details_btn{
    margin-left: 10px;
    margin-right: 15px;
}
.delete_btn{
    background: var(--red);
}
.table_btn span{
    height: 20px;
    width: 20px;
    background: #fff;
    border-radius: 50%;
    display: inline-block;
    text-align: center;
    line-height: 19px;
    margin-left: -10px;
    margin-right: 5px;
}
.custom_input label{
    font-weight: normal;
    font-size: 16px;
    line-height: 30px;
    color: #133159;
} 
/* ===========Switch css============= */
.ProfileModule-container-parent-class .Switch {
    position: relative;
    display: inline-block;
    font-size: 1.6em;
    font-weight: bold;
    color: #ccc;
    height: 30px;
    width: 110px;
    padding: 6px 6px 5px 6px;
    border: 1px solid #219653;
    border-radius: 25px;
    background: var(--white);
    cursor: pointer;
    font-size: 16px;
}
.ProfileModule-container-parent-class .Switch.On{
    border: 1px solid #F2994A;
}
.ProfileModule-container-parent-class .Switch span {
    display: inline-block;
    width: 35px;
}
.ProfileModule-container-parent-class .Switch span.On {
    font-size: 16px;
    line-height: 24px;
    color: #219653;
    font-weight: 400;
}
.ProfileModule-container-parent-class .Switch .Toggle {
    position: absolute;
    top: 1px;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background: #219653;
    background: -moz-linear-gradient(top, #219653 0%, #219653 100%);
    background: -o-linear-gradient(top, #219653 0%, #219653 100%);
    background: -ms-linear-gradient(top, #219653 0%, #219653 100%);
    background: linear-gradient(to bottom, #219653 0%, #219653 100%);
    -webkit-transition: all 0.15s ease-in-out;
}
.ProfileModule-container-parent-class .Switch.On .Toggle {
    left: 4%;
    top: 3px;
    background: #F2994A;
}
.ProfileModule-container-parent-class .Switch.Off .Toggle {
    left: 73%;
    top: 3px;
}
/* Round Switch */
.ProfileModule-container-parent-class .Switch.Round {
    padding: 0px 20px;
    border-radius: 40px;
}
body.IE7 .Switch.Round { width: 1px; }
.Switch.Round .Toggle {
    border-radius: 40px;
    width: 14px;
    height: 14px;
}
.ProfileModule-container-parent-class .Switch.Round.Off .Toggle {
    left: 3%;
    background: #33d2da;
}
.ProfileModule-container-parent-class .Switch.Round.On .Toggle { 
    left: 58%; 
}
.ProfileModule-container-parent-class .info {
    font-size: 1.2em;
    margin-bottom: 4px;
}
.ProfileModule-container-parent-class .Switch.Off .Off {
    opacity: 0;
    visibility: hidden;
}
.ProfileModule-container-parent-class .Switch.On .On {
    opacity: 1;
    visibility: visible;
}
.ProfileModule-container-parent-class .Switch.On .Off {
    visibility: visible;
    opacity: 1;
    color: #F2994A;
    transition: all 0.4s;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400 !important;
}
.ProfileModule-container-parent-class .Switch.On .On {
    visibility: hidden;
    opacity: 0;
    transition: all 0.4s;
}
/* ======Modal css======= */
.charged{
    background: #fff;
    border-radius: 5px;
    padding: 15px 62px 15px 15px;
    margin-bottom: 30px;
}

.price_form{
    padding: 0px;
}
.price_form_content .custom_input input{
    border: 1px solid #e2e3e9 !important;
    box-sizing: border-box !important;
    border-radius: 5px !important;
    padding: 10px 16px !important;
    height: 40px !important;
    font-size: 16px !important;
    line-height: 22px !important;
    letter-spacing: 0.01em !important;
    color: rgba(19, 49, 89, 0.5) !important;
    background: #fff !important;
}
.label_text{
    font-size: 16px;
    line-height: 30px;
    color: #133159;
    margin: 0;
}
.defult_card_no p{
    font-size: 16px;
    line-height: 24px;
    color: #133159;
}
.custom_input input:focus{
    box-shadow: unset !important;
}
.label_text span{
    color: var(--red);
    margin-left: 5px;
}
.title h6{
    font-weight: normal;
    font-size: 16px;
    line-height: 30px;
    color: #133159;
    margin: 0;
}
.title h6 span{
    color: var(--red);
    margin-left: 5px;
}
.switch{
    margin-left: 40px;
}
.switch label input[type=checkbox] ~.no_team{
    color: #133159;
    margin-left: 5px;
}
.switch label input[type=checkbox] ~.yes_team{
    color: rgba(19, 49, 89, 0.5);
}
.switch label input[type=checkbox]:checked ~.no_team{
    color: rgba(19, 49, 89, 0.5);
    margin-left: 5px;
}
.switch label input[type=checkbox]:checked ~.yes_team {
    color: #133159;
}
.switch label .yes_team{
    position: absolute;
    left: -30px;
}
label .no_team{
    text-transform: capitalize;
}

.switch label{
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.02em;
    color: #133159;
    text-transform: capitalize;
    position: relative;
}
.switch.team_switch label .lever{
    margin: unset !important;
    background-color:  rgba(60, 126, 243, 0.2) !important;
}
.switch.team_switch label .lever:before, .switch.team_switch label .lever:after{
    background-color: #3C7EF3 !important;
}
.payment_details{
    padding-bottom: 30px;
}
.payment_details h1{
    font-weight: normal;
    font-size: 24px;
    line-height: 40px;
    color: #133159;
    border-bottom: 1px solid #E5E8EF;
}
.defult_card{
    border: 1px solid rgba(60, 126, 243, 0.5);
    box-sizing: border-box;
    border-radius: 5px; 
    padding: 10px;
    margin-right: 27px;
}
.defult_card img{
    margin-left: 20px;
}
.defult_button{
    background: #68EBCA;
    border-radius: 5px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.01em;
    color: #FFFFFF;
    padding: 3px 7px;
}
.add_card{
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.01em;
    color: #3C7EF3;
    border: 1px solid #3C7EF3;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 13px 20px;
}
.card_no{
    font-size: 16px;
    line-height: 24px;
    color: rgba(19, 49, 89, 0.65);
    margin-left: 10px;
}
.expire_date{
    font-size: 16px;
    line-height: 24px;
    color: rgba(19, 49, 89, 0.65);
    margin-left: 20px;
}
.create_new_user{
    padding: 20px 30px 30px;
}
.create_new_user a{
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    letter-spacing: 0.01em;
    color: #FFFFFF;
    background: #3C7EF3;
    border-radius: 5px;
    padding: 15px 20px;
}
.create_new_user a span{
    display: flex;
    margin-right: 10px;
}
.charged h5{
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #133159;
    margin: 0;
}
.charged h5 .light_blue_text{
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    color: #3C7EF3;
}
.charged p{
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.01em;
    color: rgba(19, 49, 89, 0.5);
}
.charged p .light_blue_text{
    color: #3C7EF3;
}
.red_text{
    color: var(--red);
}
.charged H6{
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.01em;
    color: #133159;
}
.charged H6 .light_blue_text{
    color: #3C7EF3;
}
.select_role_drp input{
    background: #F6F7FB !important;
}
.select-wrapper .caret{
    z-index: 1 !important;
    fill: #546376 !important;
}

.team_settings_section table tr th {
    font-size: 14px !important;
}






/* ==============Responsive css=================== */
@media only screen and (min-width: 1666px) and (max-width: 1800px){
    .details table thead tr th:nth-child(4){
        width: 14.5%;
    }
    .details table thead tr th:first-child{
        width: 16.8%;
    }
}
@media only screen and (min-width: 1505px) and (max-width: 1666px){
    .details table thead tr th:first-child{
        max-width: 11%;
    }
    .details table thead tr th:nth-child(3){
        width: 9.5%;
    }
    .details table thead tr th:nth-child(2){
        max-width: 14%;
    }
    ils table thead tr th:nth-child{
        max-width: 15%;
    }
    .details table thead tr th:nth-child(4){
        max-width: 13%;
    }
    .details table thead tr th:nth-child(5){
        max-width: 9%;
    }
    .details table tr td{
        font-size: 15px;
    }
    .details_btn{
        margin-right: 9px;
    }
    .table_btn{
        padding: 4px 13px 6px 13px;
    } 
}
@media only screen and (min-width: 1400px) and (max-width: 1505px){
    .details_btn{
        margin-left: 3px;
        margin-right: 5px;
    }
    .table_btn span{
        margin-left: -7px;
        margin-right: 1px;
    }
    .table_btn{
        padding: 4px 4px 6px 13px;
    }
    .details table tr td{
        font-size: 14px;
    }
    .details table thead tr th:first-child{
        width: 11.2%;
    }
    .details table thead tr th:nth-child(2){
        width: 15%;
    }
    .details table thead tr th:nth-child(3){
        max-width: 13%;
    }
    .details table thead tr th:nth-child(4){
        width: 14.5%;
    }
    .details table thead tr th:last-child{
        width: 11%;
    }
}
@media only screen and (min-width: 1366px) and (max-width: 1400px){
    .details_btn{
        margin-left: 3px;
        margin-right: 5px;
    }
    .table_btn span{
        margin-left: 3px;
        margin-right: 1px;
    }
    .table_btn{
        padding: 4px 4px 6px 13px;
    }
    .details table thead tr th:first-child{
        width: 10.2%;
    }
    .details table thead tr th:nth-child(2){
        width: 13%;
    }
    .details table tr td{
        font-size: 14px;
    }
    .details table thead tr th:nth-child(4){
        width: 12.5%;
    }
    .details table thead tr th:nth-child(3){
        max-width: 10%;
    }
    .details table thead tr th:last-child{
        width: 10%;
    }
}
@media only screen and (min-width: 1024px) and (max-width: 1366px){
    .table_btn{
        background: unset;
        padding: 0 !important;
        font-size: 0px;
    }
    .table_btn span{
        border: 1px solid #2F80ED; 
        line-height: 28px;
    }
    .delete_btn span{
        border: 1px solid red;
    }
    .details table tr td{
        font-size: 16px;
    }
    .details table thead tr th:nth-child(3){
        max-width: 9%;
    }
    .details table thead tr th:nth-child(5){
        max-width: 20%;
    }
    .details table tr td button.parsed .dot, .details table tr td button.notParsed .dot{
        top: 11px;
    }
    .pagination_bar{
        padding: 0px 0 6px;
    }
    .details table thead tr th:nth-child(4){
        width: 7%;
    }
    .details table thead tr th:last-child{
        width: 9%;
    }
    .details table tr td button.parsed, .details table tr td button.notParsed{
        width: 100px;
    }
    .details table thead tr th:first-child{
        width: 8%;
    }
    .details table thead tr th:nth-child(2){
        width: 12%;
    }
    .details table .header th{
        font-weight: normal;
        padding: 0px 20px;
    }
    .details table tr td{
        padding: 0px 20px;
    }
    .details table tr td span svg{
        height: 25px;
        width: 25px;
    }
}
@media only screen and (min-width: 992px) and (max-width: 1024px){
    .details table .header th{
        font-size: 12px !important;
    }
    .pagination_bar{
        padding: 0px 0 6px;
    }
    .details{
        padding: 13px 10px;
    }
    .tag_list h5{
        padding: 0px 10px;
    }
    .table_btn{
        background: unset;
        padding: 0 !important;
        font-size: 0px;
    }
    .table_btn span{
        border: 1px solid #2F80ED; 
        line-height: 28px;
        margin-left: -6px;
        margin-right: -1px;
    }
    .details table .header th{
        padding: 0px 15px;
    }
    .delete_btn span{
        border: 1px solid red;
    }
    .details table tr td{
        font-size: 10px;
    }
    .details table tr td{
        padding: 0px 15px;
    }
    .details table tr td span svg{
        width: 22px;
        height: 22px;
    }
    .details table thead tr th:first-child{
        max-width: 11.8%;
    }
    .details table thead tr th:nth-child(3){
        max-width: 12%;
    }
    .details table thead tr th:nth-child(4){
        max-width: 13.5%;
    }
    .details table thead tr th:nth-child(5){
        max-width: 15%;
    }
    .details table tr td button.parsed .dot, .details table tr td button.notParsed .dot{
        top: 11px;
    }
    .details table tr td button.parsed, .details table tr td button.notParsed{
        padding: 9px 18px;
        width: 79px;
    }
    .details table tr td button.parsed .dot, .details table tr td button.notParsed .dot{
        left: 7px;
    }
    .details table thead tr th:nth-child(2){
        max-width: 16%;
    }
    .Switch{
        width: 40px;
        height: 21px;
    }
    .Switch span.On{
        display: none;
    }
    .Switch.On .Off{
        display: none;
    }
    .Switch.Off .Toggle{
        left: 51%;
        top: 3px;
        width: 14px;
        height: 14px;
    }
    
}
@media only screen and (min-width: 768px) and (max-width: 992px){
    /* body.active__minimal_sidebar .main__content_wrapper .main__content{
        width: calc(100% - 110px);
        margin-right: 11px;
        margin-left: 97px;
    } */
    .details table .header th{
        font-size: 12px !important;
        font-weight: normal;
    }
    .Switch{
        width: 40px;
        height: 21px;
    }
    .Switch span.On{
        display: none;
    }
    .Switch.On .Off{
        display: none;
    }
    .Switch.Off .Toggle{
        left: 51%;
        top: 3px;
        width: 14px;
        height: 14px;
    }
    .Switch.On .Toggle{
        left: 8%;
        top: 3px;
        width: 14px;
        height: 14px;
    }
    .pagination_bar{
        padding: 0px 0 6px;
    }
    .details{
        padding: 13px 10px;
    }
    .tag_list h5{
        padding: 0px 10px;
    }
    .table_btn{
        background: unset;
        padding: 0 !important;
        font-size: 0px;
    }
    .table_btn span{
        border: 1px solid #2F80ED; 
        line-height: 34px;
        margin-left: -6px;
        margin-right: -1px;
        height: 26px;
        width: 26px;
    }
    .details table tr td:last-child a{
        margin-right: 2px;
    }
    .details table .header th{
        padding: 0px 15px;
    }
    .details table tr td {
        padding: 0px 15px;
    }
    .details table tr td span svg{
        height: 16px;
        width: 16px;
    }
    .delete_btn span{
        border: 1px solid red;
    }
    .details table tr td{
        font-size: 12px;
    }
    .details table thead tr th:first-child{
        max-width: 18.8%;
    }
    .details table thead tr th:nth-child(3){
        max-width: 16%;
    }
    .details table thead tr th:nth-child(4){
        max-width: 14.5%;
    }
    .details table thead tr th:nth-child(5){
        max-width: 15%;
    }
    .details table tr td button.parsed .dot, .details table tr td button.notParsed .dot{
        top: 11px;
    }
    .details table tr td button.parsed, .details table tr td button.notParsed{
        padding: 9px 18px;
        width: 79px;
    }
    .details table tr td button.parsed .dot, .details table tr td button.notParsed .dot{
        left: 7px;
    }
    .details table thead tr th:nth-child(2){
        max-width: 26%;
    }
    .details table thead tr th:last-child{
        width: 13%;
    }
    .pagination_bar ul li a{
        padding: 3px 11px;
    }
    .pagination_bar ul li:nth-child(3) a{
        margin-top: -9px;
        padding: 2px 6px;
    }
    .pagination_bar ul li:nth-child(3) a span{
        margin-top: 9px;
        padding: 2px 6px;
    }
}

/* notification  table */
.team_settings_section table thead {background: #E5E8EF;}
.team_settings_section table {
    border-radius: 10px;
    overflow: hidden;
}
.team_settings_section table tbody tr:last-child {border: none;}
.team_settings_section .notification_table a {
    line-height: 24px;
    font-size: 14px;
    display: block;
    color: #3C7EF3;
}
.team_settings_section .notification_table th svg,
.team_settings_section .notification_table td img {
    vertical-align: middle;
    margin: 0 5px;
    width: 20px;
}
.team_settings_section .switch {
    display: inline-block;
    position: relative;
    width: 66px;
    height: 30px;
    cursor: pointer;
}
.team_settings_section [type="checkbox"]:checked + span + small {display: none;}
.team_settings_section .switch input {
    opacity: 0;
    width: 0;
    height: 0;
}
.team_settings_section .slider {
    position: absolute;
    transition: .4s;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
}
.team_settings_section .slider:before {
    position: absolute;
    content: "";
    height: 24px;
    width: 24px;
    left: 4px;
    bottom: 3px;
    transition: .4s;
    background-color: #fff;
}
.team_settings_section input:checked + .slider {background-color: #2196F3;}
.team_settings_section input:checked + .slider:before {
    transform: translateX(34px);
    border: none;
    top: 0px;
    left: 5px;
    width: 24px;
    height: 24px;
    background: #fff;
}
.team_settings_section .slider.round {
    border-radius: 34px;
    height: 30px;
    background: #E5E8EF;
}
.team_settings_section .slider.round:before {
    border-radius: 50%;
    border: none;
    top: 1px;
    left: 5px;
    width: 22px;
    z-index: 99;
    height: 22px;
    background: #9CA8BA;
}
.team_settings_section .switch .on {
    color: #fff;
    left: 4px;
}
.team_settings_section .switch .on,
.team_settings_section .switch .off {
    line-height: 32px;
    position: absolute;
    height: 30px;
    top: 0;
    z-index: 9;
    font-size: 16px;
    text-transform: uppercase;
}
.team_settings_section .switch .off {
    color: #9CA8BA;
    right: 5px;
}
.team_settings_section table th.text-center,
.team_settings_section table td.text-center {text-align: center;}
.team_settings_section table th {
    border: 1px solid #fff;
    white-space: nowrap;
    position: relative;
    font-size: 20px !important;
    line-height: 30px !important;
}
.team_settings_section table th,
.team_settings_section table td {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
}

.team_settings_section {
    min-height: calc(100vh - 120px);
    margin: 20px 0;
    border-radius: 10px;
}


.price_form.create_sub_user .conversative .title {
    min-width: 552px;
}
.price_form.create_sub_user .snapshot-selections .conversative {
    margin-left: 150px;
}
.price_form.create_sub_user .snapshot-selections .conversative .title {
    min-width: 170px;
}

.twili__migration___container .twilio__migration__submit__btn {
    background: #215adc;
    color: #fff;
    display: inline-flex;
    max-width: 200px;
    justify-content: center;
    height: 44px;
    align-items: center;
    border-radius: 5px;
    cursor: pointer;
    float: right;
    margin-right: 10px;
}
#panel1a-content .MuiAccordionDetails-root,
#panel1a-content .MuiAccordionDetails-root > p,
#panel2a-content .MuiAccordionDetails-root,
#panel2a-content .MuiAccordionDetails-root > p,
#panel3a-content .MuiAccordionDetails-root,
#panel3a-content .MuiAccordionDetails-root > p,
#panel4a-content .MuiAccordionDetails-root,
#panel4a-content .MuiAccordionDetails-root > p
{
    width: 100%;
}
li.snapshot--list-item {
    background: #e7ecfd;
    padding: 5px 20px;
    justify-content: space-between;
}
.snapshot--list-item .MuiFormGroup-root{
    flex-direction: row;
}
li.snapshot--list-item:not(:last-child){
    margin-bottom: 10px;
}
.snap-shot--inline-radio {
    flex-direction: row!important;
}

.snapshot-modal .massage_send_close_button .send_massage{
    display: none!important;
}

.no-conflict-message{
    line-height: 24px;
    color: rgba(19, 49, 89, 0.65);
    word-break: break-word;
}

.custom_svg_fill_wrpper svg path{
  fill:#8996AB;
}

.processing_wrapper_v2{
    font-size: 24px;
    text-align: center;
    height: calc(100vh - 335px);
    display: flex;
    justify-content: center;
    align-items: center;
}

