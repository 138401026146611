.ProfileModule-container-parent-class .call_log__wrapper {
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    color: var(--dark_blue) !important;
}


.ProfileModule-container-parent-class .call_log__wrapper .header__box .call_logs__list__title {
    padding: 16px 0;
    flex-shrink: 0;
}

.ProfileModule-container-parent-class .call_log__wrapper .call_log__filter__search__area {
    display: flex;
    justify-content: space-between;
    /*grid-gap: 16px;*/
    /*margin: 60px 0 6px 0;*/
}

.ProfileModule-container-parent-class .call_log__wrapper .call_log__filter__search__area{
    flex-direction: column-reverse;
}

.call_log__search__area .call_log__search{
    height: 39px !important;
    border-color: #ddd !important;
}

.call_log__table__wr{
    margin: 14px 0 4px 0;
    border-top: 1px solid#E5E8EF;
}

.call_log__table__wr .makeStyles-container-8::-webkit-scrollbar-track{
    -webkit-box-shadow : inset 0 0 6px rgba(0,0,0,0.3) !important;
    border-radius: 10px !important;
    background-color: var(--dark_blue) !important;
}


/*.ProfileModule-container-parent-class .call_log__wrapper .call_log__table tr.MuiTableRow-root td:nth-child(2){*/
/*    max-width: 10px;*/
/*}*/


.ProfileModule-container-parent-class .call_log__wrapper .call_log__table .chart__table__body .MuiTableCell-root{
    font-size: 14px;
    text-align: left;
}
.ProfileModule-container-parent-class .call_log__wrapper .call_log__table .report_recipient{
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.ProfileModule-container-parent-class .call_log__wrapper .chart__table__body__tr .report_recipient_top{
    font-size: 14px;
    font-weight: 400;
    word-break: break-word;
}
.ProfileModule-container-parent-class .call_log__wrapper .chart__table__body__tr .report_recipient_bottom{
    font-size: 13px;
    font-weight: 400;
    color: #039be5;
    white-space: normal !important;
    word-break: break-word;
    width: 65%;
    margin: 0 auto;
}

.ProfileModule-container-parent-class .call_log__wrapper .call_log__list__filter__area{
    display: flex;
    flex: 1;
    grid-gap: 16px;
    justify-content: space-between;
    align-items: center;
}

.ProfileModule-container-parent-class .call_log__wrapper .call_log__list__filter__area .list__settings_btn{
    flex-shrink: 0;
}


.ProfileModule-container-parent-class .call_log__wrapper .call_log__list__filter__area .filter__menu__area
{
    display: flex;
    grid-gap: 16px;
    align-items: center;

}

.ProfileModule-container-parent-class .call_log__wrapper .filter__menu__area .each__dropdown .MuiInputBase-root{
    min-width: 200px;
    border: 1px solid #ddd;
    border-radius: 4px;
}
.ProfileModule-container-parent-class .call_log__wrapper .filter__menu__area .each__dropdown .MuiInputBase-root .MuiSelect-root{
    border: 0 !important;
    width: 100%;
    max-width: 100%;
    font-size: 14px;
    color: var(--dark_blue) !important;
}

.ProfileModule-container-parent-class .call_log__wrapper .chart__table th {
    color: var(--dark_blue) !important;
}

.ProfileModule-container-parent-class .call_log__wrapper .chart__table__body td {
    color: var(--dark_blue) !important;
}

.ProfileModule-container-parent-class .call_log__wrapper .pagination_bar {
    padding: 10px 0 20px;
}
.ProfileModule-container-parent-class .call_log__wrapper .pagination_bar ul  {
    display: flex;
    align-items: center;
    justify-content: center;
}
.ProfileModule-container-parent-class .call_log__wrapper .pagination_bar ul li a {
    color:#fff;
    background: #3C7EF3;
    border-radius: 5px;
    padding: 6px 13px;
    margin-left:10px;
    display: block;
    border:2px solid #3C7EF3;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
}

.ProfileModule-container-parent-class .call_log__wrapper .pagination_bar ul li:nth-child(2) a {
    background: transparent;
    border:2px solid #3C7EF3;
    color:#3C7EF3;
}
.ProfileModule-container-parent-class .call_log__wrapper .pagination_bar ul li:nth-child(3) a {
    background: transparent;
    color:#3C7EF3;
    border: unset;
    padding: 0px 6px;
}
.ProfileModule-container-parent-class .call_log__wrapper .pagination_bar ul li:nth-child(3) a span {
    margin-right: 5px;
}

.ProfileModule-container-parent-class .call_log__wrapper .user__report__pagination__ul .active input{
    border: 2px solid #3c7ef3;
    width: 70px;
    height: 48px;
    border-radius: 4px;
    padding: 5px;
    margin-left: 12px;
    margin-bottom: 0!important
}

.ProfileModule-container-parent-class .call_log__wrapper .paginations_text{
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #133159;
}

.ProfileModule-container-parent-class .call_log__wrapper .call_logs__filter__btn{
    cursor: pointer;
}
.ProfileModule-container-parent-class .call_logs_select_wrapper_v2 .ppc__select_owner .second__step__area__select{
    width: 138px !important;
    height: 100% !important;
}

.ProfileModule-container-parent-class .call_logs_select_wrapper_v2 .ppc__select_owner .second__step__area__select .MuiSelect-selectMenu{
    overflow: hidden !important;
    height: auto !important;
    text-overflow: ellipsis !important;
    min-width: 88% !important;
    display: unset !important;
}

.ProfileModule-container-parent-class .call_logs_select_wrapper_v2 .second__step__area__select .MuiSelect-select{
    width: 100% !important;
    height:100% !important;
    padding-right: 28px;
    padding-left: 8px;

}
.ProfileModule-container-parent-class .call_logs_select_wrapper_v2 .dropdown-trigger{
    height:37px !important;
    width: 235px;
}
.ProfileModule-container-parent-class .call_logs_select_wrapper_v2 .filter__action_select{
    position: relative;
}
.ProfileModule-container-parent-class .call_logs_select_wrapper_v2 .filter__action_select .date_picker_reset{
    position: absolute;
    right: 10px;
    top: 9px;
    border: 1px solid var(--gray);
    display: flex;
    align-items: center;
    height: 20px;
    width: 20px;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
    z-index: 99;
}
.ProfileModule-container-parent-class .call_logs_select_wrapper_v2 .filter__action_select .date_picker_reset svg{
    width: 12px;
}
.ProfileModule-container-parent-class .call_logs_select_wrapper_v2 .filter__action_select .date_picker_reset svg path {
    fill: var(--gray);
}
.ProfileModule-container-parent-class .call_logs_select_wrapper_v2 .second__step__area__select .MuiSelect-select:focus{
    background-color: rgba(0, 0, 0,0.0) !important;
    height: unset !important;
    width: 156px !important;
}

.ProfileModule-container-parent-class .call_logs_select_wrapper_v2 .MuiInput-root {
    overflow: hidden;
}

.ProfileModule-container-parent-class #call_records header {
    display: none;
}

.ProfileModule-container-parent-class .call_log__wrapper .header__box {
    display: inline-flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    grid-gap: 60px;
}

.ProfileModule-container-parent-class .call_log__wrapper em{
    font-style: normal;
}

.ProfileModule-container-parent-class .call_logs__filter__btn {
    line-height: 10px;
}

.ProfileModule-container-parent-class .table-header-col {
    border: 3px solid #f6f7fb;
    font-size: 16px;
    color: #133159;
    font-weight: 500;
    position: relative;
    padding: 12px 20px 12px 10px;
}


.ProfileModule-container-parent-class .call__logs_full__details__wrapper {
    background-color: #fff;
    padding: 10px;
    border-radius: 10px;
    box-shadow: inset 0px 5px 0px var(--accent-color);
}

.call__logs_full__details__wrapper .list__content_table .list__content_table__body tr:last-child {
    border: none !important;
}

.call__logs_full__details__wrapper .list__content_table__row {
    display: flex;
    justify-content: space-between;
    column-gap: 4px;
    align-items: center;
}

.call__logs_full__details__wrapper .list__content_table__row.row_expend {
    background-color: #fff;
    padding: 0;
}

.call__logs_full__details__wrapper .list__content_table__header{
    margin-bottom: 4px;
}

.call__logs_full__details__wrapper .list__content_table__row:not(:first-child) {
    margin-top: 4px;
}

.call__logs_full__details__wrapper .list__content_table__header.list__content_table__row {
    font-weight: bold;
    color: rgb(84, 99, 118);
    font-size: 16px;
    line-height: 22px;
}

.call__logs_full__details__wrapper table th {
    color: rgb(84, 99, 118) !important;
    font-size: 16px;
    line-height: 22px;
}

.call__logs_full__details__wrapper table td {
    color: rgb(84, 99, 118) !important;
    font-size: 16px;
    line-height: 22px;
}

.call__logs_full__details__wrapper .list__content_table__col {
    padding: 20px;
    width: 100%;
}

.call__logs_full__details__wrapper .list__content_table__col:first-child {
    border-radius: 10px 0 0 10px;
}

.call__logs_full__details__wrapper .list__content_table__col:last-child {
    border-radius: 0 10px 10px 0;
}

.call__logs_full__details__wrapper .call__logs_full__detail__card__header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.call__logs_full__details__wrapper .call__logs_full__report__action__btn {
    background-color: var(--green2);
    color: var(--white);
    border-radius: 4px;
    padding: 5px 8px;
    display: inline-block;
    cursor: pointer;
}

.call__logs_full__details__wrapper .list__content_table_collapse_wrapper {
    width: 800px;
}

.call__logs_full__details__wrapper .list__content_table_collapse_wrapper .sub_user_outcome_row {
    display: inline-flex;
    gap: 8px;
    width: 100%;
    padding: 5px;
}
.call__logs_full__details__wrapper .list__content_table_collapse_wrapper .sub_user_outcome_row:nth-child(even) {
    background-color: #dfd0d024;
}

.call__logs_full__details__wrapper .list__content_table_collapse_wrapper .sub_user__outcome_list .sub_user_outcome_row:not(:last-child){
    margin-bottom: 4px;
}

.call__logs_full__details__wrapper .list__content_table_collapse_wrapper .sub_user_outcome_row .outcome__title {
    width: 60%;
    padding: 5px;
}

.call__logs_full__details__wrapper .list__content_table_collapse_wrapper .sub_user_outcome_row .outcome__count {
    width: 40%;
    text-align: center;
    padding: 5px;
    border-radius: 4px;
    border: 1px inset #ffffff4a;
    background: #fff;
}

.call__logs_full__details__wrapper .list__content_table_collapse_wrapper .sub_user_outcome_row:nth-child(odd){
    border: 1px solid #dfd0d024;
}

.call__logs_full__details__wrapper .list__content_table_collapse_wrapper .sub_user_outcome_row:nth-child(odd) .outcome__count {
    background-color: #dfd0d024 !important;
}

.call__logs_full__details__wrapper .list__content_table_collapse_wrapper .sub_user_outcome_title {
    text-align: center;
    padding: 10px;
    font-size: 20px;
    border: 1px solid #95707012;
}

.call__logs_full__details__wrapper .list__content_table_collapse_wrapper:after {
    content: "";
    display: table;
    clear: both;
}
.call__logs_full__details__wrapper .list__content_table_collapse_wrapper .sub_user__chart__view__row{
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(180px,1fr));
    grid-gap: 20px;
}

.call__logs_full__details__wrapper .list__content_table_collapse_wrapper .sub_user__chart__view__row .sub_user__report__card__each__item__value {
    font-family: var(--poppins);
    color: var(--dark_blue);
    font-size: 40px;
    font-weight: 700;
    line-height: 60px;
    letter-spacing: 0px;
}

.call__logs_full__details__wrapper .list__content_table_collapse_wrapper .sub_user__report__card__each__item__container {
    background: var(--white);
    border: 1px solid var(--border_general);
    padding: 8px;
    border-radius: 6px;
    text-align: center;
    cursor: pointer;
}

.call__logs_full__details__wrapper .list__content_table_collapse_wrapper .list__box {
    float: left;
    width: 50%;
    padding: 10px;
    color: var(--dark_blue);
}

.call__logs_full__details__wrapper .call__logs_full__report__action__btn svg {
    fill: #fff;
}

.call__logs_full__details__wrapper .call__logs_full__detail__card__header .filter__action_button {
    background: transparent !important;
    border: none;
    cursor: pointer;
}

.call__logs_full__details__wrapper .call__logs_full__detail__card__header .filter__action_button.date_picker_reset {
    display: inline-flex;
}

.call__logs_full__details__wrapper .call__logs_full__detail__card__header .filter__action_select {
    display: inline-flex;
    align-items: center;
}

/*.call__logs_full__details__wrapper .call__logs_full__detail__card__header .custom-select-arrow::after {*/
/*    top: 24px;*/
/*    right: 17px;*/
/*}*/

.call__log_datepicker.outcome-filter {
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%), 0 1px 5px 0 rgb(0 0 0 / 20%);
    border-radius: 5px;
    overflow: hidden;

    position: absolute;
    z-index: 9;
    display: flex;
    right: 35px;
    top: 50px;
}

.custom-input .rdrInputRanges .rdrInputRange input {
    width: 46px;
    padding-left: 0 !important;
    margin-right: 20px !important;
}

.call__log_datepicker.log__filter {
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%), 0 1px 5px 0 rgb(0 0 0 / 20%);
    border-radius: 5px;
    overflow: hidden;

    position: absolute;
    z-index: 9;
    display: flex;
    right: 0;
    top: 50px;
}


/* new update css start :: 02-08-2022 */
.ProfileModule-container-parent-class .call_logs_select_wrapper_v2 .call__log_datepicker_extra {
    padding: 17px;
    margin: 0 auto;
    z-index: 9993;
    background: #E5E8EF;
    border-radius: 5px;
}
.ProfileModule-container-parent-class .call_logs_select_wrapper_v2 .call__log_datepicker_extra .select-wrapper{
    position: relative;
}
.ProfileModule-container-parent-class .call_logs_select_wrapper_v2 .call__log_datepicker_extra .date__action_button{
    position: absolute;
    z-index: 1;
    right: -20px;
    top: -18px;
    cursor: pointer;
}
.ProfileModule-container-parent-class .call_logs_select_wrapper_v2 .call__log_datepicker{
    padding: 17px;
    background: #E5E8EF;
}

.ProfileModule-container-parent-class .cal_log_details_wrapper{
    /* background: #fff; */
    padding: 20px 0;
    border-radius: 10px;
}

.ProfileModule-container-parent-class .cal_log_details_wrapper .cal_log_details_container_left{
    height: calc(100vh - 202px);
}





.ProfileModule-container-parent-class .cal_log_details_wrapper .cal_log_details_heading{
    display: flex;
    justify-content: space-between;
    align-items: center;
    grid-gap: 16px;
    padding: 0 0 20px 0;
}
.ProfileModule-container-parent-class .cal_log_details_wrapper .cal_log_details_title{
    margin: 0;
    display: flex;
    align-items: center;
    grid-gap: 16px;
}
.ProfileModule-container-parent-class .cal_log_details_wrapper .cal_log_details_heading_back_btn{
    background: #bec3c9;
    padding: 7px 14px;
    font-size: 14px;
    border-radius: 5px;
    cursor: pointer;

    display: inline-flex;
    align-items: center;
    gap: 9px;
}
.ProfileModule-container-parent-class .cal_log_details_wrapper .cal_log_details_title h5{
    margin: 0;
    color: var(--dark_blue);
}
.ProfileModule-container-parent-class .cal_log_details_wrapper .cal_log_details_container{
    display: flex;
    grid-gap: 16px;
}

.ProfileModule-container-parent-class .cal_log_details_container .cal_log_details_container_left{
    width: 40%;
    background: #fff;
    border-radius: 10px;
    padding: 16px 0;
}

.ProfileModule-container-parent-class .cal_log_details_wrapper .cal_log_dl_single{
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #ddd;
    padding: 14px 16px;
    align-items: center;
    cursor: pointer;
}
.ProfileModule-container-parent-class .cal_log_details_wrapper .cal_log_dl_single.cal_log_dl_single_active{
    background: rgba(60,126,243,.1);
}

.ProfileModule-container-parent-class .cal_log_details_wrapper .cal_log_dl_single_active .cal_log_dl_single_arrow svg{
    color: var(--blue-3)
}

.ProfileModule-container-parent-class .cal_log_details_wrapper .cal_log_dl_single:last-child{
    border-bottom: 0 !important;
}
.ProfileModule-container-parent-class .cal_log_details_wrapper .cal_log_dl_single_title{
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    color: #434446;
    word-break: break-word;
}
.ProfileModule-container-parent-class .cal_log_details_wrapper .cal_log_dl_single_outgoing{
    font-size: 14px;
    font-weight: 400;
    color: #434446;
    line-height: 20px;
    margin: 4px 0 0;
    word-break: break-word;
    color: var(--blue-2);
}
.ProfileModule-container-parent-class .cal_log_details_wrapper .cal_log_dl_single_arrow svg {
    color: var(--gray);
    width: 28px;
}
.ProfileModule-container-parent-class .cal_log_details_wrapper .cal_log_dl_single:hover{
    background: rgba(60,126,243,.1);
}
.ProfileModule-container-parent-class .cal_log_details_wrapper .cal_log_dl_single:hover .cal_log_dl_single_arrow svg{
    color: var(--blue-3)
}

/* right side */
.ProfileModule-container-parent-class .cal_log_details_wrapper .cal_log_details_top_right{
    position: relative;
    display: flex;
    grid-gap: 8px;
    justify-content: flex-end;
}
.ProfileModule-container-parent-class .cal_log_details_wrapper .cal_log_details_top_right .call__log_datepicker{
    position: absolute;
    right: 0;
}
.ProfileModule-container-parent-class .cal_log_details_wrapper .cal_log_details_top_date_close_btn{
    position: absolute;
    z-index: 1;
    right: -20px;
    top: -18px;
    cursor: pointer;
}
.ProfileModule-container-parent-class .cal_log_details_wrapper .cal_log_details_top_right .call_logs_select_wrapper_v2{
    flex-basis: 196px;
    width: 196px;
}
.ProfileModule-container-parent-class .cal_log_details_wrapper .cal_log_details_top_right .cal_log_details_top_date{
    width: 49%;
}
.ProfileModule-container-parent-class .cal_log_details_wrapper .cal_log_details_top_right .call_log_select_parent_v2 .MuiSelect-select{
    padding: 23px 6px 7px 6px !important;
}
.ProfileModule-container-parent-class .cal_log_details_wrapper .cal_log_details_top_right .call_log_select_parent_v2 .MuiSelect-select:focus{
    width: unset !important;
    padding: 9px 6px 7px 6px !important;
}


.ProfileModule-container-parent-class .cal_log_details_wrapper .cal_log_details_top_date .filter__action_select{
    position: relative;
    width: unset !important;
}
.ProfileModule-container-parent-class .cal_log_details_wrapper .cal_log_details_top_date .date_picker_reset{
    position: absolute;
    right: 10px;
    top: 9px;
    border: 1px solid var(--gray);
    display: flex;
    align-items: center;
    height: 25px;
    width: 25px;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer
}
.ProfileModule-container-parent-class .cal_log_details_wrapper .text_align_left_v2{
    text-align: left;
}
.ProfileModule-container-parent-class .cal_log_details_wrapper .text_align_center_v2{
    text-align: center;
}

.ProfileModule-container-parent-class .cal_log_details_wrapper .cal_log_details_top_date .date_picker_reset svg{
    width: 14px;
}
.ProfileModule-container-parent-class .cal_log_details_wrapper .cal_log_details_top_date .date_picker_reset svg path{
    fill: var(--gray)
}

.ProfileModule-container-parent-class .cal_log_details_wrapper .call__log_datepicker .select-wrapper{
    position: relative;
}
.ProfileModule-container-parent-class .cal_log_details_container .cal_log_details_container_right{
    width: 60%;
    background: #fff;
    border-radius: 10px;
    padding: 16px;
    
    height: calc(100vh - 202px);
}
.ProfileModule-container-parent-class .cal_log_details_container .cal_log_details_container_right_grid{
    display: grid;
    grid-gap: 12px;
    grid-template-columns: repeat( auto-fill, minmax(198px, 1fr) );
    align-content: flex-start;
}
.ProfileModule-container-parent-class .cal_log_details_wrapper .cal_log_dr_single{
    border: 1px solid #ddd;
    padding: 30px;
    text-align: center;
    border-radius: 4px;
    background-color: aliceblue;
}
.ProfileModule-container-parent-class .cal_log_details_wrapper .cal_log_dr_single_top_title{
    margin: 0 0 8px 0;
    font-size: 16px;
    color: darkgreen;
}
.ProfileModule-container-parent-class .cal_log_details_wrapper .cal_log_dr_single_bottom_count{
    margin: 0;
    font-size: 26px;
    color: darkgreen;
}
.cal_log_details_wrapper .cal_log_details_top_right .call__log_datepicker{
    position: absolute;
    right: 0;
    top: 50px;
    padding: 17px;
    margin: 0 auto;
    z-index: 9993;
    background: #E5E8EF;
    border-radius: 5px;
}
.ProfileModule-container-parent-class .cal_log_details_wrapper .cal_log_right_empty_container{
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    font-size: 14px;
    color: var(--gray);
}
.ProfileModule-container-parent-class .cal_log_details_wrapper .cal_log_right_empty_container span{
    display: flex;
    align-items: center;
    justify-content: center;
}

.ProfileModule-container-parent-class .call_log__wrapper .call_log_display_name{
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: rgb(84, 99, 118);
    margin-left: 4px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.ProfileModule-container-parent-class .call_log__wrapper .call_log_display_icon{
    margin-right: 5px;
}
.ProfileModule-container-parent-class .call_log__wrapper .call_log_display_icon svg{
    width: 9px !important;
}
.ProfileModule-container-parent-class .call_log__wrapper .log_contact_number{
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 22px;
    color: rgb(84, 99, 118);
    margin-left: 4px;
    white-space: normal !important;
}
.ProfileModule-container-parent-class .call_log__wrapper .log_contact_email_email{
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: rgb(84, 99, 118);
    margin-left: 4px;
}
.ProfileModule-container-parent-class .call_log__wrapper  .log_global_parent_div .rdt_TableRow>div{
    overflow: hidden;
}



@media screen and (max-width:1292px) {
    
    .ProfileModule-container-parent-class .call_log__wrapper .header__box{
        flex-direction: column;
        grid-gap: 7px;
        align-items: flex-start;
    }
    .ProfileModule-container-parent-class .call_log__wrapper .call_log__list__filter__area{
        margin-left: -16px;
        margin-bottom: 20px;
    }
    .ProfileModule-container-parent-class .cal_log_details_wrapper .cal_log_details_top_right .cal_log_details_top_date{
        width: 50%;
    }
}


@media screen and (max-width:991px) {
    
    .ProfileModule-container-parent-class .call_log__wrapper .call_log__list__filter__area{
        justify-content: center;
        flex-wrap: wrap;
    }
    .ProfileModule-container-parent-class .call_log__wrapper .call_log__list__filter__area .filter__menu__area{
        flex-wrap: wrap;
        margin-left: 15px;
        margin-top: 10px;
    }
    .ProfileModule-container-parent-class .call_logs_select_wrapper_v2 .ppc__select_owner .second__step__area__select{
        width: 100% !important;
       
    }
}