.mainWrp{
  width: 100%;
  height: 100%;
  display: flex;
  flex-grow: 1;
  overflow: hidden;
  flex-direction: column;
}
.headerWrp{
  width: 100%;
  height: 67px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 10px;
  box-sizing: border-box;
  border-bottom: 1px solid #ddd;
}
.title{
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0.5px;
}
.closeButton{
  cursor: pointer;
  padding: 5px;
}
.closeButton:hover svg {
  fill: #ff0000;
}
.bodyWrp{
  width: 100%;
  height: calc(100% - 142px);
  overflow: hidden;
  overflow-y: auto;
  padding: 10px 16px;
  box-sizing: border-box;
}
.inputWrp{
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 10px;
}
.contentBlock{
  display: flex;
  flex-wrap: wrap;
}
.fieldTypeWrp{
  padding: 5px 10px;
  align-self: center;
  text-align: center;
  justify-content: center;
  background-color: #ddd;
  border-radius: 4px;
  cursor: pointer;
  width: fit-content;
  height: fit-content;
  margin: 5px;
  position: relative;
}
.selected{
  background-color: #3c7ef3!important;
  color: #fff;
  cursor: auto;
}
.lebelText{
  color: #133159;
  font-size: 14px;
  font-weight: 600;
}
.inputField{
  width: 100%;
  height: 40px !important;
  padding: 3px 10px !important;
  box-sizing: border-box !important;
  border: 1px solid #ddd !important;
  border-radius: 4px !important;
  font-size: 14px !important;
  font-weight: 500;
  margin: 0px !important;
}
.inputField:focus{
  border-bottom: 1px solid #ddd !important;
  box-shadow: unset !important
}
.hintText{
  font-size: 11px;
  font-weight: normal;
  letter-spacing: 0.3px;
  color: #9a9696;
}
.buttonWrp{
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.button{
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 10px 20px;
  border-radius: 7px;
  background-color: #3c7ef3;
}
.submitText{
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.6px;
  color: #fff;
}
.submitIcon{
  display: inline-flex;
}
.footerWrp{
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 10px;
  box-sizing: border-box;
  border-top: 1px solid #ddd;
}
.new{
  position: absolute;
  z-index: 1;
  top: -7px;
  right: -7px;
  background-color: red;
  color: #fff;
  padding: 2px;
  border-radius: 2px;
  font-size: 8px;
}

.error{
  color: var(--red);
  font-size: 12px;
}

.disabledButton {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  cursor: not-allowed;
  padding: 10px 20px;
  border-radius: 7px;
  background-color: #888; 
  opacity: 0.6;
}