.modalWrapper {
    display: grid;
    place-content: center;
}

.headerWrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 16px 24px;
    border-bottom: 1px solid rgba(148, 157, 178, 0.24);
}

.headerClose {
    position: absolute;
    right: 10px;
    top: 10px;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    display: grid;
    place-content: center;
    cursor: pointer;
}

.headerClose:hover {
    background-color: rgba(237, 0, 57, 0.03);
}

.headerClose svg path {
    color: red;
}

.modalInner {
    width: 590px;
    height: fit-content;
    max-height: calc(100vh - 60px);
    overflow-y: auto;
}

.modalInner:focus-visible {
    border: none !important;
    box-shadow: none !important;
    outline: none !important;
}

.modalInner::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

.modalInner::-webkit-scrollbar-track {
    background: rgba(60, 126, 243, 0.1);
    border-radius: 4px;
}

.modalInner::-webkit-scrollbar {
    scrollbar-width: thin;
}

.modalInner::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #949db2;
    box-sizing: border-box;
}

.modalInner::-webkit-scrollbar-track {
    background: #f4f5f7;
    box-shadow: inset 1px 0px 0px rgba(148, 157, 178, 0.12);
    -webkit-box-shadow: inset 1px 0px 0px rgba(148, 157, 178, 0.12);
}

.paddingWrp {
    padding: 20px 40px;
}

.modalBody {

}

.modalFooter {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 25px;
    gap: 15px;
}

.modalBackdrop {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    background-color: #00000008;
}

.submitButton {
    color: #fff !important;
    background-color: #006df5 !important;
    width: 190px;
    font-size: 18px !important;
    font-weight: 600 !important;
    border-radius: 7px !important;
    text-transform: capitalize !important;
}

.cancelButton {
    padding: 10px 20px;
    color: #f3eeef !important;
    background-color: #d53d3d !important;
    width: 190px;
    font-size: 18px !important;
    font-weight: 600 !important;
    border-radius: 7px !important;
    margin-left: 10px !important;
    text-transform: capitalize !important;
}
.headerSubTitle {
    text-align: center;
    margin-top: 10px;
}

.mmTexts {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 35px;
    margin-top: 30px;
}

.mmSearch {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.bodyTitle{
    text-align: center;
    font-size: 24px !important;
    font-weight: 700 !important;
    letter-spacing: 0.7px !important;
    color: #133159;
}
.descriptionText{
    font-size: 14px !important;
    font-weight: 500;
    letter-spacing: 0.5px;
    color: #133159;
    text-align: center;
}