.pageWrapper {
    padding: 10px 20px 10px 10px;
    background: #fff;
    border-radius: 10px;
}

.pageHeader {
    padding: 6px;
}

.pageTitle {
    font-size: 18px;
    font-weight: 600;
}

.pageSubtitle {
    margin-top: 5px !important;
    font-size: 12px;
}

.backButton {
    position: absolute;
    right: 0;
    top: 20px;
}

.pageHints {
    line-height: 35px;
    margin-left: 10px;
}

.itemJustify {
    display: flex;
    justify-content: space-between;
}

.pageHint {
    font-size: 16px;
    color: #3a414a;
    display: flex;
    align-items: center;
}

.pageHint svg {
    margin-right: 10px;
}

.pageBody{
    width: 100%;
}

.pageHintIcon {
    width: 20px !important;
    height: 20px !important;
    color: #d97070 !important;
    background-color: #e7d8c3 !important;
    margin-right: 5px !important;
}

.neutralButton {
    color: #fff !important;
    background-color: #7581d5 !important;
    width: 100%;
}

.neutralButton:focus {
    background-color: #7581d5 !important;
}

.loaderPage {
  margin-top: 100px;
  min-height: calc(100vh - 120px);
}

.tableTable {
    width: 40%;
}

.tableTrTh {
    height: 60px;
    font-size: 14px;
}

.tableTh {
    border-spacing: 0;
    border-collapse: collapse;
    display: table-cell;
    text-align: left;
    vertical-align: middle;
    border: 1px solid #fff;
    line-height: 30px !important;
    position: relative;
    background: #F6F7FB;
    border-right: 2px solid #FFFFFF;
    color: var(--dark_blue);
    font-weight: normal;
    box-sizing: border-box;
    white-space: nowrap;
    padding: 0px 10px;
    font-size: 14px!important;
    width: 13%;
}

.tableThLabel {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.tableThLabel svg {
    cursor: pointer;
}

.itemCenter {
    display: flex;
    justify-content: center;
}

.itemAlignCenter {
    display: flex;
    align-items: center;
}

.girBans {
    width: fit-content;
    padding: 5px 15px;
    background-color: #f5f0e8;
    border-radius: 15px;
}

.successIcon {
    height: 22px;
    width: 22px;
    color: #008000;
}

.infoIcon {
    height: 22px;
    width: 22px;
    color: #777B7E;
}

.problemIcon {
    height: 22px;
    width: 22px;
    color: #DC143C;
}

.alertLabel {
    font-size: 15px;
    padding-left: 0.25rem!important;
}

.resubmitShaken {
    padding: 5px;
    background-color: #7575c7;
    color: #fff;
    margin-left: 8px;
    border-radius: 4px;
    cursor: pointer;
}

.defaultIcon {
    background-color: #80ffd4;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 23px;
    height: 23px;
    color: #8b8422;
}

.helpDocButton {
    display: flex;
    align-items: center;
    gap: 7px;
    cursor: pointer;
    color: #496860;
}

.alertMessage {
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 20px;
    background-color: #e30d0d;
    border-radius: 4px;
    color: #fff;
}