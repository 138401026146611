#not-found-alert {
    display: grid;
    overflow: hidden;
    font-family: "Lato", sans-serif;
    text-transform: uppercase;
    text-align: center;
    position: relative;
    margin: auto;
    width: 700px;
    height: 250px;
}

#not-found-alert #error-box {
    position: absolute;
    width: 80%;
    height: 60%;
    right: 12%;
    background: linear-gradient(to bottom left, #ef8d9c 40%, #ffc39e 100%);
    border-radius: 20px;
    box-shadow: 5px 5px 20px #cbcdd3;
}

#not-found-alert .dot {
    width: 8px;
    height: 8px;
    background: #fcfcfc;
    border-radius: 50%;
    position: absolute;
    top: 4%;
    right: 8%;
}

#not-found-alert .two {
    right: 12%;
    opacity: .5;
}

#not-found-alert .face {
    position: absolute;
    width: 7%;
    height: 26%;
    background: #fcfcfc;
    border-radius: 50%;
    border: 1px solid #777;
    top: 12%;
    left: 37.5%;
    z-index: 2;
    animation: roll 3s ease-in-out infinite;
}

#not-found-alert .eye {
    position: absolute;
    width: 5px;
    height: 5px;
    background: #777;
    border-radius: 50%;
    top: 40%;
    left: 20%;
}

#not-found-alert .right {
    left: 68%;
}

#not-found-alert .sad {
    top: 49%;
    border: 2px solid;
    border-color: #777 transparent transparent #777;
    transform: rotate(
        45deg
    );
}

#not-found-alert .mouth {
    position: absolute;
    left: 41%;
    width: 7px;
    height: 7px;
    border-radius: 50%;
}

#not-found-alert .shadow {
    position: absolute;
    width: 21%;
    height: 3%;
    opacity: .5;
    background: #777;
    left: 40%;
    top: 43%;
    border-radius: 50%;
    z-index: 1;
}

#not-found-alert .move {
    animation: move 3s ease-in-out infinite;
}

#not-found-alert .message {
    position: absolute;
    width: 100%;
    text-align: center;
    height: 40%;
    top: 47%;
}

#not-found-alert h3 {
    font-size: .9em;
    font-weight: 100;
    letter-spacing: 3px;
    padding-top: 5px;
    color: #fcfcfc;
    padding-bottom: 5px;
    text-transform: uppercase;
}

@keyframes roll {
    0% {
        transform: rotate(0deg);
        left: 25%
    }
    50% {
        left: 60%;
        transform: rotate(168deg)
    }
    100% {
        transform: rotate(0deg);
        left: 25%
    }
}

@keyframes move {
    0% {
        left: 25%
    }
    50% {
        left: 60%
    }
    100% {
        left: 25%
    }
}