.main__content {
    width: 100%;
}

.settings_section1 {
    min-height: calc(100vh - 120px);
     background: #fff;
     padding: 16px 8px;
    border-radius: 10px;
}

.settings_section1 .tabs {
     border-bottom: 1px solid #E5E8EF;
    height: 100% !important;
    display: flex;
    gap: 10px;
    margin-top: 0 !important;
}

.settings_section1 .tab {
    /*border: 1px solid #E5E8EF;*/
    padding: 8px 12px !important;
    border-top: none;
    border-bottom: 2px solid transparent;
    display: inline-block;
}

.settings_section1 .tab a {
    height: max-content !important;
    font-weight: 400;
    font-size: 14px;
    text-transform: uppercase;
    color: rgba(19, 49, 89, 0.65);
}

.settings_section1 .tab.active a {
    /* background: none !important;
    color: #3C7EF3;
    border-bottom: 2px solid #3C7EF3; */
    color: #3C7EF3 !important;
    /*text-decoration: underline;*/
}
.settings_section1 .tab.active {
    border-bottom: 2px solid #3C7EF3;
}

.settings_content {
    padding: 15px 25px;
}

.tox-tinymce {
    border: 1px solid #ddd !important;
    height: 60vh !important;
}

.email__signature_button {
    padding: 6px 16px;
    border-radius: 5px;
}

.tox .tox-toolbar-overlord {
    width: 100% !important;
}

/* general setting */
.color_plate {
    padding: 0 25px 0 0;
}

.color_plate input:checked+.slider:before {
    left: -4px;
}

.color_plate .switch {
    display: flex;
    width: 56px;
}

.color_plate li h5 {
    font-size: 16px;
    margin: 0;
}

.color_plate li {
    justify-content: space-between;
    align-items: center;
    display: flex;
    font-size: 16px;
    color: #133159;
    margin-bottom: 25px;
}

.color_plate li .color_option {
    justify-content: space-between;
    align-items: center;
    display: flex;
    min-width: 110px;
    margin-left: 15px;
}

.color_plate input {
    border: 2px solid #3C7EF3;
    padding: 0;
    height: 32px;
    width: 74px;
    position: relative;
    border-radius: 4px;
}

.color_plate input[type="color"]::after {
    display: inline-block;
    content: '';
    background-color: inherit;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.color_plate li .color_option svg {
    margin-left: 15px;
}

.theme_plate {
    padding-left: 5%;
}

.theme_plate h5 {
    margin-top: 10px;
    color: #133159;
    font-size: 20px;
}

.theme_option {
    flex-wrap: wrap;
    display: flex;
}

.theme_color {
    margin: 0 20px 35px 0;
    cursor: pointer;
}

.theme_color svg {
    margin-bottom: 10px;
    display: block;
}

.theme_plate label span {
    color: rgba(19, 49, 89, 0.65);
    padding-left: 25px;
    font-size: 16px;
}

.theme_plate label {
    display: inline-block;
    margin-top: 8px;
}

.settings_section1 [type="radio"]:not(:checked)+span:before,
.settings_section1 [type="radio"]:not(:checked)+span:after {
    border: 1px solid #133159;
}

.settings_section1 [type="radio"]:checked+span:after {
    background: #3C7EF3;
}

.font_option {
    flex-wrap: wrap;
    display: flex;
}

.settings_section1 [type="radio"]:checked+span:before,
.settings_section1 [type="radio"]:checked+span:after {
    border: 2px solid #3C7EF3;
}

.font_select {
    margin: 10px 20px 15px 0;
}

.font_choice {
    background: #F6F7FB;
    border-radius: 5px;
    width: 114px;
    padding: 14px 16px;
}

.font_choice h6 {
    line-height: 30px;
    color: #133159;
    font-size: 16px;
    margin: 0;
}

.font_choice p {
    color: rgba(19, 49, 89, 0.65);
    line-height: 24px;
    font-size: 14px;
    margin: 0;
}

/* cut time */
#cut_time p {
    margin-bottom: 0;
    color: #133159;
    font-size: 16px;
    line-height: 30px;
}

#cut_time p.msg {
    color: rgba(19, 49, 89, 0.65);
    font-size: 14px;
    margin: 15px 0 45px;
    line-height: 24px;
}

#cut_time p.msg span {
    color: #FF264A;
}

#cut_time p svg {
    vertical-align: middle;
    margin-right: 5px;
    margin-top: -3px;
}

.time_input {
    justify-content: space-between;
    align-items: center;
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    margin-top: 15px;
    max-width: 800px;
    position: relative;
}

.time_input>label,
#cut_time h5 {
    margin-bottom: 15px;
    line-height: 24px;
    font-size: 16px;
    overflow: hidden;
    min-width: 400px;
    color: #133159;
    margin-right: 15px;
    white-space: nowrap;
    text-overflow: ellipsis;
}

#cut_time .form_group {
    max-width: 375px;
    width: 100%;
    padding: 0;
    position: relative;
}

#cut_time .time_clock {
    background: #667993;
    margin: 0 !important;
    position: absolute;
    right: 0;
    top: 1px;
    width: 51.73px;
    height: 50px;
    line-height: 67px;
    text-align: center;
}

#cut_time .personal_info_text_field {
    color: rgba(19, 49, 89, 0.65);
    width: calc(100% - 25px);
    font-size: 16px;
    margin: 0;
    height: 50px;
    line-height: 50px;
    padding: 0 12px;
    border-radius: 4px;
    border: 1px solid #667993;
    background: #ffffff !important;
}

.settings_section1 #cut_time hr {
    border-bottom: none;
    margin: 25px 0;
    border-top: 1px solid #E5E8EF;
}

#cut_time .form_group label {
    margin: 11px 0;
    display: block;
}

#cut_time .form_group label span {
    line-height: 24px;
    font-size: 16px;
    color: #133159;
}

.settings_section1 #cut_time [type="checkbox"].filled-in:not(:checked)+span:not(.lever):after {
    border: 1px solid #3C7EF3;
}

.settings_section1 #cut_time [type="checkbox"].filled-in:checked+span:not(.lever):after {
    border: 2px solid #3C7EF3;
    background: #3C7EF3;
}

.settings_section1 #cut_time [type="checkbox"].filled-in:not(:checked)+span:not(.lever):after,
.settings_section1 #cut_time [type="checkbox"].filled-in:checked+span:not(.lever):after {
    top: 2px;
}

.settings_section1 #cut_time [type="checkbox"].filled-in:checked+span:not(.lever):before {
    top: 1px;
}

#cut_time .btn_submit:hover {
    background: #68EBCA;
}

#cut_time .btn_submit {
    display: inline-block;
    background: #3C7EF3;
    border-radius: 4px;
    padding: 0 20px;
    font-size: 16px;
    border: none;
    height: 50px;
    color: #fff;
    cursor: pointer;
    min-width: 217px;
    font-weight: 500;
    line-height: 50px;
    text-align: center;
    transition: all .2s;
    font-family: var(--poppins);
}

/* timezone */
#timezone p {
    color: rgba(19, 49, 89, 0.65);
    line-height: 30px;
    font-size: 16px;
}

#timezone p.alart {
    font-size: 14px;
    color: #FF264A;
    line-height: 24px;
}

#timezone p img {
    vertical-align: middle;
    margin-right: 5px;
    margin-top: -3px;
}

.selet_input {
    align-items: center;
    display: flex;
    width: 100%;
    margin-top: 25px;
    max-width: 420px;
}

.selet_input label {
    line-height: 24px;
    font-size: 16px;
    color: #133159;
    margin-right: 15px;
}

.selet_input .time_select {
    position: relative;
    width: 316px;
}

.selet_input .time_select svg {
    position: absolute;
    right: 8px;
    z-index: 999;
    height: 49px;
    top: 1px;
    width: 12px;
    background: #fff;
}

.selet_input .select-wrapper input {
    border-bottom: none;
    padding: 0 12px;
    height: 50px;
    margin: 0;
    border-bottom: none;
}

.selet_input .select-dropdown {
    width: 100% !important;
}

.selet_input .select-wrapper,
.selet_input select {
    border: 1px solid rgba(19, 49, 89, 0.35);
    color: rgba(19, 49, 89, 0.65);
    font-size: 16px;
    width: 100%;
    max-width: 325px;
    border-radius: 5px;
}

/* notification  table */
.ProfileModule-container-parent-class .switch .on {
    color: var(--accent-text-color) !important;
}

.settings_section1 table thead {
    background: #E5E8EF;
}

.settings_section1 table {
    border-radius: 10px;
    overflow: hidden;
}

.settings_section1 table tbody tr:last-child {
    border: none;
}

.notification_table a {
    line-height: 24px;
    font-size: 14px;
    display: block;
    color: #3C7EF3;
}

.notification_table th svg,
.notification_table td img {
    vertical-align: middle;
    margin: 0 5px;
    width: 20px;
}

.switch {
    display: inline-block;
    position: relative;
    width: 66px;
    height: 30px;
    cursor: pointer;
}

.settings_section1 [type="checkbox"]:checked+span+small {
    display: none;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    transition: .4s;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
}

.slider:before {
    position: absolute;
    content: "";
    height: 24px;
    width: 24px;
    left: 4px;
    bottom: 3px;
    transition: .4s;
    background-color: #fff;
}

.settings_section1 input:checked+.slider {
    background-color: #2196F3;
}

.settings_section1 input:checked+.slider:before {
    transform: translateX(34px);
    border: none;
    top: 0px;
    left: 5px;
    width: 24px;
    height: 24px;
    background: #fff;
}

.slider.round {
    border-radius: 34px;
    height: 30px;
    background: #E5E8EF;
}

.slider.round:before {
    border-radius: 50%;
    border: none;
    top: 1px;
    left: 5px;
    width: 22px;
    z-index: 99;
    height: 22px;
    background: #9CA8BA;
}

.switch .on {
    color: #fff;
    left: 4px;
}

.switch .on,
.switch .off {
    line-height: 32px;
    position: absolute;
    height: 30px;
    top: 0;
    z-index: 9;
    font-size: 14px;
    text-transform: uppercase;
}

.ProfileModule-container-parent-class .switch .on {
    left: 5px;
}



.switch .off {
    color: #9CA8BA;
    right: 5px;
}

.settings_section1 table th.text-center,
.settings_section1 table td.text-center {
    text-align: center;
}

.settings_section1 table th {
    border: 1px solid #fff;
    white-space: nowrap;
    position: relative;
    font-size: 20px !important;
    line-height: 30px !important;
}

.settings_section1 table th,
.settings_section1 table td {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
}

/* calloutcome */
.settings_section1 #call_outcome .heading {
    border-bottom: 1px solid #E5E8EF;
    align-items: center;
    padding: 5px 0 20px;
    display: flex;
    justify-content: space-between;
}

.settings_section1 #call_outcome .heading h5 {
    line-height: 30px;
    font-size: 16px;
    margin: 0 15px 0 0;
}

#call_outcome .heading h5 span {
    color: rgba(19, 49, 89, 0.65);
}

#call_outcome .btn_submit:hover {
    background: #68EBCA;
}

#call_outcome .btn_submit {
    display: inline-block;
    background: #3C7EF3;
    border-radius: 5px;
    padding: 0 10px;
    font-size: 16px;
    border: none;
    height: 50px;
    color: #fff;
    cursor: pointer;
    min-width: 243px;
    font-weight: 500;
    line-height: 50px;
    text-align: center;
    transition: all .2s;
    font-family: var(--poppins);
}

#call_outcome .btn_submit svg {
    margin: -5px 6px 0 0;
    vertical-align: middle;
}

#call_outcome .search_form {
    justify-content: space-between;
    display: flex;
    margin: 20px 0;
}

#call_outcome .form_group {
    align-items: center;
    display: flex;
}

#call_outcome .form_group.search {
    border: 1px solid rgba(19, 49, 89, 0.35);
    border-radius: 5px;
    border-radius: 5px;
    max-width: 245px;
    width: 100%;
}

#call_outcome .form_group label {
    line-height: 30px;
    color: #133159;
    font-size: 16px;
}

#call_outcome .select-wrapper input {
    border-bottom: none;
    padding: 0 12px;
    margin: 0;
    border-bottom: none;
}

#call_outcome .select-dropdown {
    width: 100% !important;
}

#call_outcome .select-wrapper,
#call_outcome select {
    border: 1px solid rgba(19, 49, 89, 0.35);
    color: rgba(19, 49, 89, 0.65);
    font-size: 16px;
    width: 65px;
    margin: 0 10px;
    border-radius: 5px;
}

#call_outcome .select-wrapper+label {
    position: inherit;
}

#call_outcome .form_group.search input:focus {
    outline: none;
    outline: none;
    border: none;
}

#call_outcome .form_group.search input {
    padding: 0 0 0 15px;
    border: none;
    margin: 0;
}

#call_outcome .form_group.search button {
    padding-right: 12px;
    background: none;
    cursor: pointer;
    width: 42px;
    border: none;
    outline: none;
}

.data_table th svg {
    position: absolute;
    right: 15px;
    top: 18px;
}

.data_table th,
.data_table td {
    padding-left: 10%;
}

.data_table th:last-child,
.data_table td:last-child {
    padding-left: 24%;
}

#call_outcome td {
    color: rgba(19, 49, 89, 0.65)
}

#call_outcome .delete {
    color: #FF264A;
}

#call_outcome .edit {
    color: #133159;
}

#call_outcome .delete,
#call_outcome .edit {
    border: 1px solid #E5E8EF;
    display: inline-block;
    border-radius: 5px;
    font-weight: 500;
    font-size: 16px;
    min-width: 75px;
    padding: 0 8px;
    height: 40px;
    line-height: 40px;
    transition: all .2s;
}

#call_outcome .delete svg,
#call_outcome .edit svg {
    vertical-align: middle;
    margin: -5px 5px 0 0;
    color: #fff;
}

#call_outcome .delete:hover path {
    fill: #fff;
}

#call_outcome .delete:hover {
    border-color: #FF264A;
    background: #FF264A;
    color: #fff;
}

#call_outcome .edit:hover path {
    fill: #fff;
}

#call_outcome .edit:hover {
    border-color: #133159;
    background: #133159;
    color: #fff;
}

.responsive-table {
    min-height: 75vh;
}

#call_outcome .result {
    line-height: 24px;
    font-size: 14px;
    color: #133159;
}

.pagination {
    justify-content: center;
    display: flex;
    margin-bottom: 25px;
    align-items: center;
}

.pagination li {
    font-size: 32px;
    min-width: 50px;
    height: auto;
    color: #3C7EF3;
    margin-right: 0;
}

.pagination li small {
    margin-right: 12px;
    font-size: 18px;
}

.pagination li span {
    font-weight: 300;
}

.pagination li small,
.pagination li span {
    display: inline-block;
    padding-top: 3px;
    height: 50px;
    min-width: 30px;
    line-height: 50px;
    text-align: center;
}

.pagination li input {
    border: 2px solid #3C7EF3;
    margin: 0 0 0 10px;
    padding: 0;
    width: 65px;
    height: 46px;
    font-size: 18px;
    text-align: center;
    border-radius: 5px;
}

.pagination li .prev:hover,
.pagination li .next:hover {
    background: #68EBCA;
}

.pagination li .next,
.pagination li .prev {
    display: inline-block;
    background: #3C7EF3;
    border-radius: 5px;
    padding: 0 20px;
    font-size: 16px;
    border: none;
    height: 50px;
    color: #fff;
    cursor: pointer;
    width: 50px;
    font-weight: 500;
    line-height: 56px;
    text-align: center;
    transition: all .2s;
}

.show_signature_txt {
    display: flex;
}

.show_signature_txt svg {
    padding-left: 5px;
}

/* responsive */
@media screen and (max-width: 1370px) {
    .font_select {
        margin: 10px 17px 15px 0;
    }

    .theme_plate {
        padding-left: 2%;
    }

    .tabs a {
        font-size: 16px;
        padding: 0 15px;
    }
}

@media screen and (max-width: 1168px) {
    .tabs {
        height: 90px;
        display: flex;
        align-items: flex-start;
    }

    .tabs a {
        white-space: initial;
        line-height: 20px;
        max-width: 130px;
        font-size: 16px;
        padding: 0 8px;
    }

    .tabs a svg {
        margin: 8px auto;
        display: block;
    }

    .tabs a span {
        display: block;
    }
}

@media screen and (max-width: 1135px) {
    .time_input>label {
        display: none;
    }
}

@media screen and (max-width: 768px) {
    .theme_plate {
        padding-left: 0;
    }

    .settings_section1 .tabs a {
        padding: 0 4px;
    }

    .settings_section1 p {
        line-height: 24px !important;
        font-size: 14px !important;
    }

    .settings_section1 table th {
        white-space: nowrap;
        font-size: 16px !important;
    }

    .settings_section1 table th svg {
        height: 16px;
        width: 16px;
    }

    .notification_table th svg,
    .notification_table td img {
        height: 16px;
        width: 16px;
    }

    .data_table th:last-child,
    .data_table td:last-child {
        padding-left: 15px;
        width: 45%;
    }

    #cut_time p svg {
        height: 16px;
        width: 16px;
    }

    .tabs a.cut_time {
        max-width: 105px;
    }
}


.szn-system-email-provider-block #szn-container {
    position: relative;
    margin: auto;
    overflow: hidden;
    width: 400px;
    height: 320px;
}

.szn-system-email-provider-block .zh1 {
    font-size: 0.9em !important;
    font-weight: 400 !important;
    letter-spacing: 3px !important;
    padding-top: 5px !important;
    color: #676c7b !important;
    line-height: 25px;
}

.szn-system-email-provider-block .green {
    color: #4ec07d;
}

.szn-system-email-provider-block .red {
    color: #e96075;
}

.szn-system-email-provider-block .alert {
    font-weight: 700;
    letter-spacing: 5px;
}

.szn-system-email-provider-block p {
    margin-top: -5px;
    font-size: 0.5em;
    font-weight: 100;
    color: #5e5e5e;
    letter-spacing: 1px;
}

.szn-system-email-provider-block button,
.szn-system-email-provider-block .dot {}

.szn-system-email-provider-block #success-box {
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom right, #b0db7d 40%, #99dbb4 100%);
    border-radius: 20px;
    box-shadow: 5px 5px 20px #cbcdd3;
    perspective: 40px;
}

.szn-system-email-provider-block .dot {
    width: 8px;
    height: 8px;
    background: #fcfcfc;
    border-radius: 50%;
    position: absolute;
    top: 4%;
    right: 6%;
}

.szn-system-email-provider-block .dot:hover {
    background: #c9c9c9;
}

.szn-system-email-provider-block .two {
    right: 12%;
    opacity: 0.5;
}

.szn-system-email-provider-block .face {
    position: absolute;
    width: 22%;
    height: 22%;
    background: #fcfcfc;
    border-radius: 50%;
    border: 1px solid #777;
    top: 21%;
    left: 37.5%;
    z-index: 2;
    animation: bounce 1s ease-in infinite;
}

.szn-system-email-provider-block .eye {
    position: absolute;
    width: 5px;
    height: 5px;
    background: #777;
    border-radius: 50%;
    top: 40%;
    left: 20%;
}

.szn-system-email-provider-block .right {
    left: 68%;
}

.szn-system-email-provider-block .mouth {
    position: absolute;
    top: 43%;
    left: 41%;
    width: 7px;
    height: 7px;
    border-radius: 50%;
}

.szn-system-email-provider-block .happy {
    border: 2px solid;
    border-color: transparent #777 #777 transparent;
    transform: rotate(45deg);
}

.szn-system-email-provider-block .sad {
    top: 49%;
    border: 2px solid;
    border-color: #777 transparent transparent #777;
    transform: rotate(45deg);
}

.szn-system-email-provider-block .shadow {
    position: absolute;
    width: 21%;
    height: 3%;
    opacity: 0.5;
    background: #777;
    left: 40%;
    top: 43%;
    border-radius: 50%;
    z-index: 1;
}

.szn-system-email-provider-block .scale {
    animation: scale 1s ease-in infinite;
}

.szn-system-email-provider-block .move {
    animation: move 3s ease-in-out infinite;
}

.szn-system-email-provider-block .message {
    position: absolute;
    width: 100%;
    text-align: center;
    height: 40%;
    top: 47%;
}

.szn-system-email-provider-block .button-box {
    position: absolute;
    background: #fcfcfc;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 90%;
    height: auto;
    padding: 20px 10px;
    border-radius: 20px;
    top: 60%;
    outline: 0;
    border: none;
    box-shadow: 2px 2px 10px rgba(119, 119, 119, 0.5);
    transition: all 0.5s ease-in-out;
}

.szn-system-email-provider-block .button-box:hover {
    background: #efefef;
    transform: scale(1.05);
    transition: all 0.3s ease-in-out;
}

/* settings section end */