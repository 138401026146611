.paginationWrp{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.paginationIcon{
  width: 40px;
  height: 40px;
  display: inline-flex;
  border: 1px solid rgba(148,157,178,0.24);
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  cursor: pointer;
}
.paginationIcon.disabled{
  opacity: 0.5;
  cursor: not-allowed;
}
.paginationIuput{
  width: 60px !important;
  height: 40px !important;
  display: inline-flex;
  border: 1px solid rgba(148,157,178,0.24) !important;
  align-items: center;
  justify-content: center;
  border-radius: 4px !important;
  /*font-size: 12px !important;*/
  /*font-weight: 500;*/
  /*color: #6258ff;*/
  margin: 0px !important;
  padding: 0px 5px !important;
  box-sizing: border-box;
}
.paginationIuput:focus{
  border-bottom: 1px solid rgba(148,157,178,0.24) !important;
  box-shadow: unset !important;
}
.separator{
  cursor: auto;
  color: #ddd;
  border: none!important;
  width: 20px;
  font-size: 25px;
  font-weight: 300;
}
.total{
  font-size: 12px;
  font-weight: 500;
  color: #949db2;
}