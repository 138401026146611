h1,
h2 {
  margin: 0;
}

.cardmainPage__wrapper {
  background: #fff;
}

.cardHeadingTitle {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
  text-transform: capitalize;
  padding: 20px 20px 20px;
  color: #333333;
  border-bottom: 1px solid #e5e8ef;
}

.sale_package_list .MuiListItemIcon-root {
  min-width: 30px;
}

.sale_package_list .list_icon {
  color: #6258ff;
}

.sale_package_list .MuiListItem-button:hover {
  background: none !important;
}

.sale_package_title {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
  text-transform: capitalize;
  color: #133159;
  padding: 20px 20px 0;
}

.sale_package_list {
  height: unset !important;
  box-shadow: unset !important;
}

.sale_package_list .MuiListItemText-root {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: rgba(19, 49, 89, 0.65);
}

.sale_package_list .MuiButtonBase-root {
  padding-top: 4px;
  padding-bottom: 4px;
  cursor: default;
}

.sale__package {
  background: #f6f7fb;
  border-radius: 10px;
}

.sale_package_footer {
  background: #e5e8ef;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px; */
  margin: 16px 0 20px;
}

.sale_package_footer.alter {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sale_package_footer_text {
  font-family: "Poppins";
  font-size: 20px;
  line-height: 36px;
  color: #133159;
  margin: 4px 20px 16px !important;
  /* margin: 0 !important; */
}

.sale_package_footer_text span {
  color: #3C7EF3;
}

.sale_package_footer_text.alt {
  margin: 0 !important;
}

.sale_package_list {
  padding: 20px 0 0 !important;
  border-top: 1px solid #E5E8EF;
}

.play__btn {
  padding: 15px 30px !important;
  background: #fff !important;
  border-radius: 5px !important;
  font-family: "Poppins" !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: #133159 !important;
  box-shadow: unset !important;
  transition: all 0.4s ease-in-out;
  text-transform: capitalize !important;
}

.play__btn.alt {
  text-transform: capitalize !important;
}

.sale_package_footer.alter .play__btn .MuiButton-endIcon {
  color: #fff;
  background: #133159;
}

.play__btn:hover {
  opacity: 0.85;
}

.sale_package_footer_btn.load_more button {
  background: #3C7EF3 !important;
  color: #fff !important;
}

.play__btn .MuiButton-endIcon {
  width: 18px;
  height: 18px;
  background: #fff;
  border-radius: 50%;
  color: #133159;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0;
}

.play__btn .MuiButton-endIcon svg {
  font-size: 16px !important;
}

.cardBox__wrapper {
  background: rgba(246, 247, 251, 0.6);
  border-radius: 10px;
  padding: 20px;
}

.cardBox__wrapper .title__h6 {
  font-family: "Poppins" !important;
  font-weight: 300;
  font-size: 20px;
  line-height: 36px;
  margin: 0;
  color: #133159;
}

.cardBox__wrapper .title__h6 strong {
  font-weight: 600;
}


.form__group label.MuiFormControlLabel-root .MuiIconButton-root.Mui-checked {
  color: #3C7EF3;
}

.cardBox__body {
  padding: 24px 0;
}

.pay__btn {
  margin-left: auto !important;
  background: #68ebca !important;
  border-radius: 10px !important;
  padding: 13px 15px !important;
  display: flex !important;
  max-width: 190px;
  transition: all 0.4s ease-in-out;
  box-shadow: unset !important;
}

.pay__btn.alt .MuiButton-startIcon {
  background: transparent !important;
  color: #fff;
}

.pay__btn:hover {
  opacity: 0.85;
}

.pay__btn .MuiButton-startIcon {
  width: 36px;
  height: 36px;
  background: #fff;
  border-radius: 50%;
  color: #3c7ef3;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.pay__btn svg {
  font-size: 30px !important;
}

#PaymentForm .rccs {
  margin: 0;
}

div#PaymentForm {
  display: flex;
  align-items: center;
}

div#PaymentForm form {
  margin-left: 20px;
}

div#PaymentForm form input {
  padding: 14px 20px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  width: 100%;
  letter-spacing: 0.01em;
  color: rgba(19, 49, 89, 0.35);
  border: 0;
  background: #f6f7fb;
  border-radius: 5px;
  margin: 0 !important;
}

div#PaymentForm form input:focus {
  box-shadow: unset !important;
}

div#PaymentForm form label {
  margin-bottom: 2px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  color: #133159;
}

.two__col {
  display: flex;
  margin: 9px 0;
}

.two__col div + div {
  margin-left: 20px;
}


.mainGrid {
  background: #FFFFFF;
  padding: 8px;
  margin: 0 !important;
  width: 100% !important;
}


.creditCardModal .ccm__modalPaper {
  padding: 140px 104px 65px;
  text-align: center;
  position: relative;
}

.creditCardModal .ccm__modalPaper:focus-visible {
  outline: 0 !important;
}

.creditCardModal .ccm__modal_title {
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 48px;
  line-height: 72px;
  letter-spacing: 0.01em;
  color: #3C7EF3;

}

.creditCardModal.errorModal .ccm__modal_title {
  color: #133159;
}




.creditCardModal .ccm__modal_subtitle {
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.01em;
  color: rgba(19, 49, 89, 0.65);
  margin: 10px 38px 50px;

}


.creditCardModal.errorModal .ccm__modal_subtitle {
  color: #FF264A;
}

.creditCardModal .ccm__modal_subtitle strong,
.creditCardModal .ccm__modal_subtitle b {
  color: #133159;
}

.creditCardModal.errorModal .ccm__modal_subtitle strong,
.creditCardModal.errorModal .ccm__modal_subtitle b {
  color: inherit;
}

.creditCardModal .ccm__modal_button {
  background: #3C7EF3;
  border-radius: 5px;
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: capitalize;
  padding: 7px 20px;
  color: #FFFFFF;
  min-height: 50px;
  margin: 0 auto;
  transition: all 0.4s ease-in-out;
  min-width: 184px;
}

.creditCardModal .ccm__modal_button:hover {
  background: #3C7EF3;
  opacity: 0.65;
}

.creditCardModal .MuiButton-outlined.ccm__modal_button {
  background: transparent;
  color: #3C7EF3;
  border: 1px solid #3C7EF3;
}

.creditCardModal .MuiButton-outlined.ccm__modal_button:hover {
  opacity: 0.65;
}

.creditCardModal .MuiIconButton-root {
  background: #FF264A;
  color: #fff;
  width: 26px;
  height: 26px;
  position: absolute;
  top: 25px;
  right: 20px;
  transition: all 0.4s ease-in-out;
}

.creditCardModal .MuiIconButton-root:hover {
  background: #FF264A;
  opacity: 0.65;
}

.creditCardModal .MuiIconButton-root svg {
  font-size: 22px;
  font-weight: 700;
}

.sale_package_footer_btn {
  padding: 20px;
}

.sale_package_footer_btn button {
  background: #e5e8ef !important;
  padding: 15px 65px !important;
  border-radius: 5px !important;
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #133159;
  max-width: unset;
  margin: 0 auto !important;
  text-transform: capitalize !important;
}

.close_modal_icon {
  background: #f00 !important;
  position: absolute !important;
  top: -18px;
  right: -18px;
  color: #fff !important;
}

.close_modal_icon svg {
  font-size: 18px;
}

.modal_fade h2 {
  font-size: 24px;
}

.modal_fade p {
  font-size: 16px;
  line-height: 24px;
  margin-top: 5px;
}

@media (max-width: 1500px) {
  div#PaymentForm form label {
    font-size: 13px;
    line-height: 24px;
  }

  .pay__btn,
  .play__btn {
    padding: 10px 15px !important;
    font-size: 16px !important;
    line-height: 36px !important;
  }

}

@media (max-width: 1400px) and (min-width: 1367px) {
  .two__col {
    flex-direction: column;
  }

  .two__col div + div {
    margin: 10px 0 0;
  }
}

@media (max-width: 1400px) {


  /* .sale_package_footer {
    flex-direction: column;
    align-items: flex-start;
  } */

  .sale_package_footer .play__btn {
    margin-top: 15px;
  }

}


@media (max-width: 1366px) {
  .sale_package_footer_text {
    font-size: 16px;
    line-height: 30px;
  }

  .pay__btn,
  .play__btn {
    padding: 10px 15px !important;
    font-size: 16px !important;
    line-height: 30px !important;
  }

  .mainGrid .MuiGrid-item {
    max-width: 100%;
    flex-basis: 100%;
  }
}

@media(max-width: 1024px) {
  div#PaymentForm {
    flex-direction: column;
    align-items: flex-start;
  }

  div#PaymentForm form {
    margin: 20px 0 0;
  }

  .cardHeadingTitle {
    font-size: 18px;
    line-height: 28px;
    padding: 15px 20px 15px;
  }

  .sale_package_title {
    font-size: 16px;
    line-height: 26px;
    padding: 10px 20px;
  }

  .cardBox__wrapper .title__h6 {
    font-size: 18px;
    line-height: 30px;
  }
}


.mapping__mysale {
  background: rgba(246, 247, 251, 0.6);
  border-radius: 10px;
  padding: 20px;
  margin: 20px;
  width: 100%;
  display: inline-block;
}

.mapping__mysale .mapping__mysale_title {
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 24px;
  line-height: 40px;
  color: #133159;
}

.mapping__mysale .mapping__mysale_subtitle {
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 20px;
  line-height: 36px;
  color: #133159;
}

.mapping__mysale .mapping__mysale_subtitle span {
  font-weight: 400;
}

.mapping__mysale .mapping__mysale_status {
  font-family: 'Poppins';
  font-weight: 700;
  font-size: 20px;
  line-height: 36px;
  color: #133159;
}

.mapping__mysale .mapping__mysale_status span {
  color: #3C7EF3;
}

/* Active Version */
.sale__package.mi_active_card {
  background: #133159;
}

.mi_active_card .sale_package_title {
  color: #fff;
}

.mi_active_card .sale_package_footer_text {
  color: #fff;
}

.mi_active_card .sale_package_footer_text span {
  color: #68EBCA;
}

.mi_active_card .sale_package_footer.alter {
  background: rgba(255, 255, 255, 0.1);
}

.mi_active_card .sale_package_list .MuiListItemText-root {
  color: #fff;
}

.mi_active_card .sale_package_list .list_icon {
  color: rgba(255, 255, 255, 0.4);
}

.mi_active_card .sale_package_footer_btn button {
  background: rgba(255, 255, 255, 0.1) !important;
  color: #fff;
}

.mi_active_card .sale_package_footer_btn button svg {
  margin-right: 10px;
}

.mi_active_card .sale_package_footer_btn button svg path {
  fill: #08BE7C;
}



/* .ProfileModule-container-parent-class .card__domain__more__info{
    font-size: 15px;
    margin: 3px 0 0 16px;
    color: var(--blue-2);
}
.ProfileModule-container-parent-class .card__domain__more__info:hover{
  text-decoration: underline;
} */

.ProfileModule-container-parent-class .cardHeadingTitle__title {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
  text-transform: capitalize;
  padding: 20px 20px 20px;
  color: #333333;

}

.ProfileModule-container-parent-class .cardmainPage__wrapper__toper {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e5e8ef;
  align-items: center;
  padding: 0 22px 0 0;
}

.ProfileModule-container-parent-class .cardmainPage__wrapper__toper button {
  height: 100%;
  border: 0;
  font-size: 14px;
  border-radius: 6px;
  cursor: pointer;
}

.ProfileModule-container-parent-class .cardmainPage__radio__wrapper {
  flex-direction: row !important;
  padding: 0 0 8px 23px;
  grid-gap: 55px;
}

.ProfileModule-container-parent-class .cardmainPage__radio__wrapper .MuiButtonBase-root {
  padding: 2px 5px !important;
}

.ProfileModule-container-parent-class .cardmainPage__radio__wrapper .Mui-checked {
  color: var(--blue-1) !important;
}

.ProfileModule-container-parent-class .cardmainPage__radio__wrapper svg {
  width: 16px;
}

.ProfileModule-container-parent-class .cardmainPage__radio__wrapper .MuiTypography-root {
  font-size: 14px;
  color: var(--dark_blue);
}

.ProfileModule-container-parent-class .mapping__mysale .mapping__mysale_title {
  display: flex;
  grid-gap: 50px;
  align-items: center;
}

.ProfileModule-container-parent-class .mapping__mysale .mapping__mysale_title .package__hide__show__button {
  background: var(--blue-2);
  border-radius: 6px;
  color: #fff;
  font-size: 17px;
  padding: 2px 10px;
  height: 100%;
  cursor: pointer;
}

/* Map Integration - 21.5.22 @anisur */
.cardHeadingTitle.alt {
  flex-direction: column;
  align-items: flex-start;
}

.mi_header_desc {
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.01em;
  text-transform: capitalize;
  color: rgba(51, 51, 51, 0.65);
  margin-top: 8px;
}

.card__domain__more__info {
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  text-align: right;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: rgba(19, 49, 89, 0.4);
  position: absolute;
  right: 20px;
  top: 20px;
  display: inline-flex;
  align-items: center;
}

.card__domain__more__info svg {
  margin-right: 8px;
}

/* User Table List */
.map__access__team__user__list {
  padding: 0 20px 20px;
}

.map__access__team__user__list thead {
  background: #F6F7FB;
}

.map__access_user_table label.MuiFormControlLabel-root {
  margin: -4px 0 0;
}

.map__access_user_table th div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.map__access_user_table th svg {
  cursor: pointer;
  color: rgba(19, 49, 89, 0.65);
}

.mi_user_table_header p {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0.01em;
  text-transform: capitalize;
  color: #333333;
  margin-bottom: 16px;
}

.map__access_user_table tbody td {
  color: rgba(19, 49, 89, 0.65)
}

.map__access_user_table .custom-switch-inactive-mode .react-switch-bg {
  border: 1px solid #e5e8ef !important;
  background-color: #E5E8EF !important;
}

.map__access_user_table tbody td small {
  margin-left: 10px;
}

/* Package Purchased */
.mi_updated .mapping__mysale_title {
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0.01em;
  text-transform: capitalize;
  color: #333333;
}

.mi_updated .mapping__mysale_title p {
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.01em;
  text-transform: capitalize;
  color: rgba(51, 51, 51, 0.65);
  /* padding-bottom: 16px; */
  margin: 0;
  /* margin-bottom: 16px; */
}

.mapping__mysale {
  border-left: 5px solid #3C7EF3;
}

.ProfileModule-container-parent-class .mapping__mysale.mi_updated {
  width: calc(100% - 40px);
}

.ProfileModule-container-parent-class .mapping__mysale.mi_updated .mapping__mysale_title {
  /* display: block; */
  grid-gap: 0;
  /* align-items: unset; */
  position: relative;
  margin-bottom: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid #E5E8EF;
}

.ProfileModule-container-parent-class .mapping__mysale.mi_updated .mapping__mysale_title > svg {
  margin-right: 12px;
  cursor: pointer;
  transform: rotate(180deg);
}
.ProfileModule-container-parent-class .mapping__mysale.mi_updated .mapping__mysale_title .package__hide__show__button {
  position: absolute;
  height: auto;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
  margin-top: -10px;
  padding: 6px 11px;
  background: #3C7EF3;
}

.ProfileModule-container-parent-class .mapping__mysale.mi_updated .mapping__mysale_title .package__hide__show__button_v2 {
  position: absolute;
  height: auto;
  top: 50%;
  transform: translateY(-50%);
  right: 210px;
  margin-top: -10px;
  padding: 6px 11px;
  background: #f33c3c;
  border-radius: 6px;
  color: #fff;
  font-size: 17px;
  cursor: pointer;
}

.mapping__mysale.mi_updated .mi_purchased_list {
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: rgba(19, 49, 89, 0.65);
  display: flex;
  align-items: center;
}

.mapping__mysale.mi_updated .mi_purchased_list + .mi_purchased_list {
  margin-top: 8px;
}

.mi_updated .mi_purchased_list svg {
  margin-right: 10px;
  color: #6258FF;
}

.mi_updated.mapping__mysale .mapping__mysale_subtitle span,
.mi_updated.mapping__mysale .mapping__mysale_status span {
  color: #133159;
}

.mi_updated.mapping__mysale .mapping__mysale_status.alt span {
  color: #3C7EF3;
}

/* Pending Request */
.mi_right_col {
  background: rgba(98, 88, 255, 0.05);
  border: 1px dashed #6258FF;
  border-radius: 8px;
  padding: 20px;
}

.mi_right_col button {
  background: #E5E8EF;
  border-radius: 5px;
  border: 0;
  padding: 12px 25px;
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.01em;
  color: #133159;
  text-transform: capitalize;
  cursor: pointer;
}

.mi_pending_header > p {
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: #2A2E34;
}

.mi_pending_header p {
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: #2A2E34;
  margin: 0;
}

.mi_pending_header span {
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: rgba(42, 46, 52, 0.6);
  margin: 0;
}

.mi_right_col p span {
  color: #133159;
}

.mi_right_col div ~ p {
  display: flex;
  align-items: center;
  margin: 0;
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: rgba(19, 49, 89, 0.65);
}

.mi_right_col div ~ p + p {
  margin-top: 10px;
}

.mi_right_col div ~ p svg {
  margin-right: 10px;
  color: #6258FF;
}

.mi_right_col div ~ p span {
  color: #133159;
}

.mi_pending_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
}

.mi_row {
  display: flex;
  flex-wrap: wrap;
}

.mi_col {
  flex: 1 0 50%;
}
.mi_row.fullRow .mi_left_col {
  flex-basis: 100%;
}

/* Card Package */
.mi_grid_wrapper .sale__package {
  background: #fff;
  cursor: pointer;
}
.mi_grid_wrapper .sale__package.mi_active_card {background: #133159;}
.mi_grid_wrapper {
  margin-top: 20px !important;
  border-top: 1px solid #ddd;
  padding-top: 10px;
}

.sale_pack_update.sale__package .sale_package_footer.alter {
  background: transparent;
  border-top: 1px solid #E5E8EF;
  padding-bottom: 0;
  margin-bottom: 0;
}

.sale_pack_update.sale__package .sale_package_footer.alter button {
  background: #08BE7C !important;
  border-radius: 5px !important;
  margin: 0 auto !important;
  font-family: 'Poppins', serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #FFFFFF !important;
  text-transform: capitalize;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
.sale_pack_update.sale__package .sale_package_list {
  border: 0;
  padding-bottom: 15px !important;
}

.sale_pack_update.sale__package.mi_active_card .sale_package_footer.alter {
  background: transparent;
}
.sale_pack_update.sale__package.mi_active_card .sale_package_footer.alter button {
  background: rgba(255, 255, 255, 0.1) !important;
  margin: 0 auto !important;
}
.sale_pack_update.sale__package.mi_active_card .pay__btn svg {
  color: #08BE7C;
  margin-right: 10px;
}

@media (max-width: 1200px) {
  .mi_row {
    flex-direction: column;
  }

  .mi_col + .mi_col {
    margin-top: 20px;
  }

  .mi_grid_wrapper .MuiGrid-item {
    max-width: 50%;
    flex-basis: 50%;
}
}