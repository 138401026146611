.overlay{
    background-color: #00000080;
  }
.mainWrp{
  width: 400px;
  /* max-height: 450px;
  height: 199px; */
  padding-top: 25px;
  padding-bottom: 25px;
  display: flex;
  background-color: #fff;
  border-radius: 7px;
  flex-direction: column;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.header{
  width: 100%;
  min-height: 40px;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  box-sizing: border-box;
}
.bodyWrp{
  width: 100%;
  padding: 30px 20px;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  gap: 5px;
  text-align: center;
}
.footerWrp{
  width: 370px;
  min-height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  box-sizing: border-box;
  gap: 10px;
}
.close{
  cursor: pointer;
  display: inline-flex;
}
.button{
  padding: 6px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0.6px;
  border-radius: 7px;
  width: 100%;
  font-size: 18px;
}
.cancelButton{
  color: #ffffff;
  background-color: #ef244a;
  border-radius: 10px;
  border:2px solid #ef244a;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}
.cancelButton:hover {
  background-color: #ad0120;
  border:2px solid #ad0120;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transform: translateY(-1px);
}
.submitButton{
  color: #fff;
  background-color: #006df5;
  border-radius: 10px;
  border:2px solid #006df5;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
}
.submitButton:hover {
  background-color: #0056b3;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transform: translateY(-1px);
}
.bodyTitle{
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 0.7px;
  color: #133159;
}
.descriptionText{
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.5px;
  color: #133159;
  text-align: center;
  width: 465px;
}
.highlightWrp{
  width: 100%;
  display: flex;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 7px;
  gap: 10px;
  margin: 20px 0px;
}
.highlightWrpWarning{
  background-color: #fdf6b2;
}
.highlightBody{
  display: flex;
  flex-direction: column;
  gap: 7px;
}
.titleWarning{
  color: #8e4b10;
}
.submitButtonWarning{
  color: #fff;
  background-color: rgba(181, 80, 226, 0.88);
}
.footerLinkText{
  text-decoration: underline;
  text-align: center;
  color: #006df5;
}