  .product_container button:focus {
    background-color: unset;
  }

  .product_container input:-webkit-autofill,
  .product_container input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
  }

  .product_container input[data-autocompleted] {
    background-color: transparent !important;
  }

  .product_container input:not([type]),
  .product_container input[type="text"]:not(.browser-default),
  .product_container input[type="password"]:not(.browser-default),
  .product_container input[type="email"]:not(.browser-default),
  .product_container input[type="url"]:not(.browser-default),
  .product_container input[type="time"]:not(.browser-default),
  .product_container input[type="date"]:not(.browser-default),
  .product_container input[type="datetime"]:not(.browser-default),
  .product_container input[type="datetime-local"]:not(.browser-default),
  .product_container input[type="tel"]:not(.browser-default),
  .product_container input[type="number"]:not(.browser-default),
  .product_container input[type="search"]:not(.browser-default),
  .product_container textarea.materialize-textarea {
    margin: 0;

    &:focus,
    &:active {
      box-shadow: unset !important;
      --webkit-box-shadow: unset;
      border-bottom: unset;
    }
  }

  .product_container input[type="search"]::-webkit-search-decoration,
  .product_container input[type="search"]::-webkit-search-cancel-button,
  .product_container input[type="search"]::-webkit-search-results-button,
  .product_container input[type="search"]::-webkit-search-results-decoration {
    -webkit-appearance: button;
  }

  .product_container [type="checkbox"]+span:not(.lever):before,
  .product_container [type="checkbox"]:not(.filled-in)+span:not(.lever):after {
    content: none;
  }

  .product_container input[type="text"]:not(.browser-default) {
    height: unset;
  }

  .product_and_service_header {
    display: flex;
    justify-content: space-between;
  }

  .global_search {
    float: right;
    margin-bottom: 10px;
  }

  .text_area {
    /* width: 75%; */
    height: 100px;
    padding: 10px;
    border-radius: 5px;
    max-height: 150px;

  }

  .text_area:focus-visible {
    outline: none !important;
  }

  .item_input {
    border: 1px solid !important;
    padding: 10px !important;
    border-radius: 5px !important;
  }

  .select_gateway {
    border: 1px solid #e2e3e9;
    padding: 5px;
    font-size: 16px;
    margin-bottom: 10px;
    width: 180px;
  }

  .selling_price_title {
    display: flex;
    gap: 20px;
  }

  .selling_price_description {
    display: flex;
    position: relative;
  }

  .selling_price_description h3 {
    position: absolute;
    background-color: grey;
    font-size: 33px;
    color: #fff;
    /* height: 3rem; */
    text-align: center;
    padding: 4px 25px;
    border-radius: 5px;
  }

  .selling_price_input {
    border: 1px solid !important;
    padding: 10px 10px 10px 80px !important;
    border-radius: 5px !important;
    height: 45px !important;
  }

  .selling_price_input:focus {
    border-bottom: 1px solid !important;
  }

  .sales_tax {
    display: flex;
    position: relative;
  }

  .sales_input_tag {
    border: 1px solid !important;
    /* padding: 10px; */
    padding: 10px 81px 10px 10px !important;
    border-radius: 5px !important;
  }

  .sales_input_tag:focus {
    border-bottom: 1px solid !important;
  }

  .sales_tax .sales_description {
    position: absolute;
    background-color: grey;
    font-size: 34px;
    color: rgb(255, 255, 255);
    text-align: center;
    padding: 4px 25px;
    right: 0;
    border-radius: 5px;
  }

  .dropdown {
    display: flex;
    gap: 10px;
  }

  .modal_title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(148, 157, 178, .24);
    padding: 16px 24px;
  }


  .sticky_pagination {
    position: sticky;
    bottom: 0;
    background: white;
  }