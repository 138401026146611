.listing--table-wrapper,button,select,input{
    font-family: var(--poppins);
}
.listing--table-wrapper {
    background-color: var(--white);
    border-radius: 10px;
}
.page_header{
    width: 100%;
    display: -webkit-box; 
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex; 
    display: flex;
    justify-content: space-between;
    border-bottom:1px solid #ddd;
    padding: 20px 20px;
    margin-top: 30px;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}

 .page_header .page_title h3{
    font-family: var(--poppins);
    font-weight: 500;
    font-size: 24px;
    line-height: 40px;
    margin: 0;
    padding: 0;
    color: var(--dark_blue);
}
 .page_header .header_right_action{
	 max-width:152px;
 width:100%;
 }
 .page_header .header_right_action .header_button {
    border-radius: 5px;
    padding: 13px 20px;
    background: #3C7EF3;
    color: #fff;
    font-size: 14px;
    border:none;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: 0.5s all ease;
	 width:100%;
     font-weight: 500;
     height: 50px;
}
 .page_header .header_right_action .header_button:hover {
    background: #416cb6;
}
 .page_header .header_right_action .header_button svg {
    background: #fff;
    padding:5px;
    color:#fff;
    border-radius: 50%;
    margin-right: 10px;
    width: 25px;
    height: 25px;
}
 .details.table-listing-wrapper {
    position: relative;
    width: 100%;
    padding: 20px 30px;
}
 .details.table-listing-wrapper .list_filter {
    display: flex;
    width: 100%;
}
 .details.table-listing-wrapper .list_filter p{
    display: flex;
    font-size: 16px;
    color: var(--dark_blue);
}
 .details.table-listing-wrapper .list_filter select {
    display: block;
    width:66px;
    height: 40px;
    margin: 20px;
    border-radius: 5px;
    border:1px solid rgba(0,0,0,0.3);
    margin-top: 10px;
    padding:5px;
    padding-right: 10px;
    font-weight: normal;
    font-size: 16px;
    line-height: 30px;
    color: #546376;
}
 .details.table-listing-wrapper table {
    width: 100%;
    height: auto;
    border-collapse: collapse;
    position: relative;
    margin-top: 10px;
    border-radius: 10px;
}
 .details.table-listing-wrapper table thead td {
    background: #F6F7FB;
    color: var(--dark_blue);
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 36px;
    border-right: 2px solid var(--white);
    padding: 15px 10px;
}
 .details.table-listing-wrapper table thead td:last-child {
    text-align: left;
}


 .details.table-listing-wrapper table tr {
    /* border-bottom: 1px solid rgba(0,0,0,0.1); */
    border-bottom: 3px solid rgba(60, 126, 243, 0.05);
    height: 60px;
}
 .details.table-listing-wrapper table tr:hover {
    background: #F6F7FB;
}
 .details.table-listing-wrapper table tbody tr:last-child {
    border-bottom:none;
}
/* #main__content .details.table-listing-wrapper table tbody tr:hover {
    background: #03a9f4;
    color:#fff;
} */
 .details.table-listing-wrapper table tbody tr td {
    padding: 5px 5px;
    font-weight: 400;
    color: rgba(19, 49, 89, 0.65);
    font-size: 16px;
    
}
.details.table-listing-wrapper table tr td .time{
  margin-left: 10px;
}
 .details.table-listing-wrapper table tr td:first-child {
    width:20%;
    padding: 0 20px;
}
.details.table-listing-wrapper table tr td:nth-child(3){
    width:30%;
}
 .details.table-listing-wrapper table tr td:last-child {
    width:25%;
    text-align: left;
}

 .details.table-listing-wrapper table tr td .action .edit__list  {
    border:1px solid #ddd;
    padding:10px 15px;
    background: transparent;
    border-radius: 5px;
    position: relative;
    cursor: pointer;
    margin: 0 5px;
}
 
 .details.table-listing-wrapper table tr td .action .edit__list span{
    color:var(--dark_blue);
    font-size: 16px;
    font-weight: 500;
    font-family: Poppins;
}
 .details.table-listing-wrapper table tr td .action .edit__list svg {
    margin-right: 5px;
}

 .pagination_bar {
    position: relative;
    width:100%;
    height: auto;
    background: #F5F7F9;
    padding:20px 0;
    margin-bottom: 2px;
    
}
 .pagination_bar ul  {
   display: flex;
   justify-content: center;
}
 .pagination_bar ul li a {
    color:#fff;
    background: #3C7EF3;
    border-radius: 5px;
    padding: 6.5px 11px;
    margin-left:10px;
    display: block;
    border:2px solid #3C7EF3;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
}
 .pagination_bar ul li:nth-child(2) a {
    background: transparent;
    border:2px solid #3C7EF3;
    color:#3C7EF3;
}
   .pagination_bar ul li:nth-child(3) a {
    background: transparent;
    color:#3C7EF3;
    border: 2px solid #f5f7f9;
}
 .pagination_bar ul li:nth-child(3) a span {
    margin-right: 5px;
}
/* Modal css*/

.add_list_modal .modal_body .modal_input,
.add_list_modal .modalBody .modal_input{
    background:var(--white);
    border-radius: 5px;
    border:0px;
    height: 50px;
    padding: 0px 15px;
    box-sizing: border-box;
    color: #8896A8;
}
.add_list_modal .modal_body .modal_input:focus,
.add_list_modal .modalBody .modal_input:focus{
    border: 0;
    outline: none;
}

.add_list_modal .modal--footer .btn-primary svg {
    background:  var(--white) !important;
    padding: 5px;
    color: var(--white) !important;
    border-radius: 50%;
  
}

.update_list_modal .modal--footer .btn-primary{
    max-width: 163px;
}
.update_list_modal .modal--footer .btn-danger{

}
.modal--footer .btn-primary:hover{
    /*background-color: var(--dark_blue);*/
}
.modal--footer .btn-danger:hover{
    /*background-color: var(--orange);*/
}


.add_list_modal .modalBody .list-update-label {
    font-weight: normal;
    padding: 10px 3px;
    font-size: 16px;
}


@media(max-width:992px){
    /* body.active__minimal_sidebar .main__content_wrapper .main__content{
        width: 100%;
    } */
    .page_header .page_title h3{
        font-size: 16px;
    }
    .page_header .header_right_action .header_button{
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        height: 40px;
        padding: 0;
        width: 132px;
        align-items: center;
        justify-content: center;
    }
    .details.table-listing-wrapper .list_filter {
        align-items: center;
    }
    .details.table-listing-wrapper .list_filter p{
        font-size: 10px;
    }
    .details.table-listing-wrapper .list_filter select{
        width: 45px;
        height: 25px;
        margin: 20px;
        font-size: 10px;
        padding: 0;
    }
    .details.table-listing-wrapper table thead td,
    .details.table-listing-wrapper table tbody td{
        font-weight: 500;
        font-size: 10px;
        line-height: 18px;
    }
    .details.table-listing-wrapper table tr td .action .edit__list span {
        
        font-size: 12px;
        line-height: 18px;
    }
    .details.table-listing-wrapper table tr td .action .edit__list {
        display: flex;
        align-items: center;
        width: 100%;
        
    }
    .details.table-listing-wrapper table tr td .action .edit__list svg{
        width: 16px;
        height: 16px;
        margin-right: 20px;
    }
    .add_list_modal.modal .header h5{
        font-size: 16px;
        line-height: 24px;
    }
    .modal--footer .btn-primary, .modal--footer .btn-danger{
        font-size: 12px;
        line-height: 24px;
        height: 40px;
    }
    .modal--footer .btn-primary{
        max-width: 108px;
    }
    .modal--footer .btn-danger{
        max-width: 118px;
    }
    
}
