.activity_log{
    background: var(--white);
    border-radius: 10px;
    margin-top: 30px;
}
.activity_title{
    border-bottom: 1px solid rgba(60, 126, 243, 0.1);
}
.activity_title h1{
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.01em;
    color: var(--dark_blue);
    margin: 0;
    padding: 27px 20px 15px;
}
.activity_list .logged_in{
    background: #F6F7FB;
    border: 1px solid #E5E8EF;
    box-sizing: border-box;
    border-radius: 5px;
    margin: 10px 20px 10px 30px;
    padding: 15px 35px;
    position: relative;
}
.activity_list .logged_in::before{
    content: '';
    position: absolute;
    background-image: url(./images/warning.svg);
    left: -15px;
    width: 30px;
    height: 30px;
    top: 45px;
}
.activity_list .contact_log::before{
    content: '';
    position: absolute;
    background-image: url(./images/add.svg) !important;
    left: -15px;
    width: 30px;
    height: 30px;
    top: 45px;
}
.activity_list .delete_log::before{
    content: '';
    position: absolute;
    background-image: url(./images/delete.svg) !important;
    left: -15px;
    width: 30px;
    height: 30px;
    top: 45px;
}
.activity_list .logged_in h6{
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.01em;
    color: var(--dark_blue);
    margin: 0;
}
.activity_list .logged_in span,
.activity_list .logged_in p{
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.01em;
    color: #546376;
    margin: 12px 0;
}

/*============paginations css==============*/


ul.pagination li.arrow-left a i {
    background: #3C7EF3;
    border-radius: 5px;
    color: #fff;
    display: block;
    padding: 10px 10px;
}

.pagination li.active {
    background-color: #fff;
    border: 2px solid #3C7EF3;
    box-sizing: border-box;
    border-radius: 5px;
    /* color: #3C7EF3; */
}
.pagination li.active a {
    color: #3C7EF3;
}

ul.pagination {
    display: flex;
    align-items: center;
    width: 287px;
    margin-top: 0px;
}

.pagination li {
    display: inline-block;
    border-radius: 2px;
    text-align: center;
    vertical-align: top;
    height: auto;
    margin-left: -15px;
}

.pagination li a {
    display: inline-block;
    padding: 10px 27px;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
    color: #3C7EF3;
}
