.categorySelectList {
    background: #3c7ef3;
    border-radius: 5px;
    padding: 10px 15px;
    margin: unset;
    cursor: pointer;
    font-family: Poppins!important;
    font-style: normal!important;
    font-weight: 500!important;
    font-size: 20px!important;
    line-height: 36px!important;
    color: #fff!important;
}

.categoryFilterSelectList{
    background: #f6f7fb;
    border: 1px solid #e5e8ef;
    box-sizing: border-box;
    border-radius: 5px;
    margin: unset;
    padding: 10px 15px;
    width: 220px;
    font-family: Poppins!important;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: #133159;
    margin: 0px 6px;
}

.displayHeadDesign{
    display: flex;
    align-items: inherit;
}

.pageHeadingSearch {
    background: #f6f7fb;
    border: 1px solid #e5e8ef;
    border-radius: 5px;
    width: 220px;
    margin: 0 35px;
    height: 50px;
    display: flex;
    padding: 0 20px;
    align-items: center;
}


.removeInputBorder{
    border: none !important;
    border-bottom: none !important;
}


.snapBox{
    border-radius: 10px;
    background: #fff;
    padding: 20px 30px;
    margin-bottom: 30px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.ashColor{
    color: #e5e8ef
}

.snapBox h5{
    padding-bottom: 5px;
    font-weight: 500;
    font-size: 20px;
    color: #133159;
    line-height: 30px;
}

.snapBox  p{
    line-height: 24px;
    font-size: 16px;
    color: #060607;
}

.snapBox  p span{
    color: #3c7ef3;
}

/* .snapBox  .snap-box-action{
    display: flex;
    justify-content: space-around;
} */



.snapBoxActionDiv{
    display: flex;
    align-items: center;
    justify-content: center;
}
.snapBoxActionDiv .snapLink{
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
}

.snapBoxAction{
    display: flex;
    border: 1px dashed #e5e8ef;
    border-radius: 10px;
    background: #fafafc;
    color: #3c7ef3;
    width: 200px;
    font-size: 16px;
    margin: 25px;
    min-height: 110px;
    text-align: center;
    align-items: center;
    justify-content: center;
}
.flexAction{
    text-align: center;
    align-items: center;
    justify-content: center;
}