.itemWrp{
  width: 100%;
  height: 120px;
  /* flex-grow: 1; */
  border:1px solid #949db23d;
  padding: 5px 10px;
  box-sizing: border-box;
  border-radius: 7px;
  cursor: grab;
}
.itemWrp:hover{
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 0.2), 0px 2px 2px 0px rgb(0 0 0 / 0.14), 0px 1px 5px 0px rgb(0 0 0 / 0.12);
}
.header{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 0px;
  border-bottom: 1px solid #949db23d;
}
.title{
  font-size: 15px;
  font-weight: 700;
  letter-spacing: 0.5px;
  text-transform: capitalize;
  color: #1c1b1f;
}
.actionWrp{
  align-items: center;
  display: flex;
}
.actionWrp svg{
  display: inline-flex;
  margin: 0px 5px;
  cursor: pointer;
}
.actionWrp svg:hover{
  fill: #1c1b1f;
}
.body{
  width: 100%;
  display: grid;
  grid-template-columns: 1.8fr 1.2fr;
  gap: 5px;
  padding: 5px 15px;
  box-sizing: border-box;
}
.bodyLeft{
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.bodyRight{
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.tag{
  display: flex;
  gap: 5px;
}
.tagLabel, .tagText{
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0.6px;
  color: #1c1b1f;
}
.info{
  display: flex;
  gap: 5px;
}
.infoLabel, .infoText{
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 0.6px;
  color: #546376;
}