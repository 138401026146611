/* Default styles for larger screens */
.company__info__container {
    padding: 0;
}

.company__info__row + .company__info__row {
    margin-top: 10px;
}

 .company__info__row__title {
    margin: 0 0 4px 0;
    font-size: 14px;
}

 .personal_info_text_field {
    background: #F5F7F9 !important;
    font-size: 12px;
    margin: 0;
    color: rgba(19, 49, 89, 0.65);
}

/* Placeholder styles for input fields */
.personal_info_text_field::placeholder {
    color: rgba(19, 49, 89, 0.65);
}

 .company__info__row__right__img img {
    width: 250px;
    height: 100px;
    border: 1px solid #e1e1e1;
    padding: 10px;
    border-radius: 8px;
}
  
.img__file__condition {
    color: rgba(19, 49, 89, 0.65);
    font-size: 12px;
    margin: 0 0 8px 0;
}

.company__info__row__right .MuiDropzoneArea-root {
    max-width: 300px;
    padding: 16px 58px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: auto;
    border: 2px dashed #ddd;
    background: #F5F7F9;
}

.company__info__container .MuiTypography-root {
    font-size: 12px;
    margin: 0 0 8px 0;
}

.company__info__container .MuiDropzoneArea-icon {
    color: rgba(0, 0, 0, 0.87);
    width: 28px;
    height: auto;
}


.invoice{
    margin-top: 1.5rem !important;
}
 .invoice_design{
    margin: 10px;
    padding: 10px;
}
 .footerOption{
    margin-top: 1.5rem !important;
}
 .editor{
    display: flex!important;
    gap: 10px;
    margin-top: 0.5rem !important;
}
 .editorContent{
    width:50%;
}
.save_button{
    width: 100%;
    text-align: right;
    margin-top: 20px;
    padding: 12px;
}

 .save_button button:disabled{
    opacity: .7;
}

 .changeImageButton {
    background-color: #3C7EF3;
    width: 250px;
    color: white;
    text-align: center;
    padding: 12px;
    bottom: 7px;
    cursor: pointer;
}


/* Responsive styles for smaller screens */
@media (max-width: 768px) {
    .company__info__row {
        flex-direction: column;
    }

    .company__info__row__right {
        max-width: 100%;
    }

    .company__info__row__right__img img {
        max-width: 100%;
    }
}

