.settings_section.virtual_number_wrapper {}

.settings_section.virtual_number_wrapper .settings_content {
    padding: 15px 0px;
}

.dialer__setting_wrapper {
    padding-left: 25px;
}

.settings_section.virtual_number_wrapper .settings_content .details table th {
    font-size: 19px !important;
}

.settings_section.virtual_number_wrapper .global__dropdown .MuiList-root li {
    color: rgb(62 19 89) !important;
}

.menu_list__delete span {
    color: rgb(255 0 0) !important;
}

.menu_list__delete svg {
    fill: rgb(255 0 0) !important;
}

.settings_section.virtual_number_wrapper .settings_content .details table .header th small {
    display: block;
    line-height: 10px;
}

.settings_section.virtual_number_wrapper .settings_content .details table .header th {
    padding: 0px 10px;
}

.settings_section.virtual_number_wrapper .settings_content .details table .header th svg {
    position: absolute;
    right: 10px;
    top: 38%;
}

.settings_section.virtual_number_wrapper .settings_content .details table tr td {
    padding: 0px 10px;
}



.virtual_number_audio_card .icon-record {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 12px;
    border: 1px solid #e2e2e2;
    border-radius: 6px;
    min-width: 100px;
    cursor: pointer;
    transition: all 0.3s ease-in;
}

.virtual_number_audio_card .icon-record:hover {
    background: #f8f8f8;
}

.virtual_number_audio_card .icon-record:disabled,
.virtual_number_audio_card .icon-record[disabled] {
    background: #abb6c778;
    cursor: unset;
}

.virtual_number_audio_card .icon-record+.icon-record {
    margin-left: 10px;
}

.card.virtual_number_audio_card {
    padding: 15px;
    border-radius: 5px;
    box-shadow: 0 2px 2px 0 rgb(138 138 138 / 14%);
}


/* virtual number new design */
.text-field-item-vn-page {
    position: relative;
}

.text-field-item-vn-page .text-field {
    font-size: 12px !important;
}

.text-field-vn-page {
    border: none !important;
    height: 44px !important;
    border-radius: 5px !important;
    padding-left: 10px !important;
    background: var(--white) !important;
    box-sizing: border-box !important;
    flex-grow: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.text-field-vn-incoming-timeout {
    border: none !important;
    height: 44px !important;
    border-radius: 5px !important;
    padding-left: 10px !important;
    background: var(--white) !important;
    box-sizing: border-box !important;
    font-size: 10px;
}

.font-sm {
    font-size: 12px;
}

.audio-container {
    width: 300px;
    overflow: hidden;
    box-sizing: border-box;
    overflow-x: auto;
}

audio {
    width: 300px;
    height: 40px;
}

.audio-scroll {
    overflow-x: auto;
    overflow-y: hidden;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.audio-scroll::-webkit-scrollbar {
    width: 0px;
}

.small-round-icon-vn-page {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: rgba(60, 126, 243, 0.1) !important;
    color: var(--light_blue);
    right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.text-field-item-vn-page .small-round-icon-vn-page {
    position: absolute;
    top: 14px;
}

.small-round-icon-vn-page i {
    font-size: 14px;
}


.text-field-item-vn-page .small-round-icon-vn-page.checkIcon {
    right: 30px;
}

.text-field-item-vn-page .small-round-icon-vn-page.micIcon {
    right: 50px;
}

.details table tr td .small-round-icon-vn-page svg {
    height: 20px;
    width: 20px;
}

/* Oct 14 - Updated */
.ProfileModule-container-parent-class .details.phone table thead tr th:nth-child(2) {
    width: 7%;
}

.ProfileModule-container-parent-class .details.phone table thead tr th:nth-child(3) {
    width: 9%;
}

.ProfileModule-container-parent-class .details.phone table thead tr th:nth-child(4) {
    width: 9%;
}

.ProfileModule-container-parent-class .details.phone table thead tr th:nth-child(5) {
    width: 9%;
}

.ProfileModule-container-parent-class .settings_section.virtual_number_wrapper .settings_content .details table th {
    font-size: 16px !important;
}

.ProfileModule-container-parent-class .details.phone table thead tr th:first-child {
    width: 7%;
}

.ProfileModule-container-parent-class .card.virtual_number_audio_card p {
    font-size: 13px;
    color: #000;
    font-weight: 400;
}

.ProfileModule-container-parent-class .virtual_number_audio_card .icon-record {
    padding: 8px 8px 4px;
}

.ProfileModule-container-parent-class .details table tr td span {
    font-size: 12px;
}

.ProfileModule-container-parent-class .card.virtual_number_audio_card p.alert-title {
    font-size: 12px;
    line-height: 14px;
    color: #404040;
    font-style: italic;
    user-select: none;
}

.ProfileModule-container-parent-class .card.virtual_number_audio_card p.title {
    margin-top: 0;
}

.ProfileModule-container-parent-class .small-round-icon-vn-page {
    width: 30px;
    height: 30px;
}

.ProfileModule-container-parent-class .text-field-item-vn-page .small-round-icon-vn-page.checkIcon {
    right: 46px;
}

.ProfileModule-container-parent-class .text-field-item-vn-page .small-round-icon-vn-page.micIcon {
    right: 82px;
}

.ProfileModule-container-parent-class .details table tr td .small-round-icon-vn-page svg {
    height: 18px;
    width: 18px;
}

.ProfileModule-container-parent-class .card.virtual_number_audio_card {
    margin: 0;
    background: #f6f7fb;
}

.ProfileModule-container-parent-class .text-field-item-vn-page .small-round-icon-vn-page {
    top: 12px;
}

.ProfileModule-container-parent-class .small-round-icon-vn-page {
    background: #f5f5f5 !important;
    transition: all 0.3s;
}

.ProfileModule-container-parent-class .small-round-icon-vn-page:hover {
    background: var(--accent-color) !important;
    color: var(--accent-text-color) !important;
}

.ProfileModule-container-parent-class .small-round-icon-vn-page.cancelIconNew:hover {
    background: #f00 !important;
}

.ProfileModule-container-parent-class .details.phone .text-field-vn-page {
    margin: 0 !important;
    font-size: 14px;
    width: calc(100% - 130px);
}

.ProfileModule-container-parent-class .details.phone .text-field-vn-incoming-timeout {
    margin: 0 !important;
    font-size: 14px;
    width: calc(100% - 130px);
}

.ProfileModule-container-parent-class .details.phone .w-80 {
    width: 80% !important;
}

.ProfileModule-container-parent-class .details.phone .text-field-vn-page {
    width: calc(100% - 130px);
}

.ProfileModule-container-parent-class .details.phone .text-field-vn-incoming-timeout {
    width: calc(100% - 130px);
}

.ProfileModule-container-parent-class .details.phone .text-field-vn-page:disabled {
    width: 100%;
}

.ProfileModule-container-parent-class .details.phone .text-field-vn-page:focus {
    outline: 0 !important;
    border: 0 !important;
    box-shadow: unset !important;
}

.ProfileModule-container-parent-class .details.phone .text-field-vn-incoming-timeout:focus {
    outline: 0 !important;
    border: 0 !important;
    box-shadow: unset !important;
}

.ProfileModule-container-parent-class .details.phone table tr td {
    padding: 10px !important;
}

.ProfileModule-container-parent-class .details.phone .action .MuiButton-contained {
    box-shadow: unset !important;
}

.ProfileModule-container-parent-class .details.phone table tr td {
    font-size: 14px;
}

.ProfileModule-container-parent-class .audio-icons.d-flex input.mb-2 {
    position: absolute;
}

/* Virtual Design Update - Oct 24 */
.virtual_number_wrapper .settings_content {
    padding-top: 0 !important;
}

.main__content .virtual_number_wrapper .container-fluid {
    margin-top: 0;
    width: calc(100% - 10px);
}

.main__content .virtual_number_wrapper .tag_list h5 {
    font-size: 18px;
    padding-left: 10px;
}

.dialer_settings_wrapper {
    background: #f1f1f1;
    padding: 16px;
    border-radius: 6px;
    color: #000;
    display: flex;
    align-items: flex-end;
    min-height: 98px;
    margin-bottom: 10px;
    margin-top: 5px;
}

.dialer_settings_wrapper p {
    margin: 0;
}

.dialer_settings_wrapper h6 {
    margin: 0;
    color: #000;
}

.dialer_settings_wrapper .MuiSelect-selectMenu {
    min-height: unset;
    padding: 12px 30px 12px 12px;
    font-size: 12px;
}

.dialer_settings_wrapper label p {
    color: inherit;
    margin-bottom: 4px;
}

.dialer_settings_wrapper label {
    color: inherit;
}


.virtual_number_wrapper .settings_content {
    padding-top: 0 !important;
}

.main__content .virtual_number_wrapper .mail__inbox {
    margin-top: 0;
    box-sizing: border-box;
    width: calc(100% - 10px);
}



.add_new_user.alt .m-profile-virtual-enable span {
    font-size: inherit;
}

.virtual_number_wrapper .add_new_user.alt {
    color: #fff;
}

@media(max-width: 1505px) {
    .ProfileModule-container-parent-class .details.phone tr td audio {
        width: 95%;
    }

    .ProfileModule-container-parent-class .details.phone .small-round-icon-vn-page {
        width: 20px;
        height: 20px;
    }

    .ProfileModule-container-parent-class .details.phone table tr td .small-round-icon-vn-page svg {
        height: 15px;
        width: 15px;
    }

    .ProfileModule-container-parent-class .details.phone .text-field-item-vn-page .small-round-icon-vn-page.micIcon {
        right: 54px;
    }

    .ProfileModule-container-parent-class .details.phone .text-field-item-vn-page .small-round-icon-vn-page.checkIcon {
        right: 32px;
    }

    .ProfileModule-container-parent-class .details.phone .text-field-item-vn-page .small-round-icon-vn-page.activeDefaultIcon {
        background-color: var(--light_green) !important;
        color: var(--purple);
    }


    .ProfileModule-container-parent-class .details.phone .text-field-item-vn-page.forward__number_ .text-field-vn-page {
        width: calc(100% - 55px);
    }

    .ProfileModule-container-parent-class .details.phone .text-field-item-vn-page.forward__number_ .text-field-vn-incoming-timeout {
        width: calc(100% - 55px);
    }

    .ProfileModule-container-parent-class .details.phone .text-field-item-vn-page.call__whisper_message .text-field-vn-page {
        width: calc(100% - 75px);
    }

    .ProfileModule-container-parent-class .details.phone .text-field-item-vn-page.call__whisper_message .text-field-vn-incoming-timeout {
        width: calc(100% - 75px);
    }

    .ProfileModule-container-parent-class .details.phone .text-field-item-vn-page.call__whisper_message .text-field-vn-page:disabled {
        width: calc(100% - 32px);
    }

    .ProfileModule-container-parent-class .details.phone .text-field-item-vn-page.call__whisper_message .text-field-vn-incoming-timeout:disabled {
        width: calc(100% - 32px);
    }

    .ProfileModule-container-parent-class .details.phone .card.virtual_number_audio_card p.title {
        width: calc(100% - 60px);
        font-size: 11px;
        line-height: 14px;
    }

    .ProfileModule-container-parent-class .details.phone .card.virtual_number_audio_card p.alert-title {
        font-size: 10px;
        margin: 5px;
    }

}

@media (max-width: 1366px) {
    .ProfileModule-container-parent-class .settings_section.virtual_number_wrapper .settings_content .details table th {
        font-size: 8px !important;
    }

    .ProfileModule-container-parent-class .virtual_number_audio_card .icon-record span {
        display: none;
    }

    .ProfileModule-container-parent-class .virtual_number_audio_card .icon-record {
        min-width: 45px;
        height: 40px;
        margin: 0 2px;
    }

    .ProfileModule-container-parent-class .audio-icons.d-flex {
        flex-wrap: wrap;
    }

    .ProfileModule-container-parent-class .card.virtual_number_audio_card {
        padding: 10px;
    }

    .virtual_number_wrapper .by-additional-phone-number,
    .virtual_number_wrapper .m-profile-virtual-enable {
        padding: 8px 10px;
        font-size: 14px;
    }

    .dialer_settings_wrapper .MuiFormControlLabel-label {
        font-size: 14px;
    }

    .virtual_number_wrapper .custom-switch-off-icon,
    .virtual_number_wrapper .custom-switch-on-icon {
        font-size: 14px !important;
    }

    .virtual_number_wrapper .custom-switch-off-icon {
        line-height: 23px;
    }
}

@media (max-width: 1199px) {
    .ProfileModule-container-parent-class .settings_section.virtual_number_wrapper .settings_content .details.phone table th {
        font-size: 12px !important;
    }

    .ProfileModule-container-parent-class .details.phone table tr td,
    .ProfileModule-container-parent-class .details.phone .text-field-vn-page,
    .ProfileModule-container-parent-class .details.phone .text-field-vn-incoming-timeout {
        font-size: 12px;
    }

    .ProfileModule-container-parent-class .virtual_number_audio_card .icon-record {
        min-width: 40px;
    }
}

@media only screen and (min-width: 1024px) and (max-width: 1705px) {
    .settings_section.virtual_number_wrapper .settings_content .details table thead tr th:first-child {
        width: 9% !important;
    }

    .settings_section.virtual_number_wrapper .settings_content .details table thead tr th:nth-child(2) {
        width: 8% !important;
    }

    .settings_section.virtual_number_wrapper .settings_content .details table thead tr th:nth-child(3) {
        width: 9% !important;
    }

    .settings_section.virtual_number_wrapper .settings_content .details table thead tr th:nth-child(4) {
        width: 9% !important;
    }

    .settings_section.virtual_number_wrapper .settings_content .details table thead tr th:last-child {
        width: 9% !important;
    }

    .ProfileModule-container-parent-class .details.phone .text-field-vn-page.text-field-vn-page-v2 {
        margin: 3px !important;
        font-size: 8px !important;

    }

    .ProfileModule-container-parent-class .settings_section.virtual_number_wrapper .settings_content .details table th {
        font-size: 8px !important;
    }

    .ProfileModule-container-parent-class .details.phone table tr td {
        font-size: 8px !important;
    }

    .ProfileModule-container-parent-class .details.phone .text-field-item-vn-page.forward__number_ .text-field-vn-page {
        width: calc(100% - 32px) !important;
        ;
    }

    .ProfileModule-container-parent-class .details.phone .text-field-vn-incoming-timeout {
        margin: 0 !important;
        font-size: 8px;
        width: calc(100% - 130px);
    }

}

@media(max-width: 1098px) {
    .virtual_number_wrapper .tagBox {
        flex-direction: column;
        align-items: flex-start;
    }

    .virtual_number_wrapper .add_new_user.alt {
        margin: 0 10px 10px;
    }

    .main__content .virtual_number_wrapper .tag_list h5 {
        margin: 0 0 10px;
    }
}

.MuiDrawer-paper {
    max-height: 100% !important;
    max-width: 80% !important;
}

.virtual_number_add_warning_popup .react-confirm-alert-body {
    text-align: center;
}

.virtual_number_add_warning_popup .react-confirm-alert-button-group {
    justify-content: center;
}

.add__virtual__number__button span {
    word-break: normal !important;
}

.ProfileModule-container-parent-class .settings_content .audio-voice .virtual_number_audio_card {
    position: relative;
    width: 400px;
    z-index: 1;
    left: -230px;
    top: 35px;
}

.ProfileModule-container-parent-class .settings_content .audio-voice-new {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    height: 100%;

}

.ProfileModule-container-parent-class .settings_content .audio-voice .virtual_number_audio_card_new {
    position: unset;
    z-index: unset;
    left: unset;
    top: unset;
    width: 380px;
}

.ProfileModule-container-parent-class .details.phone .text-field-vn-page.text-field-vn-page-v2 {
    margin: 5px !important;
    font-size: 12px;

}