.mainWrp{
  width: 500px;
  height: 400px;
  display: flex;
  overflow: hidden;
  border-radius: 4px;
  background-color: #fff;
  flex-direction: column;
  overflow-y: auto;
}
.header{
  width: 100%;
  height: 60px;
  padding: 5px 10px;
  border-bottom: 1px solid #ddd;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}
.headerText{
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.6px;
}
.footer{
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 10px;
  box-sizing: border-box;
  gap: 10px;
  border-top: 1px solid #ddd;
}
.body{
  width: 100%;
  height: calc(100% - 132px);
  padding: 10px;
  box-sizing: border-box;
}
.textLabel{
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.6px;
  margin-bottom: 10px;
}
.folderWrp{
  width: 100%;
  display: flex;
  gap: 10px;
  padding: 7px;
  flex-wrap: wrap;
}
.folderInput{
  width: 100% !important;
  height: 40px !important;
  border: 1px solid #ddd !important;
  border-radius: 4px !important;
  padding: 3px 10px !important;
  font-size: 13px !important;
}
.folderInput:focus {
  border-bottom: 1px solid #ddd !important;
  box-shadow: unset !important;
}
.button{
  padding: 9px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border-radius: 4px;
  box-sizing: border-box;
  cursor: pointer;
}
.cancelButton{
  background-color: #ff0000;
}
.icon{
  display: inline-flex;
}
.buttonText{
  color: #fff;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.6px;
}
.submitButton{
  background-color: #3c7ef3;
}
.folderText{
  padding: 5px 10px;
  align-self: center;
  text-align: center;
  justify-content: center;
  background-color: #ddd;
  border-radius: 4px;
  cursor: pointer;
  width: fit-content;
  height: fit-content;
  margin: 5px;
}
.selected{
  background-color: #3c7ef3!important;
  color: #fff;
  cursor: auto;
}