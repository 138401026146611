.MuiTableCell-root{
  font-family: var(--poppins) !important;
}

/* globar css custom main wrapper */

.contact__custom__section .MuiBox-root{
  padding: 20px !important;
}
.tab__topper{
  background: var(--white);
  height: 90px;
  border-radius: 10px;
  margin-top: 22px;
}
.tab__topper button:focus{
  background-color: rgba(0,0,0,0.0);
}
.tab__topper .MuiTabs-fixed{
  height: 100%;
  margin: 0 10px;
  display: flex;
  align-items: center;

}
.tab__topper .MuiButtonBase-root {
  color: var(--dark_blue);
  font-size: 20px;
  font-weight: 500;
  text-transform: capitalize;
  padding: 0 20px;
  line-height: 40px;
  
}

.tab__topper .Mui-selected{
  color: var(--light_blue);
}

.tab__topper .PrivateTabIndicator-colorSecondary-3{
  background-color: var(--light_blue) !important;
}
.tab__topper .system__tab__button{
  width: 198px;
}
.tab__topper .fields__tab__button{
  width: 237px;
}
.tab__topper .personalized__tab__button{
  width: 231px;
}



/* Tab Toper Css End */

/* Tab Content */
.tab__contnet{
  background: var(--white);
  border-radius: 10px;
  margin-top: 20px;
}
.tab__contnet tr.MuiTableRow-root{
  border-radius: 5px;
}
.tab__contnet th.MuiTableCell-root{
  max-height: auto !important;
  background: var(--gray_light);
  border-right: 2px solid var(--white);
  border-bottom: 0;
}

.tab__contnet thead.MuiTableHead-root{
  height: 50px;
  background: var(--gray_light);
  border-radius: 5px;
}

.tab__contnet .MuiPaper-root{
  box-shadow: 0 0 0 0 rgba(0,0,0,0.0);
}


/* System Table */
.system__table .MuiTableCell-root{
  padding: 14px 20px;
}
.custom__table .MuiTableCell-root,
.personiliz__table .MuiTableCell-root{
  padding: 7px 20px 8px 20px;
  
}
.system__table .MuiTableBody-root .MuiTableCell-root,
.custom__table .MuiTableBody-root .MuiTableCell-root,
.personiliz__table .MuiTableBody-root .MuiTableCell-root
{
  font-size: 16px;
  font-weight: 400;
  color: rgba(19, 49, 89, 0.65)
}
.system__table .MuiTableHead-root .MuiTableCell-root,
.custom__table .MuiTableHead-root .MuiTableCell-root,
.personiliz__table .MuiTableHead-root .MuiTableCell-root,
.custom__form .MuiFormLabel-root
{
  font-size: 16px;
  font-weight: 500;
  color: var(--dark_blue)
}

.personiliz__table thead tr,
.custom__table thead tr,
.system__table thead tr{
  border-bottom: 0;
}

.system__table tbody tr,
.personiliz__table tbody tr,
.custom__table tbody tr{
  border-bottom: 3px solid rgba(60, 126, 243, 0.05);
}
.personiliz__table .MuiTableCell-root,
.custom__table .MuiTableCell-root{
  border: 0 ;
}
.systemtable__th1{
  /* width: 300px; */
  width: 19.2%;
  position: relative;
}
.systemtable__th2{
  width: 16.83%;
}
.systemtable__th3{
  width: 21.44%;
  position: relative;
}
.systemtable__th4{
  width: 23.83%
}
.systemtable__th5{
  width: 18.69%;
}
/* Custom Form */
.tab__contnet .custom__form input{
  border: 1px solid var(--gray_dark);
}
.custom__form{
  display: flex;
  font-size: 16px;
}
.custom__form__icon .MuiButton-startIcon{
  margin-right: 10px;
}
.custom__form .MuiInputLabel-outlined.MuiInputLabel-shrink {
  transform: translate(0px, -10px) scale(1);
}
/* .custom__form .MuiFormLabel-root.Mui-focused{
  color: var(--dark_blue);
} */
.personailized__form .MuiInput-formControl,
.custom__form__input .MuiInput-formControl{
  margin-top: 10px;
}
.custom__form .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color:var(--gray_dark);
  border-width: 1px;
}
.custom__form .MuiSelect-select:focus{
  background-color: var(--white);
}
.custom__form .custom__form__input{
  width: 19%;
}
.custom__form .custom__form__select{
  width: 192px;
  margin: -5px 15px 0 15px;
}
.custom__form__select .MuiFormLabel-root{
  margin: 0 0 6px 0;
}
.custom__form__margin{
  align-items: center;
}
.custom__form__margin .add__icon{
  margin-bottom: -15px;
}
.custom__form .custom__form__select
.MuiInputBase-formControl{
  border-color:var(--gray_dark) !important;
}
.custom__table .customtable__th1{
  width: 20%;
  position: relative;
}
.custom__table .customtable__th1
span.lable__icon1 {
  position: absolute;
  top: 39%;
}
span.label__name1 {
  margin-right: 10px;
}
.custom__table .customtable__th2{
  width: 9%;
}
.custom__table .customtable__th3{
  width: 17%;
}
/* .custom__table .customtable__th4{
  width: 17%;
} */
.custom__table .customtable__th5{
  width: 7%;
}
.custom__table .customtable__th6{
  width: 18%;
  position: relative;
}
span.lable__icon1 {
  position: absolute;
  top: 32%;
}
.custom__table .customtable__th7{
  width: 10%;
}
.custom__form__margin{
  margin-bottom: 28px;
}
.personalized__value .MuiFormLabel-root.Mui-focused,
.personalized__field .MuiFormLabel-root.Mui-focused,
.custom__form__margin .MuiFormLabel-root.Mui-focused{
  color: var(--dark_blue) !important;
}
.custom__form__select .MuiInputBase-root{
  color: rgba(19, 49, 89, 0.35);
}
.custom__form__select .MuiOutlinedInput-input {
  padding: 18.5px 12px;
}
.custom__form .add__icon{
  background: var(--light_blue);
}
.add__icon__personalized .MuiButton-iconSizeMedium,
.custom__form__icon .MuiButton-iconSizeMedium{
  background: #fff;
  color: var(--light_blue);
  border-radius: 50%;
  width: 20px;
  min-width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.add__icon__personalized.add__icon svg,
.custom__form__icon.add__icon svg{
  width: 16px !important;
}
.custom__form input{
  height: 48px !important;
  border-radius: 5px !important;
  font-weight: 400;
  padding: 0 10px !important;
  color: var(--dark_blue);
}
.custom__form .MuiButtonBase-root{
  color: var(--white);
}

.custom__form button{
  padding: 13px 20px;
  font-size: 16px;
  font-weight: 500;
  text-transform: capitalize;
}
.custom__form button,
.custom__form .MuiInputBase-formControl{
  height: 50px !important;
  border-radius: 5px !important;
}
.custom__form .MuiButtonBase-root.add__icon:hover{
  background-color: var(--light_blue);
 }
 .personailized__form .MuiButtonBase-root.reset__icon{
  background-color: var(--gray_dark);
  color: rgba(19, 49, 89, 0.65);
  margin-right: 20px;
  padding: 0 15px;
 }
 .reset__icon .reset__icon__svg{
   margin-right: 6px;
 }
 .personailized__form .MuiButton-iconSizeMedium > *:first-child {
  font-size: 17px !important;
}

.personailized__form .MuiButton-startIcon {
  margin-right: 10px !important;
}

/* Global Select Active and Hover Css Start */

 .custom__form_select .MuiButtonBase-root.MuiListItem-button {
  position: relative;
}

.custom__form_select .MuiButtonBase-root.MuiListItem-button:after {
  position: absolute;
  content: "";
  height: 100%;
  width: 2px;
  left: 0;
  top: 0;
  background: rgba(60, 126, 243, 1);
  opacity: 0;
}
.custom__form_select .MuiButtonBase-root.MuiListItem-button:hover{
  background: rgba(60, 126, 243, 0.1);
  color: rgba(60, 126, 243,1);
}
.custom__form_select .MuiList-root li.MuiButtonBase-root.MuiListItem-button.Mui-selected:after{
  opacity: 1;
}
.custom__form_select .MuiList-root li.MuiButtonBase-root.MuiListItem-button:hover::after{
  opacity: 1;
}
.custom__form_select .MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover{
  background-color: rgba(60, 126, 243, 0.1) !important;
  color: rgba(60, 126, 243,1);
}

/* Global Select Active and Hover Css End */


/* Custom Table Css Start */
.editDelete__wr{
  display: flex !important;
  justify-content: space-between;
  align-items: center;
}

.editDelete__wr .edit__button,
.editDelete__wr .delete__button{
  border: 1px solid var(--gray_dark);
  display: flex !important;
  align-items: center;
  padding: 9px 15px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  margin: 0 5px;
  border-radius: 5px;
  cursor: pointer;
}
.edit__button.edit__button__active{
  background: var(--light_blue);
  color: var(--white) !important;
}
.editDelete__wr .edit__button{
  color: var(--dark_blue);
}
.editDelete__wr .edit__button:hover{
  background: var(--light_blue);
  color: var(--white);
}
.editDelete__wr .delete__button{
  color: var(--red);
}
.delete__button svg,
.edit__button svg {
  margin-right: 8px;
  height: 16px;
  width: 16px;
}
.create__value__add__title{
  color: #3C7EF3;
  font-size: 12px;
  display: flex;
  align-items: center;
  align-items: center;
  margin: 0;
}

.create__value__plusIcon svg {
  font-size: 17px !important;
}
.table__body__td {
  position: relative;
}
.table__body__td .create__value__add__title{
  position:absolute;
  bottom: -1px;

}

.create__value__display__none{
  display: none;
}

.custom__datafeild__edit_wr{
  border-bottom:  0 !important;
  border-top: 0 !important;

}
.custom__datafeild__edit_wr td{
  background: #F6F7FB !important; 
}
.custom__datafeild__edit input {
   height: 32px !important;
    border-radius: 5px !important;
    font-weight: 400;
    padding: 0 10px !important;
    color: var(--dark_blue);
    border: 1px solid #E5E8EF !important;
    background-color: var(--white) !important;
    font-size: 16px !important;
}

/* React Switch Custom  Css Start*/
.react-switch-bg{
  /* background: transparent !important; */
  height: 24px !important;
  width: 55px !important;
  display: flex;

}
.active__switch .react-switch-handle{
  top: 4px !important;
  left: -3px !important;
}
.Unactive__switch .react-switch-handle{
  top: 4px !important;
  left: 3px !important;
}
.react-switch-handle{
  height: 16px !important;
  width: 16px !important;
}
.Unactive__switch .react-switch-bg{
  /* border: 1px solid var(--orange); */
  border: 1px solid #9aa7b8;
}
.active__switch .react-switch-bg{
  /* border: 1px solid var(--green2); */
}
.checkoutIcon__off{
  padding: 0px 6px 0 0 !important;
}
.checkoutIcon__on{
  padding: 4px !important;
}


/* Personilized Css Start*/

.personailized__form{
  align-items: flex-end;
  margin-bottom: 42px;
}
.personailized__form .MuiFormControl-root{
  margin-right: 15px;
}
.personalized__tag p{
  color: rgba(19, 49, 89, 0.65);
  font-size: 16px;
  padding: 1px 0;
}



.personiliz__table .MuiTableRow-head th:first-child,
.custom__table .MuiTableRow-head th:first-child,
.system__table .MuiTableRow-head th:first-child
 {
  border-top-left-radius: 5px; 
  border-bottom-left-radius: 5px;
}
.personiliz__table .MuiTableRow-head th:last-child,
.custom__table .MuiTableRow-head th:last-child,
.system__table .MuiTableRow-head th:last-child {
  border-top-right-radius: 5px; 
  border-bottom-right-radius: 5px;
}

.custom__datafeild__edit_wr td:first-child {
    border-top-left-radius: 5px; 
    border-bottom-left-radius: 5px;
}
.custom__datafeild__edit_wr td:last-child {
  border-top-right-radius: 5px; 
  border-bottom-right-radius: 5px;
}
.custom__datafeild__edit input{
  margin:0 !important;
}
.custom__datafeild__edit{
  margin-left: -10px !important;
}
.personailized__form__left{
  display: flex;
  align-items: flex-end;
}
.personilize__btn__wr {
  display: flex;
  margin-bottom: 5px;
}
.personalized__field{
  width: 300px;
}

.personalized__value{
  width:  220px;
}
.personalized__tag{
  width: 220px;
}
.personiliz__table .personiliz__th1{
  width: 26.18%;
  position: relative;
}
.personiliz__table .personiliz__th2{
  width: 28.84%
}
.personiliz__table .personiliz__th3{
  width: 27.95%
}
.personiliz__table .personiliz__th4{
  width: 15.23%;
}














/* Responsive Css */

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  /* .custom__table{
    table-layout: fixed !important;
  } */

  .custom__form .custom__form__input{
    width:30%;
  }
  .custom__form .custom__form__select {
    width: 20%;
  }
  .custom__form button.reset__icon{
    padding: 0 10px;
  }
  
   /* table nth class css start*/
  .table__body__row__custom td:nth-child(3) {
    border-bottom-left-radius: 10px;
  }
  .table__body__row__custom td:first-child {
    width: 17% !important;
    max-width: 150px;
  }
  .table__body__row__custom td:nth-child(4) {
    text-align: center;
  }
  .table__head__row__custom th:nth-child(3){
    text-align:center;

  }
  .table__head__row__custom th:nth-child(4){
    text-align:center !important;
    width: 259px !important;
    letter-spacing: 0.01em
  }
  .table__head__row__custom th:nth-child(5){
    width: 100px;
  }


  /* table nth class css end */
  .personalized__field{
    width: 250px;
  }
  .personalized__tag{
    width: 150px;
  }
  
  .personailized__form__left .add__icon{
    width: 220px;
  }
  
}
@media only screen and (max-width: 1200px){
  .editDelete__wr .edit__button,
  .editDelete__wr .delete__button{
    height: 30px;
    width: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .delete__button svg, .edit__button svg{
    margin-right: 0;
  }
  .editDelete__wr .edit__button span,
  .editDelete__wr .delete__button span{
    display: none;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .custom__form .custom__form__select{
    width: 30%;
  }
}

@media only screen and (max-width: 1199px) {

    /* For Main */
    /* .main__content{
      margin-left: 103px;
    } */
    
   .table__thead__th{
      font-size: 12px !important;
   }
  
  .system__table .MuiTableCell-root {
    padding: 10px 10px;
    text-align: center;
   }
  .table__body__td {
      font-size: 12px !important;
  }
  .add__icon.add__icon__personalized {
    width: 40px !important;
    height: 40px !important;
    min-width: 40px;
  }
  .personilize__btn__wr .reset__icon{
    height: 40px !important;
    width: 78px;
  }
  .reset__icon svg {
    font-size: 14px !important;
  }
  /*=====For Tab Font And Width Size Design ==========*/
  .tab__topper .personalized__tab__button {
    width: 198px;
    font-size: 16px;
  }
  .tab__topper .fields__tab__button {
    width: 198px;
    font-size: 16px;
   }
   .tab__topper .system__tab__button {
     width: 166px;
     font-size: 16px;
  }
  /*=====For All Table td Font Size Design ==========*/
  .system__table .MuiTableHead-root .MuiTableCell-root,
   .custom__table .MuiTableHead-root .MuiTableCell-root, 
   .personiliz__table .MuiTableHead-root .MuiTableCell-root,
    .custom__form .MuiFormLabel-root {
    font-size: 12px;
  }   
  .custom__table{
    table-layout: fixed !important;
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important;
    overflow: hidden !important;
    overflow-x: scroll !important;    
  }
  .editDelete__wr{
    height: 50px !important;
  }
  .customtable__th7 {
    width: auto !important;
}
  .reset__icon span.MuiButton-label {
    font-size: 12px;
  }

  /*=====For Custom Data Fields ==========*/
    .custom__form .custom__form__input{
      width: 30%;
    }
    .custom__form .custom__form__select{
      width: 30%;
    }
    .custom__form input{
      font-size: 12px !important;
    }
    
    /* For Personilized Fields */
    .personalized__field,
    .personalized__value
    {
      width: 160px;
    }
    .personalized__tag{
      width: 106px;
    }

    .personilize__btn__wr .add__icon span.create__content{
      display: none;
    }
    .add__icon__personalized .MuiButton-startIcon{
      margin: 0 !important;
    }
    .reset__icon .MuiButton-startIcon{
      margin-right: 7px !important;
    }
    
}
@media only screen and (max-width: 1366px){
  .systemtable__th5{
    width: 13.69%;
  }
  span.lable__icon1 {
    top: 34%;
}
}
@media only screen and (max-width: 991px){
  /* #main__content{
    margin-left: 108px !important;
    width: 100%;
  } */

  .tab__topper {
    height: 60px;
}

.custom__datafeild__edit{
  margin-left: -5px !important;
}
.custom__datafeild__edit input{
  font-size: 12px !important;
}
.custom__form button, .custom__form .MuiInputBase-formControl{
  height: 40px !important;
}
.custom__form input{
  height: 38px !important;
}

  .table__thead__th{
    font-size: 10px !important;
 }
 .editDelete__wr .edit__button, .editDelete__wr .delete__button{
   margin: 0 !important;
 }

 .editDelete__wr .edit__button{
   margin-right: 5px !important;
 }
  .system__table .MuiTableCell-root,
  .custom__table .MuiTableCell-root, 
  .personiliz__table .MuiTableCell-root {
    padding: 7px 10px 8px 10px;
  }
  .create__value__add__title {
    font-size: 10px;
  }
  .table__head__row__custom th:nth-child(5){
    width: 62px;
  }
  .systemtable__th4 {
    width: 17.83%;
  }
  .systemtable__th1{
    width: 15%;
  }
  .systemtable__th2 {
    width: 5.83%;
  }
  .custom__table .customtable__th7 {
    width: 15%;
  }
  .custom__table .customtable__th6{
    width: 16%;
  }
  .custom__table .customtable__th1 span.lable__icon1 {
    top: 40%;
  }

  /* switch */
  .checkoutIcon__on,
  .checkoutIcon__off {
    font-size: 12px !important;
  }
  .react-switch-bg{
    width: 44px !important;
  }

  .active__switch .react-switch-handle{
    left: -7px;
  }
  
  .checkoutIcon__off {
    padding: 3px 6px 0 0 !important;
}


}
